import React, { useRef } from "react";
import { FiSearch } from "react-icons/fi";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import GlobalURL from "../BaseUrl";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
// import { Modal, Button, Box } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { MdOutlineFormatBold } from "react-icons/md";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough } from "react-icons/bs";
import { CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { CiTextAlignCenter } from "react-icons/ci";

const EventPage = () => {
  const language = useSelector((state) => state.language.value);
  const [open, setOpen] = useState(false);

  const handleDeacriptionClick = () => {
    setOpen(true);
  };

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [eventType, setEventType] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");
  const [loading, setloading] = useState(false);
  const formData = new FormData();
  const [file, setFile] = useState("");
  const [preview, setPreview] = useState();
  const [mediatype, setMediatype] = useState("img");
  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  const [eventData, setEventData] = useState({
    title: "",
    event_type: "",
    description: "",
    event_date: "",
    streaming_link: "",
  });

  const addEvent = async () => {
    if (!descripcion) {
      alert(t("Description fields required"));
      return;
    }
    setloading(true);
    try {
      const apiUrl = `${GlobalURL}/api/dev/app_moderator/event/addEvent`; // Replace this with your actual API endpoint
      const accessToken = window.localStorage.getItem("token");

      const eventData = {
        title: title,
        event_type: eventType,
        description: descripcion,
        event_date: date,
        streaming_link: link,
        team_id: -1,
      };

      // Loop through the eventData and append each key-value pair to formData

      formData.append("image", file);
      formData.append("title", title);
      formData.append("event_type", eventType);
      formData.append("description", descripcion);
      formData.append("event_date", date);
      formData.append("team_id", -1);

      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "ngrok-skip-browser-warning": "69420",
          team_id: -1,
        },

        body: formData,
      });
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
        setloading(false);
      }
      if (response.status === 404) {
        console.log("Estado 404: No encontrado");
        Swal.fire(t("Error del Servidor"));
        setloading(false);
      } else if (response.status === 200) {
        const res = await response.json();
        console.log("Response data:", res.data);
        navigate("/events");
        Swal.fire(t("Eventos creados exitosamente"));
        setloading(false);
      }
      setloading(false);
    } catch (err) {
      console.log("Error occurred:", err);
      setloading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addEvent();
  };

  function handleChange(e) {
    setFile(e.target.files[0]);
    console.log("e.target.files: ", e.target.files[0]);
    if (!e.target.file) {
      return;
    }
    const preViewURL = URL?.createObjectURL(e.target.files[0]);
    setPreview(preViewURL);
    if (e.target.files[0].name.includes("mp4")) {
      setMediatype("vids");
    }
  }

  const handleClose = () => {
    // Close the modal
    setOpen(false);
  };

  const handleBold = () => {
    document.execCommand("bold", false, null);
  };

  const handleItalic = () => {
    document.execCommand("italic", false, null);
  };

  const handleStrikeThrough = () => {
    document.execCommand("strikeThrough", false, null);
  };

  const handleTextAlign = (alignment) => {
    document.execCommand("justify" + alignment, false, null);
  };

  const handleGuardarClick = () => {
    setEditorContent(editorRef.current.innerHTML);
    console.log("editorRef.current: ", editorRef.current.innerHTML);
    setDescripcion(editorRef.current.innerHTML);
    handleClose();
  };

  const handleEventData = () => {};

  const applyFormatting = (tag) => {
    const textarea = document.getElementById("modal-description-input");
    console.log("textarea: ", textarea);
    if (!textarea) return;

    const { selectionStart, selectionEnd } = textarea;
    if (selectionStart === undefined || selectionEnd === undefined) return;

    const selectedText = textarea.value.substring(selectionStart, selectionEnd);
    if (!selectedText) return;

    const newText = `${textarea.value.substring(
      0,
      selectionStart
    )}<${tag}>${selectedText}</${tag}>${textarea.value.substring(
      selectionEnd
    )}`;
    console.log("newText: ", newText);
    setDescripcion(newText);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="event-page">
            <Container fluid>
              <Row>
                <Col xs lg="3"></Col>
                <Col xs lg="6">
                  <div className="news-header">
                    <div
                      className="back-sec"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3
                      className="main-title"
                      style={{ fontFamily: "Francois One", fontSize: "23px" }}
                    >
                      {t("Agregar Eventos")}
                    </h3>
                    <div className="search-icon" style={{ opacity: 0 }}>
                      <FiSearch />
                    </div>
                  </div>
                  <div className="event-page-content">
                    <div className="common-input-forms"></div>
                    <form onSubmit={handleSubmit}>
                      <div className="event-page-content">
                        <div className="common-input-forms">
                          <div>
                            <div className="common-form-control">
                              <label>{t("Título")}</label>
                              <input
                                required
                                value={title}
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                                placeholder={t(
                                  "Inserte el título de la noticia aquí"
                                )}
                              />
                            </div>
                            <div className="common-form-control">
                              <div className="">
                                <label>{t("Tipo de Evento")}</label>
                                <select
                                  name="event_type"
                                  onChange={(e) => {
                                    setEventType(e.target.value);
                                  }}
                                  value={eventType}
                                >
                                  <option value="">
                                    {t(
                                      "Escoja el tipo de evento (evento regular o exclusivo)"
                                    )}
                                  </option>
                                  <option value="events">{t("Eventos")}</option>
                                  <option value="exclusive-events">
                                    {t("Eventos Exclusivos")}
                                  </option>
                                  <option value="experiences-messice">
                                    {t("Vivencias Mess Ice")}
                                  </option>
                                </select>
                              </div>

                              {/* <input value={eventType} onChange={(e) => { setEventType(e.target.value) }} placeholder='Escoja el tipo de evento (evento regular o exclusivo)' /> */}
                            </div>
                            <div className="common-form-control">
                              <label>{t("Descripción")}</label>
                              <input
                                readOnly
                                onClick={() => {
                                  handleDeacriptionClick();
                                }}
                                placeholder={t(
                                  "Inserte la descripción de la noticia aquí"
                                )}
                              />
                            </div>

                            <div className="common-form-control img-video-form-control">
                              <label>Foto/Video</label>
                              <div className="file-input-sec">
                                <input
                                  id="uploadFile"
                                  disabled={true}
                                  className="f-input"
                                  placeholder={t(
                                    "Inserte la foto o video de la Vivencia Mess Ice aquí"
                                  )}
                                />
                                <div className="fileUpload btn btn--browse">
                                  <input
                                    id="uploadBtn"
                                    required
                                    onChange={handleChange}
                                    type="file"
                                    className="upload"
                                  />
                                </div>
                              </div>
                            </div>

                            {file && (
                              <div className="preview-imgCon-ae">
                                {mediatype === "img" ? (
                                  <div className="preview-imgCon">
                                    <img src={preview} alt=""></img>
                                  </div>
                                ) : (
                                  <div className="preview-imgCon">
                                    <video src={preview}></video>
                                  </div>
                                )}
                              </div>
                            )}

                            <div className="common-form-control">
                              <label>{t("Seleccionar Fecha")}</label>
                              <input
                                required
                                value={date}
                                type="date"
                                onChange={(e) => {
                                  const selectedDate = e.target.value;
                                  const currentDate = new Date()
                                    .toISOString()
                                    .split("T")[0]; // Get today's date in yyyy-mm-dd format

                                  // Check if the selected date is greater than or equal to today's date
                                  if (selectedDate < currentDate) {
                                    // If the selected date is earlier than today's date, clear the date value
                                    setDate("");
                                    // You can also show an error message or perform other actions here
                                    Swal.fire(
                                      t(
                                        "Por favor seleccione una fecha mayor o igual a hoy."
                                      )
                                    );
                                  } else {
                                    // If the selected date is valid, update the date state
                                    setDate(selectedDate);
                                  }
                                }}
                                placeholder={t(
                                  "Seleccione la fecha del evento"
                                )}
                              />
                            </div>
                            <div className="common-form-control">
                              <label>{t("Link de Streaming / otro")}</label>
                              <input
                                required
                                value={link}
                                onChange={(e) => {
                                  setLink(e.target.value);
                                }}
                                placeholder={t(
                                  "Inserte el link del streaming o evento"
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="news-btn-sec">
                          <button className="green-btn" type="Submit">
                            {loading ? (
                              <div
                                className=""
                                style={{
                                  height: "25px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="loading-adjust">
                                  <div className="Loader d-flex justify-content-center align-items-center">
                                    <div
                                      className="spinner-border spinner-border-sm my-5"
                                      role="status"
                                    >
                                      <span className="sr-only"></span>
                                    </div>
                                    <div className="my-5">
                                      {" "}
                                      &nbsp; {t("Cargando...")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              t("Guardar")
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
                <Col xs lg="3"></Col>

                <Modal
                  show={open}
                  className="institutional-popup common-popup"
                  onHide={handleClose}
                  centered
                >
                  <Modal.Header className="pb-0 mb-0" closeButton>
                    <Modal.Title>{t("Editar")}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="custom-text-editor w-100">
                      <div className="toolbar">
                        <button onClick={handleBold}>
                          <MdOutlineFormatBold />
                        </button>
                        <button onClick={handleItalic}>
                          <BiItalic />
                        </button>
                        <button onClick={handleStrikeThrough}>
                          <BsTypeStrikethrough />
                        </button>
                        <div className="text-align-buttons">
                          <button onClick={() => handleTextAlign("Left")}>
                            <CiTextAlignLeft />
                          </button>
                          <button onClick={() => handleTextAlign("Center")}>
                            <CiTextAlignCenter />
                          </button>
                          <button onClick={() => handleTextAlign("Right")}>
                            <CiTextAlignRight />
                          </button>
                        </div>
                      </div>

                      <div
                        className="editor"
                        ref={editorRef}
                        contentEditable
                        // onInput={handleChange}
                        // onChange={handleChange}
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                      ></div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer className="border-0 p-0">
                    <div className="news-btn-sec">
                      <button
                        className="green-btn"
                        onClick={handleGuardarClick}
                      >
                        {t("GUARDAR")}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventPage;
