import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./style.css";
import Swal from "sweetalert2";
import { getAuth } from "firebase/auth";
import { RxEyeClosed } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

import GlobalURL from "../BaseUrl";

const LoginScreen = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const auth = getAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [eyeOpen, seteyeOpen] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [loading, setLoading] = useState(false);
  const relogin = async () => {
    console.log(
      "Refresh token from login ",
      localStorage.getItem("refresh-token")
    );
    const refreshToken = localStorage.getItem("refresh-token");
    if (!refreshToken) {
      clearInterval();
      return;
    }
    try {
      // const refreshedUser = await auth.currentUser.getIdTokenResult(true);
      const refreshedUser = await auth.currentUser.getIdToken(true);

      console.log("New Refresh Token from login", refreshedUser);
      localStorage.setItem("token", refreshedUser);
    } catch (error) {
      console.error(
        t("Error al actualizar el token de acceso:"),
        error.message
      );
    }
  };
  async function handleSubmit() {
    setLoading(true);
    if (username && password) {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          username,
          password
        );

        // If sign-in is successful, proceed with the following actions
        const user = userCredential.user;
        console.log("user credentials ", userCredential);
        const token2 = user.accessToken;
        localStorage.setItem("token", user.accessToken);
        localStorage.setItem(
          "refresh-token",
          user.stsTokenManager.refreshToken
        );

        // localStorage.setItem('user_id', user.data.user_id);
        // const LoginInterval =  setInterval(() => {
        // const refreshToken = localStorage.getItem("refresh-token");
        // if (!refreshToken) {
        //   clearInterval(LoginInterval);
        //   return;
        // }
        //   relogin();
        // }, 3000000); 3000000 // 50 minutes in milliseconds

        const LoginInterval = setInterval(() => {
          const refreshToken = localStorage.getItem("refresh-token");
          if (!refreshToken) {
            clearInterval(LoginInterval);
            return;
          }
          relogin();
        }, 3000000); // 3 seconds in milliseconds
        // Make a POST request to a specific endpoint with user credentials
        const response = await fetch(`${GlobalURL}/api/appmod/login`, {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            email: username,
            password: password,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token2}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        const data = await response.json();
        console.log("Data: ", data.code);
        if (data.code === 401) {
          setWrongCredentials(true);
          setLoading(false);
          return;
        }
        // Check the response status and handle accordingly
        if (!response.ok) {
          setLoading(false);
        }
        if (response.ok) {
          setLoading(false);
          console.log("data: ", data);
          // localStorage.setItem('user_id', response.data.user_id);
          localStorage.setItem("user_id", data.data.user_id);
          localStorage.setItem("email", username);
          navigate("/homepage");
        }
      } catch (error) {
        if (
          error.code === "auth/invalid-email" ||
          error.code === "auth/wrong-password"
        ) {
          // Handle invalid email or wrong password error
          console.log("Invalid email or password.");
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
          setLoading(false);
          return;
        }
        setLoading(false);
        // Handle errors during the authentication process
        if (error.code === "auth/invalid-email") {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        } else if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          setLoading(false);
          setWrongCredentials(true);
          Swal.fire(t("El ID o la contraseña son incorrectos"));
        } else {
          setLoading(false);
          Swal.fire(t("Error del Servidor"));
        }
      }
    } else {
      setLoading(false);
      Swal.fire(t("Error del Servidor"));
      //authErr.innerText = "¡Campo requerido!";
    }
  }

  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-container">
            <div className="logo-img">
              <img src="./img/logo.png" alt="" />
            </div>
            <div className="login-form-sec">
              <>
                <div className="cust-form-control">
                  <input
                    type="email"
                    className="form-input"
                    placeholder={t("usuario")}
                    value={username}
                    name="username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    style={{ borderColor: wrongCredentials ? "#b50000" : "" }}
                    onClick={() => {
                      setWrongCredentials(false);
                    }}
                    required
                  />
                </div>
                <div className="cust-form-control password-sec">
                  <NavLink
                    className="forgot-pass-link"
                    to=""
                    onClick={() => {
                      seteyeOpen(!eyeOpen);
                    }}
                  >
                    {" "}
                    {eyeOpen ? <IoEyeOutline /> : <RxEyeClosed />}
                  </NavLink>
                  <input
                    type={eyeOpen ? "text" : "password"}
                    className="form-input"
                    placeholder={t("contraseña")}
                    value={password}
                    name="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onClick={() => {
                      setWrongCredentials(false);
                    }}
                    style={{ borderColor: wrongCredentials ? "#b50000" : "" }}
                    required
                  />
                </div>
                <NavLink
                  className="forgot-pass-link"
                  id="forgetPass-word"
                  to=""
                  onClick={() => {
                    seteyeOpen(!eyeOpen);
                  }}
                >
                  {" "}
                  {t("¿Olvidaste tu contraseña?")}
                </NavLink>

                <div className="signup-btn-sec">
                  <span className="show-error w-75 mb-2 d-inline-block text-danger"></span>
                  <span className="log-btn">
                    <button
                      className="signup-btn"
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      {loading ? (
                        <div
                          className=""
                          style={{
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="loading-adjust">
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className="my-5">
                                {" "}
                                &nbsp; {t("Cargando...")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        "Iniciar sesión"
                      )}{" "}
                    </button>
                  </span>
                </div>
                <NavLink className="contact-admin" to="/contactadmin">
                  {t("Contacto Admin")}
                </NavLink>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
