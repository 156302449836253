import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { MdOutlineFormatBold } from "react-icons/md";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough } from "react-icons/bs";
import { CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { CiTextAlignCenter } from "react-icons/ci";

const BillboardAddPost = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [banner, setBanner] = useState({
    title: "",
    description: "",
    banner_type: "",
  });
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const [prev, setPrev] = useState(null);
  const [open, setOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);
  const [descripcion, setDescripcion] = useState("");

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]);
    console.log("file: ", file);
    {
      e.target.files[0] && setPrev(URL?.createObjectURL(e.target.files[0]));
    }
  };
  function handleclickinput(e) {
    inputRef.current.click();
  }
  const handleBannerContent = (e) => {
    setBanner({ ...banner, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      banner.title.length &&
      banner.description.length &&
      banner.banner_type.length
    ) {
      formData.append("team_id", -1);
      formData.append("media", file);

      for (let formfields in banner) {
        formData.append(formfields, banner[formfields]);
      }
      console.log("formdata", formData);

      try {
        const response = await fetch(
          `${GlobalURL}/api/dev/app_moderator/banner/createBanner`,
          {
            method: "POST",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "ngrok-skip-browser-warning": "69420",
            },
            body: formData,
          }
        );
        if (response.code === 401) {
          Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
          navigate("/login");
          setLoading(false);
        }

        if (response.status === 404) {
          console.log("status: ", response);
          setLoading(false);
        } else if (response.status === 200) {
          console.log("done!!");
          setBanner({ title: "", description: "" });
          navigate("/billboard");
          Swal.fire(t("Cartelra añadido con éxito!"));
          const res = await response.json();
          console.log("----> ", res);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.log("error occurred: ", err);
        setLoading(false);
      }
    } else {
      // Apply error message for news if fields are empty
      Swal.fire(t("Todos los campos son obligatorios"));
      setLoading(false);
    }
  };
  const handleDeacriptionClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // Close the modal
    setOpen(false);
  };
  const handleBold = () => {
    document.execCommand("bold", false, null);
  };
  const handleItalic = () => {
    document.execCommand("italic", false, null);
  };
  const handleStrikeThrough = () => {
    document.execCommand("strikeThrough", false, null);
  };
  const handleTextAlign = (alignment) => {
    document.execCommand("justify" + alignment, false, null);
  };
  const handleGuardarClick = () => {
    setEditorContent(editorRef.current.innerHTML);
    console.log("editorRef.current: ", editorRef.current.innerHTML);
    setBanner((prevState) => ({
      ...prevState,
      description: editorRef.current.innerHTML,
    }));

    setDescripcion(editorRef.current.innerHTML);
    handleClose();
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="billboardAdd-post-page">
            <Container fluid>
              <Row>
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="billboardAdd-post ">
                    <div className="news-header">
                      <div
                        className="back-sec"
                        onClick={() => {
                          navigate(-1);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="./img/volver.png" alt="" />
                      </div>
                      <h3
                        className="header-title inter-page-header-title"
                        style={{ fontFamily: "Francois One" }}
                      >
                        {t("Agregar Post")}
                      </h3>
                      <div className="search-icon"></div>
                    </div>
                    <div className="common-input-forms newsAddContent">
                      <form action="" onSubmit={handleSubmit}>
                        <div className="common-form-control">
                          <label>{t("Título")}</label>
                          <input
                            placeholder={t("Inserte el título del post aquí")}
                            required
                            name="title"
                            value={banner.title}
                            onChange={handleBannerContent}
                          />
                        </div>
                        <div className="common-form-control  img-video-form-control">
                          <label>{t("Foto/Video")}</label>
                          <div className="file-input-sec">
                            <p
                              className="p-tag-ref-input"
                              onClick={handleclickinput}
                              disabled={true}
                            >
                              {t("Inserte la foto o video del post aquí")}
                            </p>

                            {prev && (
                              <img
                                src={prev}
                                alt="Preview"
                                className="preview-img-icon"
                              />
                            )}

                            <div className="fileUpload btn btn--browse">
                              <input
                                id="uploadBtn"
                                onChange={handleFileSelect}
                                ref={inputRef}
                                type="file"
                                className="upload"
                                accept="image/*, video/*"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="common-form-control">
                          <label>{t("Categoría")}</label>
                          <select
                            placeholder={t("Seleccione la categoría")}
                            name="banner_type"
                            onChange={handleBannerContent}
                            value={banner.banner_type}
                          >
                            <option value="">
                              {t("Seleccione la categoría")}
                            </option>
                            <option value="home">{t("HOGAR")}</option>
                            <option value="store">{t("TIENDA")}</option>
                          </select>
                        </div>

                        <div className="common-form-control">
                          <label>{t("Descripción")}</label>
                          <input
                            onClick={() => {
                              handleDeacriptionClick();
                            }}
                            type="text"
                            placeholder={t(
                              "Inserte la descripción del post aquí"
                            )}
                            readOnly={true}
                            name="description"
                            // onChange={handleBannerContent}
                          />
                        </div>
                        <div className="err-spn text-danger text-center"> </div>
                        <div className="news-btn-sec">
                          <button
                            type="submit"
                            disabled={loading}
                            className="green-btn"
                          >
                            {loading ? (
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="loading-adjust">
                                  <div className="Loader d-flex justify-content-center align-items-center">
                                    <div
                                      className="spinner-border spinner-border-sm my-5"
                                      role="status"
                                    >
                                      <span className="sr-only"></span>
                                    </div>
                                    <div className="my-5">
                                      {" "}
                                      &nbsp; {t("Cargando...")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              t(`Guardar`)
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>

                <Modal
                  show={open}
                  className="institutional-popup common-popup"
                  onHide={handleClose}
                  centered
                >
                  <Modal.Header className="pb-0 mb-0" closeButton>
                    <Modal.Title>{t("Editar")}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="custom-text-editor w-100">
                      <div className="toolbar">
                        <button onClick={handleBold}>
                          <MdOutlineFormatBold />
                        </button>
                        <button onClick={handleItalic}>
                          <BiItalic />
                        </button>
                        <button onClick={handleStrikeThrough}>
                          <BsTypeStrikethrough />
                        </button>
                        <div className="text-align-buttons">
                          <button onClick={() => handleTextAlign("Left")}>
                            <CiTextAlignLeft />
                          </button>
                          <button onClick={() => handleTextAlign("Center")}>
                            <CiTextAlignCenter />
                          </button>
                          <button onClick={() => handleTextAlign("Right")}>
                            <CiTextAlignRight />
                          </button>
                        </div>
                      </div>
                      <div
                        className="editor"
                        ref={editorRef}
                        contentEditable
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                      ></div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer className="border-0 p-0">
                    <div className="news-btn-sec">
                      <button
                        className="green-btn"
                        onClick={handleGuardarClick}
                      >
                        {t("GUARDAR")}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillboardAddPost;
