import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import './AddCategory.css';
import GlobalURL from '../../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

const AddCategory = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };


    const [mediatype, setMediatype] = useState("img");
    const [preview, setPreview] = useState();
    const [file, setFile] = useState(null);
    const [parameter, setParameter] = useState('');
    const [heading, setHeading] = useState('');

    const [addingCat, setAddingCat] = useState(false);

    const formData = new FormData();
    const [title, setTitle] = useState('');


    // const handleInputChange = (e) => {
    //     setParameter(e.target.value);
    // };

    function handleChange(e) {
        setFile(e.target.files[0]);
        console.log("File ", file);
        setPreview(URL.createObjectURL(e.target.files[0]));
        if (e.target.files[0].name.includes('mp4')) {
            setMediatype("vids");
        }
    }
    const navigate = useNavigate();

    const addCategory = async () => {
        console.log(title, file, heading);
        if (title === '' || file === null || heading === '') {
            Swal.fire('Datos vacíos');
            return
        }

        setAddingCat(true);
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/addCategory`;
        // const apiUrl = ''
        const bearerToken = localStorage.getItem('token');
        formData.append("media", file);
        formData.append("category_name", title);
        formData.append("unit", 'execional');
        formData.append("heading", heading);

        async function makeApiRequest() {
            try {

                const response = await fetch(apiUrl, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'ngrok-skip-browser-warning': '69420',
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                });

                if (response.code === 401) {
                    Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                    navigate('/login');
                }

                if (!response.ok) {
                    Swal.fire("Error del Servidor");
                    setAddingCat(false);
                }
                if (response.ok) {
                    setAddingCat(false);
                    const data = await response.json();
                    console.log('API response:', data);
                    Swal.fire(t("Categoría agregada exitosamente"));
                    navigate('/store');
                }

            } catch (error) {
                setAddingCat(false);
                console.error('Error making API request:', error.message);
            }
        }

        // Call the function to make the API request
        makeApiRequest();

    }

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <Container fluid>
                        <Row >
                            <Col xs lg="3"></Col>
                            <Col xs lg="6">
                                <div className='addExp-sec'>
                                    <div className='news-header'>
                                        <div className='back-sec'>
                                            <img src="./img/volver.png" alt="" onClick={() => { navigate('/store') }} />
                                        </div>
                                        <h3 className='header-title' style={{ fontFamily: 'Francois One', fontSize: '23px' }}>{t('Agregar Categoría')}</h3>
                                        <div className='search-icon'>
                                        </div>
                                    </div>

                                    <div className='AddExp-sec-content'>
                                        <div className='common-input-forms newsAddContent'>
                                            <form action="">
                                                <div className='common-form-control'>
                                                    <label>{t('Título')}</label>
                                                    <input placeholder='Inserte el título de la Vivencia Mess Ice aquí' value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                                </div>

                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Foto/Video')}</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile" className="f-input" placeholder={t('Inserte la foto o video de la Vivencia Mess Ice aquí')} />
                                                        <div className="fileUpload btn btn--browse">
                                                            <input id="uploadBtn" onChange={handleChange} multiple type="file" className="upload" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Parámetro')}</label>
                                                    <div className='file-input-sec'>
                                                        {/* <input
                                                            type="text"
                                                            id="uploadFile"
                                                            className="f-input"
                                                            value={parameter}
                                                            placeholder='Inserte la foto o video de la Vivencia Mess Ice aquí'
                                                            onChange={handleInputChange}
                                                            autoComplete="off"
                                                            list="parameterList"
                                                        /> */}
                                                        <select onChange={(e) => { setHeading(e.target.value) }}>
                                                            <option defaultChecked value={''}>{t('SELECCIONAR UNIDAD')}</option>
                                                            <option value={'SIZE'}>{t('TAMAÑO')}</option>
                                                            <option value={'WEIGHT(gm)'}>{t('PESO')} (gm)</option>
                                                            <option value={'CAPACITY(li)'}>{t('CAPACIDAD')} (li)</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {file && (<div className='preview-imgCon-ae'>
                                                    {
                                                        mediatype === "img"
                                                            ? (
                                                                <div className='preview-imgCon'>
                                                                    <img src={preview} alt=''>
                                                                    </img>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className='preview-imgCon'>

                                                                    <video src={preview} >

                                                                    </video>
                                                                </div>
                                                            )
                                                    }
                                                </div>)}

                                            </form>
                                        </div>
                                        <div className='news-btn-sec'>
                                            <button className='green-btn' onClick={() => { addCategory() }} disabled={addingCat}>
                                                {
                                                    addingCat ? (<div className="loading-adjust">
                                                        <div className="Loader d-flex justify-content-center align-items-center">
                                                            <div className="spinner-border spinner-border-sm my-5" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            <div className="my-5"> &nbsp; {t('Eliminando...')}</div>
                                                        </div>
                                                    </div>) : t('GUARDAR')
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs lg="3">
                                <div className='category-options'>

                                    <ul>
                                        <li></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AddCategory
