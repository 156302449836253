import React, { useState } from 'react'
import { IoIosNotificationsOutline } from 'react-icons/io';
import { FaUserCircle } from "react-icons/fa"
import Sidebar from '../Sidebar/Sidebar';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useDispatch, useSelector } from 'react-redux';
import translations from '../../locals/translation';
import { setLanguage, selectLanguage } from '../../redux/languageSlice';

const Navbar = () => {

  const dispatch = useDispatch(); // Get dispatch function
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };
  const language = useSelector(state => state.language.value);

  const [username, setUsername] = useState(localStorage.getItem('email'));



  // Function to handle language change
  const changeLanguage = (lang) => {
    dispatch(setLanguage(lang)); // Dispatch action to update language
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
      title: t('¿Cerrar sesión?'),
      text: t('¿Quieres cerrar sesión en tu cuenta?'),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: t('Sí'),
      cancelButtonText: "No",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        clearInterval();
        navigate('/login')
      }
    });
  }


  return (
    <>
      <div className="navbar-sec" >
        <div className='navbar-container'>
          <div className='menu-bar'>
            <Sidebar />
          </div>
          <div className='logo-sec' style={{ cursor: "pointer" }} onClick={() => { navigate('/homepage') }}>
            <img src="./img/LogoMess.png" alt="brand_logo" />
          </div>
          <div className='user-profile-sec'>
            <div className='notification'>
              <IoIosNotificationsOutline /><span className='red-dot'></span>
            </div>
            <div className='user-profile' style={{ cursor: "pointer" }} onClick={() => { handleLogout() }}>
              <span className='user-img'>
                <FaUserCircle />
              </span>
              <span className='user-name'>
                {username}
              </span>
            </div>

            {/* <select >
              <option value="en" disabled={language === 'en'}>EN</option>
              <option value="es" disabled={language === 'es'}>ES</option>
            </select> */}

            <div value={language} onChange={(e) => changeLanguage(e.target.value)} className='select-lang'>
              <select>
                <option value="es" >ES</option>
                <option value="en" >ENG</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
