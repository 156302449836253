import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { GrUpload } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import React, { useState, useEffect } from "react";
//import GlobalURL from '../BaseURL';
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";
import "./style.css";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import BarChart from "../../charts/barchart";
import { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { MdOutlineFormatBold } from "react-icons/md";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough } from "react-icons/bs";
import { CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { CiTextAlignCenter } from "react-icons/ci";

const EditorBannerPage = () => {
  const language = useSelector((state) => state.language.value);
  const [loading, setLoading] = useState(false);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");

  const inputRef = useRef(null);
  const navigate = useNavigate();
  const bannerData = useLocation().state;
  console.log("Banner data ", bannerData);

  if (bannerData?.banner_type === "" || null) {
    Swal.fire("No Banner has selected!");
    navigate("/billboard");
  }
  const formData = new FormData();

  const [file, setFile] = useState(null);
  const [editBanner, setEditBanner] = useState(true);
  const [preview, setPreview] = useState("");
  const [title, setTitle] = useState("");
  const [showDescModal, setShowDescModal] = useState(false);

  useEffect(() => {
    setEditorContent(bannerData?.description);
    setTitle(bannerData?.title);
  }, []);
  const fileExtension = file;

  function handleclickinput(e) {
    inputRef.current.click();
  }

  // deleteion of banner
  const handleBannerDeletion = async () => {
    setLoading(true);
    try {
      const result = await Swal.fire({
        title: t("¿Estás seguro?"),
        showDenyButton: true,
        confirmButtonText: t("Eliminar"),
        denyButtonText: t(`Cancelar`),
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `${GlobalURL}/api/dev/app_moderator/banner/deleteBanner`,
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ banner_id: bannerData.banner_id }),
          }
        );

        if (response.code === 401) {
          Swal.fire("La sesión expiró, vuelva a iniciar sesión");
          setLoading(false);
          navigate("/login");
        }
        if (response.ok) {
          setLoading(false);
          Swal.fire(t("¡Eliminado!"), "", "success");
          navigate("/billboard");
        } else {
          Swal.fire("Error del Servidor");
        }
      }
    } catch (err) {
      setLoading(false);
      Swal.fire("Error!", `${err}`, "error");
    }
  };
  // handle update banner
  const handleEditBanner = async () => {
    setLoading(true);
    if (editBanner) {
      // Making editable state - true
      if (title === "" || editorContent === "") {
        return;
      }
      if (true) {
        const result = await Swal.fire({
          title: t("Estas seguro"),
          text: t("¿Quieres editar el banner?"),
          showDenyButton: true,
          confirmButtonText: t("Editar banner"),
          denyButtonText: t(`Cancelar`),
        });

        if (result.isConfirmed) {
          formData.append("title", title);
          formData.append("description", editorContent);
          formData.append("banner_id", bannerData?.banner_id);
          formData.append("media", file);

          try {
            const res = await fetch(
              `${GlobalURL}/api/dev/app_moderator/banner/editBanner`,
              {
                method: "POST",
                mode: "cors",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "ngrok-skip-browser-warning": "69420",
                },
                body: formData,
              }
            );
            if (res.code === 401) {
              Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
              setLoading(false);
            }

            if (res.ok) {
              await Swal.fire(t(`¡Editar!`), "", "success");
              setLoading(false);
              navigate("/billboard");
              return
            } else {
              console.log("Edit failed");
              setLoading(false);
            }
          } catch (err) {
            setLoading(false);
            console.log(err);
          }
        } else {
          setLoading(false);
          setFile(null);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const handleFileSelect = (e) => {
    if (e?.target?.files[0] === undefined) {
      return;
    }
    console.log("e.target.files[0]: ", e.target.files[0]);
    setFile(e.target.files[0]);
    setPreview(URL?.createObjectURL(e.target.files[0]));
  };

  const handleDeacriptionClick = () => {
    setShowDescModal(true);
  };

  const handleClose = () => {
    setShowDescModal(false);
  };

  const handleBold = () => {
    document.execCommand("bold", false, null);
  };

  const handleItalic = () => {
    document.execCommand("italic", false, null);
  };

  const handleStrikeThrough = () => {
    document.execCommand("strikeThrough", false, null);
  };

  const handleTextAlign = (alignment) => {
    document.execCommand("justify" + alignment, false, null);
  };

  const handleGuardarClick = () => {
    setEditorContent(editorRef.current.innerHTML);
    handleClose();
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="editarbannerPage">
            <Container fluid>
              <Row>
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="editarbanner-sec">
                    <div className="news-header">
                      <div
                        className="back-sec"
                        onClick={() => navigate(-1)}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="./img/volver.png" alt="" />
                      </div>
                      <h3 className="main-title">{t("Cartelera")}</h3>
                      <div className="search-icon">
                        <FiSearch style={{ opacity: "0" }} />
                      </div>
                    </div>
                    <div className="billboard-sec">
                      <div
                        className="editorBannerSec"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="myTeamBanner billboard-cards">
                          <div className="card-heading">
                            <h3>{`BANNER DE ${
                              bannerData.banner_type === "miteam"
                                ? "MI EQUIPO"
                                : "MI PALCO"
                            }`}</h3>
                          </div>
                          <div className="card-img editnewscard-img">
                            {file ? (
                              // <img src={URL?.createObjectURL(file)} alt="" />
                              file && file.type === "video/mp4" ? (
                                <video
                                  className="video-grid-view2"
                                  src={preview}
                                  controls
                                >
                                  {/* <source src={preview} type="video/mp4" /> */}
                                  {t(
                                    "Su navegador no soporta la etiqueta de vídeo."
                                  )}
                                </video>
                              ) : (
                                <img src={URL?.createObjectURL(file)} alt="" />
                              )
                            ) : // <img src={bannerData.imageUrl} alt="" />
                            bannerData.imageUrl &&
                              bannerData.imageUrl
                                ?.toLowerCase()
                                .endsWith(".mp4") ? (
                              <video
                                className="video-grid-view2"
                                src={bannerData.imageUrl}
                                controls
                              >
                                {/* <source src={preview} type="video/mp4" /> */}
                                {t(
                                  "Su navegador no soporta la etiqueta de vídeo."
                                )}
                              </video>
                            ) : (
                              <img src={bannerData.imageUrl} alt="" />
                            )}
                          </div>
                          {editBanner && (
                            <div
                              className="img-edit-icon"
                              onClick={handleclickinput}
                            >
                              <GrUpload className="imageupload-icon" />
                              <input
                                id="uploadFile"
                                ref={inputRef}
                                onChange={handleFileSelect}
                                style={{ opacity: "0" }}
                                type="file"
                                className="upload"
                              />
                            </div>
                          )}
                          <div className="card-body">
                            <input
                              className="input-for-edit"
                              type="text"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                            {/* <h5 className="edit-sec">
                              {bannerData.title.toUpperCase()}
                            </h5> */}
                            <p
                              className="edit-sec"
                              dangerouslySetInnerHTML={{
                                __html: editorContent,
                              }}
                              onClick={() => {
                                setShowDescModal(true);
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="space-between-btn"
                        style={{ flexWrap: "wrap" }}
                      >
                        <button
                          className="blue-btn"
                          onClick={handleEditBanner}
                          disabled={loading}
                        >
                          {t("EDITAR")}
                        </button>
                        <button
                          disabled={loading}
                          className="red-btn"
                          onClick={handleBannerDeletion}
                        >
                          {t("Eliminar Post")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Modal
                  show={showDescModal}
                  className="institutional-popup common-popup"
                  onHide={handleClose}
                  centered
                >
                  <Modal.Header className="pb-0 mb-0" closeButton>
                    <Modal.Title>{t("Editar")}</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="custom-text-editor w-100">
                      <div className="toolbar">
                        <button onClick={handleBold}>
                          <MdOutlineFormatBold />
                        </button>
                        <button onClick={handleItalic}>
                          <BiItalic />
                        </button>
                        <button onClick={handleStrikeThrough}>
                          <BsTypeStrikethrough />
                        </button>
                        <div className="text-align-buttons">
                          <button onClick={() => handleTextAlign("Left")}>
                            <CiTextAlignLeft />
                          </button>
                          <button onClick={() => handleTextAlign("Center")}>
                            <CiTextAlignCenter />
                          </button>
                          <button onClick={() => handleTextAlign("Right")}>
                            <CiTextAlignRight />
                          </button>
                        </div>
                      </div>
                      <div
                        className="editor"
                        ref={editorRef}
                        contentEditable
                        dangerouslySetInnerHTML={{ __html: editorContent }}
                      ></div>
                    </div>
                  </Modal.Body>

                  <Modal.Footer className="border-0 p-0">
                    <div className="news-btn-sec">
                      <button
                        className="green-btn"
                        onClick={handleGuardarClick}
                      >
                        {t("GUARDAR")}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>

                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorBannerPage;
