// store.js
import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice'; // Your language slice reducer

const store = configureStore({
  reducer: {
    language: languageReducer, // Add other reducers here if needed
  },
});

export default store;
