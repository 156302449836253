import React, { useEffect } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./DebateChat.css"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import GlobalURL from '../../BaseUrl';
import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';


export const data = [
    ["Subscription", "Users"],
    ["Diamond", 14],
    ["Silver", 38],
    ["Gold", 58],
];
export const options = {
    title: "",
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
        color: '#FEF6F2'
    },
}

const DigitalSecTwo = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(null);
    const [loader, setLoader] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    const [messageDetails, setMessageDetails] = useState([]);

    async function getDigitalManagersDMChats() {
        const apiUrl = `${GlobalURL}/api/dev/debate/chat/digitalManagersDMChats?page=${page}&limit=${limit}`;
        const token = localStorage.getItem('token'); // Replace with your actual Bearer token

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                },
            });

            if (!response.ok) {
                Swal.fire(t('Error del Servidor'));
                setLoader(false);
                return
            }
            if (response.code === 401) {
                Swal.fire('Token caducado, volver a iniciar sesión');
                navigate('/login');
                setLoader(false);
                return
            }

            const data = await response.json();
            console.log('E to E messages', data);

            setMessageDetails(data.data);
            setLoader(false);

        } catch (error) {
            Swal.fire(t('Error del Servidor'));
            setLoader(false);
            return
        }
    }
    const handlepageChange = (x) => {
        setLoader(true);
        if (x) {
            setPage(page + 1);
            return
        }
        setPage(page - 1);
    }

    useEffect(() => {
        getDigitalManagersDMChats();
    }, [page, limit]);


    return (
        <>
            <div className="dashboard">
                <div className="dashboard-container">
                    <div className='Digital-sec'>
                        <Container fluid>
                            <Row className="justify-content-md-center">

                                <Col xs lg="16">
                                    {
                                        loader ? (
                                            <div className="" style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className="loading-adjust">
                                                    <div className="Loader d-flex justify-content-center align-items-center">
                                                        <div className="spinner-border spinner-border-sm my-5" role="status">
                                                            <span className="sr-only"></span>
                                                        </div>
                                                        <div className="my-5"> &nbsp; {t('Eliminando...')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            messageDetails ?
                                                (messageDetails.map((item, index) => (<>
                                                    <div className="todas-card-main">
                                                        <div
                                                            className={`todas-card-list ${index === activeIndex ? 'active' : ''}`}
                                                        >
                                                            <div className="todas-card todas-three-item mb-0" >

                                                                <div className='left-sec' onClick={() => { navigate('/chathistory', { 'state': { Details: item, sender_id: item.user1.user_id, usersDetails1: item.user1, usersDetails2: item.user2 } }) }} >
                                                                    <div className='profile-img'>

                                                                        <img src={item.user1?.image_url} alt="" />

                                                                    </div>
                                                                    <div className='profile-content'>
                                                                        <h5>{item.user1?.first_name} {item.user1?.last_name}</h5>
                                                                        <p>{item.user1?.email}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="mid-sec" >
                                                                    <span className='todas-img-mid'>
                                                                        <img src="./img/exclamation.png" style={{ 'opacity': '1', 'cursor': 'pointer' }} alt="" />

                                                                    </span>
                                                                </div>

                                                                <div className='right-sec'>

                                                                    <div className='left-sec' onClick={() => { navigate('/chathistory', { 'state': { Details: item, sender_id: item.user2.user_id, usersDetails1: item.user2, usersDetails2: item.user1 } }) }}>
                                                                        <div className='profile-content'>
                                                                            <h5>{item.user2?.first_name} {item.user2?.last_name}</h5>
                                                                            <p>{item.user2?.email}</p>
                                                                        </div>
                                                                        <div className='profile-img'>
                                                                            <img src={item.user2?.image_url} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>))) : (<h4>{t('Aún no hay chats')}</h4>)
                                        )
                                    }

                                    <div className='page-switch'>
                                        {page !== 1 && <button className='page-buttons' disabled={loader} onClick={() => { handlepageChange(0) }}><IoIosArrowBack /></button>}
                                        <div className="pagenum" > {t('Página')} {page}</div>
                                        {messageDetails.length >= 5 && <button className='page-buttons' disabled={loader} onClick={() => { handlepageChange(1) }}><IoIosArrowForward /></button>}
                                    </div>

                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSecTwo
