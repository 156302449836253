import React from 'react'
import { FiSearch } from "react-icons/fi"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./ChatCompList.css";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import GlobalURL from '../../BaseUrl';
import { Link } from 'react-router-dom';


import DebateChat from '../../DebateRoom/DebateChatList/DebateChat';
import BoardMeeting from '../BoardMeeting/BoardMeeting';
// import DebateRooms from '../../DebateRoom/DebateRooms/DebateRooms';
// import DebateRooms from '../../DebateRoom/BoardMeetingLists/BoardMeeting';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

import { Chart } from "react-google-charts";
import Swal from 'sweetalert2';

export const data = [
    ["Subscription", "Users"],
    [`<img src='https://img.icons8.com/ios/80/000000/diamond.png'/>`, 25],
    ["Silver", 25],
    ["Golds", 25],
    ["Empty", 25],
];

export const options = {
    title: "",
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
        color: '#FEF6F2'
    },

    slices: {
        0: { color: '#005EAA' },
        1: { color: '#25762E' },
        2: { color: '#C37704' },
        3: { color: 'grey' },
    },
};




const DigitalSec = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };


    const [rD, setRD] = useState(true);
    const [eB, setEB] = useState(false);
    const [loader, setLoader] = useState(true);

    const navigate = useNavigate();

    return (
        <>
            <div className="dashboard">
                <div className="dashboard-container">
                    <div className='Digital-sec Digital_sec'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col className='first-sec' xs lg="3">
                                </Col>
                                <Col xs lg="6">
                                    <div className='digital-heading'>
                                        <div className='back-sec' onClick={() => { navigate('/homepage') }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3>{t('SALÓN DE DEBATES')}</h3>
                                        <div className='search-icon'>
                                            <FiSearch />
                                        </div>
                                    </div>
                                    <ul className="digitales-list">

                                        <li className={rD ? 'li-hover-data' : ''}
                                            onClick={() => {
                                                setRD(true)
                                                setEB(false)
                                            }}>
                                            <Link>
                                                <img src="./img/email.png" alt="" />
                                                <div className="dl-text"><p className='mb-0'>{t('Mensajes Directos')}</p></div>
                                            </Link>
                                        </li >
                                        <li className={eB ? 'li-hover-data' : ''}
                                            onClick={() => {
                                                setRD(false)
                                                setEB(true)
                                            }}>
                                            <Link>
                                                <img src="./img/message.png" alt="" />
                                                <div className="dl-text"><p className='mb-0'>{t('Asamblea General')}</p></div>
                                            </Link>
                                        </li>
                                    </ul>

                                    <div className='tabs-section'>
                                        {rD &&
                                            <DebateChat />
                                        }
                                        {eB &&
                                            <BoardMeeting />
                                        }
                                    </div>



                                </Col>
                                <Col xs lg="3">
                                    <div className="team-list-char-sec" hidden={true}>
                                        <div className="piechart-sec text-white"  >
                                            <Chart
                                                chartType="PieChart"
                                                data={data}
                                                options={options}
                                                width={"300px"}
                                                height={"300px"}
                                            />

                                            <p className='piechart-title'>{t('DIRIGENTES DIGITALES')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSec
