import React from 'react';
import { useState, useEffect } from 'react';
import { Col, Container, Row, } from 'react-bootstrap';
import { GrLike } from "react-icons/gr";
import { GrDislike } from "react-icons/gr";
import "./style.css";
import { useLocation } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import GlobalURL from '../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';
import BarChart from '../../charts/barchart';


const ProposalAndVotes = () => {


    const language = useSelector(state => state.language.value);
    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();

    // details
    const [proposalId, setProposalId] = useState(''); // Initial value can be anything you want
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');

    const [insigniaList, setInsigniaList] = useState([]);

    const [insigniaType, setInsigniaType] = useState('');
    const [insigniaId, setInsigniaId] = useState('');


    // const [option1, setOption1] = useState('');
    // const [option2, setOption2] = useState('');
    // const [option3, setOption3] = useState('');
    // const [option4, setOption4] = useState('');

    const [options, setOptions] = useState([]);

    const [createdData, setCreatedDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [likes, setLikes] = useState('');
    const [disLikes, setDisLikes] = useState('');

    // details
    const [category2, setCategory2] = useState(false);
    const [selectedIns, setSeectedIns] = useState(false);
    const [three, setThree] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [imgURL, setImgURL] = useState('');
    const [optionData, setOptionData] = useState([]);



    const { state } = useLocation();
    console.log('Entire Prop Data : ', state);
    const prop_id = state.proposalData.proposal_id;

    const insignia_id_required = state.proposalData.insignia_id

    const [insigniaBadge, setInsigniaBadge] = useState(insignia_id_required);


    const handleOptionClick = (option) => {
        console.log(option);
        if (option === 'INSTITUCIONAL') { setInsigniaType('INSTITUCIONAL') }
        if (option === 'INFRAESTRUCTURA') { setInsigniaType('INFRAESTRUCTURA') }
        if (option === 'TÉCNICO') { setInsigniaType('TÉCNICO') }
        setSelectedOption(option);
        setThree(false);
    };

    const deleteporposal = () => {

        const url = `${GlobalURL}/api/dev/app_moderator/deleteProposal`;
        const data = {
            proposal_id: prop_id,
        };

        Swal.fire({
            title: t("Estas seguro"),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: 'No',
            confirmButtonText: t('Sí')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(url, {
                        method: 'POST', // Assuming it's a POST request based on the provided curl command
                        headers: {
                            'Content-Type': 'application/json',
                            'ngrok-skip-browser-warning': '69420',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,

                        },
                        body: JSON.stringify(data),
                    });
                    if (response.code === 401) {
                        Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                        navigate('/login');
                    }

                    if (!response.ok) {
                        Swal.fire("Server Error");
                    }
                    if (response.ok) {
                        Swal.fire({
                            title: t('¡Eliminado!'),
                            text: t('Su archivo ha sido eliminado.'),
                            icon: "success"
                        });
                        navigate('/proposalvote')
                    }

                    const result = await response.json();
                    console.log(result);
                } catch (error) {
                    console.error('Error:', error.message);
                }

            }
        });
    }

    async function getAllInsignia() {
        try {
            const response = await fetch(`${GlobalURL}/api/dev/team_moderator/getAllInsignia`, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    "ngrok-skip-browser-warning": "69420",
                },
            });

            if (!response.ok) {
                Swal.fire('Server Error');
                return
            }

            const data = await response.json();
            console.log('Insignia data', data.data);
            setInsigniaList(data.data);


            const item = data.data.find((item, index) => item.insignia_id === state.proposalData.insignia_id)
            console.log('item: found of insignia ', item.insignia_name);
            setSelectedOption(item.insignia_name);


            // Process the data further or update your component state here
        } catch (error) {
            console.error('Error fetching data:', error.message);

        }
    }

    const fetchAPIpropvotes = async () => {
        console.log({
            proposal_id: prop_id,
            title: title,
            category: category,
            insignia_type: insigniaType,
            insignia_id: insigniaId,
        });

        const url = `${GlobalURL}/api/dev/app_moderator/updateProposal`;
        const formData = new FormData();
        formData.append('proposal_id', prop_id);
        formData.append('title', title);
        formData.append('category', category);
        if (category === 'insignia') {
            formData.append('insignia_id', insigniaBadge);
        }


        options.forEach((option, index) => {
            formData.append(`option${index + 1}`, option);
        });

        if (insigniaBadge === 4 || 7 || 10) {
            formData.append('insigniaType', 'Camino Técnico');
        }
        else if (insigniaBadge === 3 || 6 || 9) {
            formData.append('insigniaType', 'Camino Institucional');
        }
        else if (insigniaBadge === 2 || 5 || 8) {
            formData.append('insigniaType', 'Camino de Infraestructura',);
        }

        const data = Object.fromEntries(formData);
        console.log('Ready data ', data);

        // url
        try {
            const response = await fetch(url, {
                method: 'POST', // Assuming it's a POST request based on the provided curl command
                body: formData,
                headers: {
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (!response.ok) {
                Swal.fire(t('Error del Servidor'))
            }
            if (response.ok) {
                Swal.fire(t('hecho!'));
                navigate('/proposalvote')
            }
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    }

    const handleInputChange = (e, index) => {
        const newOptions = [...options];
        newOptions[index] = e.target.value;
        setOptions(newOptions);
    };

    function formatDate(inputDate) {
        const eventDate = new Date(inputDate);

        // Extract day, month, and year
        const day = eventDate.getDate();
        const month = eventDate.getMonth() + 1; // Month is zero-based, so add 1
        const year = eventDate.getFullYear().toString().slice(-2); // Get last two digits of year

        // Add leading zeros if necessary
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Concatenate day, month, and year in DD/MM/YY format
        return formattedDay + '/' + formattedMonth + '/' + year;
    }

    useEffect(() => {

        setProposalId(state.proposalData.proposal_id);
        setTitle(state.proposalData.content);
        setCategory(state.proposalData.category);
        setInsigniaType(state.proposalData.insignia_type);
        setInsigniaId(state.proposalData.insignia_id);
        setOptionData(state.optionsData);
        console.log('state.proposalData: ', state.optionsData);

        // if (state.optionsData[0]) {
        //     setOption1(state.optionsData[0].option_text);
        // }
        // if (state.optionsData[1]) {
        //     setOption2(state.optionsData[1].option_text);
        // }
        // if (state.optionsData[2]) {
        //     setOption3(state.optionsData[2].option_text);
        // }
        // if (state.optionsData[3]) {
        //     setOption4(state.optionsData[3].option_text);
        // }
        // if (state.proposalData.category === 'insignia') {
        //     setSeectedIns(true);
        //     setCategory('insignia');
        // } else {
        //     setSeectedIns(false);

        //     setCategory('general');
        // }

        if (options.length === 0) {

            for (let i = 0; i < state.optionsData.length; i++) {
                setOptions(prev => [...prev, state.optionsData[i].option_text]);
            }
        }



        console.log(state.optionsData);
        console.log("Options ", options);


        setImgURL(state.proposalData.media_url[0]);
        setCreatedDate(state.proposalData.created_at);
        setExpiryDate(state.proposalData.expiring_on);
        setLikes(state.proposalData.likes);
        setDisLikes(state.proposalData.dislikes);
        if (state.proposalData.category === 'insignia') {
            setSeectedIns('true');
        }
        getAllInsignia();


    }, [state.optionsData, state.proposalData.category, state.proposalData.content, state.proposalData.created_at, state.proposalData.dislikes, state.proposalData.expiring_on, state.proposalData.insignia_id, state.proposalData.insignia_type, state.proposalData.likes, state.proposalData.proposal_id])


    return (
        <>
            <div className='dashboard paveb-page'>
                <div className='dashboard-container'>
                    <div className='proposal-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col className="first-sec" lg="3" md="12"></Col>
                                <Col className="second-sec" lg="6" md="12">
                                    <div className='news-header-pv'>
                                        <div className='back-sec' style={{ cursor: "pointer" }} onClick={() => { navigate('/proposalvote') }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3 className='header-title' style={{ fontFamily: 'Francois One' }}>{t('EDITAR PROPUESTAS')}</h3>
                                        <div className='search-icon'>
                                            <FiSearch  style={{opacity:'0'}}/>
                                        </div>
                                    </div>
                                    <div className='proposal-vote-sec legend-tabs'>
                                        {
                                            editMode &&

                                            <div className='two-option-tags'>
                                                <div className='div-with-drop'>
                                                    <p className='tags-option-insignia' onClick={() => { if (editMode) { setCategory2(!category2); } setThree(false); }}>{selectedIns ? 'INSIGNIA' : 'GENERAL'}<div className='down-arrow'><IoIosArrowDown style={{ width: '36px', height: '25px', marginTop: '-17px', marginBottom: '-17px', marginRight: '-17px', marginLeft: '7px', color: '#ffffff5e' }} /></div></p>
                                                    {category2 && <div className='dropdown-new1'><p className='dropdown-p-tag' onClick={() => { setSeectedIns(false); setCategory('general'); setCategory2(false); setCategory('general'); }}>{t('General')}</p><p className='dropdown-p-tag' onClick={() => { setSeectedIns(true); setCategory('insignia'); setCategory2(false); }}>{t('Insignia')}</p></div>}
                                                </div>

                                                {selectedIns && (<div className='div-with-drop'>
                                                    <p className='tags-option-insignia toi-dropdown' onClick={() => { if (editMode) { setThree(!three); setCategory2(false) } }}>{selectedOption}<div className='down-arrow'><IoIosArrowDown style={{ width: '36px', height: '25px', marginTop: '-17px', marginBottom: '-17px', marginRight: '-17px', marginLeft: '7px', color: '#ffffff5e' }} /></div></p>
                                                    {three && <div className='dropdown-new2'>
                                                        {
                                                            insigniaList.map((item, index) => (<>
                                                                <p key={index} className='dropdown-p-tag' onClick={() => { handleOptionClick(item.insignia_name_es); setInsigniaType('camino tecnico'); setInsigniaBadge(item.insignia_id) }}>
                                                                    {item.insignia_name_es}
                                                                </p>
                                                            </>
                                                            ))
                                                        }


                                                        {/* <p className='dropdown-p-tag' onClick={() => { handleOptionClick('INSTITUCIONAL'); setInsigniaType('camino institutional') }}>
                                                        INSTITUCIONAL
                                                    </p>
                                                    <p className='dropdown-p-tag' onClick={() => { handleOptionClick('INFRAESTRUCTURA'); setInsigniaType('camino de infrastructure') }}>
                                                        INFRAESTRUCTURA
                                                    </p> */}

                                                    </div>}
                                                </div>)}

                                            </div>
                                        }
                                        <div className='general-tab-sec' >
                                            <Row style={{ justifyContent: 'center' }}>
                                                {selectedIns ? (
                                                    <Col xs lg="4">
                                                        <div className='general-card'>
                                                            <div className='card-header'>
                                                                <div className="card-imgs">
                                                                    <img src={imgURL}
                                                                        alt="" />
                                                                </div>
                                                                <div className='card-dates-sec'>
                                                                    <div className='card-logo'>
                                                                        <img src={`./img/i${insigniaId}.svg`} alt="" />
                                                                    </div>
                                                                    <div className='created-date-sec'>
                                                                        <p>{t('creada')}</p>
                                                                        <p >{formatDate(createdData)}</p>

                                                                    </div>
                                                                    <div className='wins-date-sec'>
                                                                        <p>{t('vence')}</p>
                                                                        {/* {editMode ? (<input className='date-input-am' type='date' value={expiryDate} onChange={(e) => { setExpiryDate(e.target.value) }} />) : (<p >{expiryDate}</p>)} */}
                                                                        <p >{formatDate(expiryDate)}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='content-sec'>
                                                                    <div className='content-data'>
                                                                        <p>
                                                                            {editMode ? (<input value={title} onChange={(e) => { setTitle(e.target.value) }} />) : (<p >{title}</p>)}
                                                                        </p>
                                                                    </div>
                                                                    <div className='like-sec'>
                                                                        <GrLike />
                                                                        <span>{likes}</span>
                                                                    </div>
                                                                    <div className='dislike-sec'>
                                                                        <GrDislike />
                                                                        <span>
                                                                            {disLikes}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className='check-box-sec'>
                                                                    <ul className='check-box-list'>

                                                                        {
                                                                            options.map((item, index) => (
                                                                                <li className='list-content' key={index}>
                                                                                    <input type="checkbox" disabled={true} id={`checkbox-${index}`} />
                                                                                    <label htmlFor={`checkbox-${index}`}>
                                                                                        <span></span>
                                                                                        {editMode ? (
                                                                                            <input
                                                                                                className='input-class'
                                                                                                value={item}
                                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                            />
                                                                                        ) : (
                                                                                            <p className='p-tag'>{item}</p>
                                                                                        )}
                                                                                    </label>
                                                                                </li>
                                                                            ))
                                                                        }

                                                                    </ul>
                                                                </div>
                                                                <div className='bar-chart-sec'>
                                                                    <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                    <div className='chart-content'>
                                                                        {/* You may replace this with your actual chart rendering logic */}
                                                                        {/* <img src="./img/bar-chart.png" alt="" /> */}

                                                                        <BarChart optionsList={optionData} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>) :
                                                    (<Col xs lg="4">
                                                        <div className='general-card'>
                                                            <div className='card-header'>
                                                                <div className="card-imgs">
                                                                    <img src={imgURL}
                                                                        alt="" />
                                                                </div>
                                                                <div className='card-dates-sec'>
                                                                    <div className='created-date-sec'>
                                                                        <p>{t('creada')}</p>
                                                                        <p >{formatDate(createdData)}</p>
                                                                    </div>
                                                                    <div className='wins-date-sec'>
                                                                        <p>{t('vence')}</p>
                                                                        <p>{formatDate(expiryDate)}</p>
                                                                        {/* {editMode ? (<input className='date-input-am' type='date' value={expiryDate} onChange={(e) => { if (e.target.value <= expiryDate) { alert('Only Extendable'); return } setExpiryDate(e.target.value) }} />) : (<p  >{expiryDate}</p>)} */}
                                                                    </div>
                                                                </div>
                                                                <div className='content-sec'>
                                                                    <div className='content-data'>
                                                                        <p> {editMode ? (<input value={title} onChange={(e) => { setTitle(e.target.value) }} />) : (<p >{title}</p>)}
                                                                        </p>
                                                                    </div>
                                                                    <div className='like-sec'>
                                                                        <GrLike />
                                                                        <span>{likes}</span>
                                                                    </div>
                                                                    <div className='dislike-sec'>
                                                                        <GrDislike />
                                                                        <span>
                                                                            {disLikes}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='card-body'>
                                                                <div className='check-box-sec'>
                                                                    <ul className='check-box-list'>
                                                                        {
                                                                            options.map((item, index) => (

                                                                                <li className='list-content' key={index}>
                                                                                    <input type="checkbox" disabled={true} id={`checkbox-${index}`} />
                                                                                    <label htmlFor={`checkbox-${index}`}>
                                                                                        <span></span>
                                                                                        {editMode ? (
                                                                                            <input
                                                                                                className='input-class'
                                                                                                value={item}
                                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                            />
                                                                                        ) : (
                                                                                            <p className='p-tag'>{item}</p>
                                                                                        )}
                                                                                    </label>

                                                                                </li>
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                <div className='bar-chart-sec'>
                                                                    <h5 className='chart-heading'>{t('Resultados')}</h5>
                                                                    <div className='chart-content'>
                                                                        {/* You may replace this with your actual chart rendering logic */}
                                                                        {/* <img src="./img/bar-chart.png" alt="" /> */}

                                                                        <BarChart optionsList={optionData} />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>)}

                                            </Row>
                                        </div>

                                        <div className='proposal-btn-sec'>
                                            <div className="buttonGroup" style={{ marginTop: '50px' }}>

                                               
                                                    <div className='proposal-btns'>
                                                        <button className='blue-btn' onClick={() => { setEditMode(!editMode) }}>{editMode ? t('CANCELAR') : t('EDITAR')}</button>
                                                    </div>
                                                
                                                    <div className='proposal-btns'>
                                                        <button className='green-btn' onClick={() => { fetchAPIpropvotes() }} disabled={!editMode}>{t('GUARDAR')}</button>
                                                    </div>
                                                
                                                    <div className='proposal-btns'>
                                                        <button className='red-btn' onClick={() => { deleteporposal() }}>{t('ELIMINAR PROPUESTA')}</button>
                                                    </div>
                                                

                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="third-sec" lg="3" md="12">
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProposalAndVotes
