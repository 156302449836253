import React, { useEffect } from 'react';
import { useState } from 'react';
import './report.css';
import Swal from 'sweetalert2';
import GlobalURL from '../BaseUrl';
import { useNavigate } from 'react-router-dom';

function Reports() {
    const [data, setData] = useState([]);
    const [reports, setReports] = useState([]);
    const [complaints, setComplaints] = useState([]);

    const [read, setRead] = useState([]);
    const [unread, setUnread] = useState([]);
    const [clicked, setClicked] = useState(false);
    const navigate = useNavigate();


    async function fetchData() {
        // Example usage
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllReport`;

        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'GET', // or 'GET', 'PUT', etc. depending on your API
                headers: {
                    'Content-Type': 'application/json',
                    "ngrok-skip-browser-warning": "69420",
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`, // Replace with your actual Bearer token
                    // Additional headers...
                },
            });

            if (response.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                navigate('/login');
            }
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const resp = await response.json();
            const user_data_Id = resp.data;
            console.log(user_data_Id);
            setData(resp.data);

        } catch (error) {
            console.error('Error fetching data:', error);
            throw error; // Rethrow the error to be handled by the calling code
        }
    }

    

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <div className="table-container">
            <div className="left-section">
                <div className="left-part-table">
                    <div className="left-list">
                        <p className="list-item">Todo <span>7</span></p>
                        <p className="list-item">Denucias <span>5</span></p>
                        <p className="list-item">Reportes <span>2</span></p>
                        <p className="list-item">Marcar como leído</p>
                        <p className="list-item">Marcar como no leído</p>
                    </div>
                </div>
            </div>

            <div className="right-section">
                <h2 className="main-heading">Main Heading - Right Section</h2>
                <div className="right-part-table">
                    <h3 className="sub-heading">Right Part Table</h3>
                    {
                        data.map((item, index) => (
                            <div className="right-list" style={{ fontFamily: true? 'Century-Bold' : 'bold' }}>
                                <label className="checkbox-item" onClick={() => { setClicked(true) }}>
                                    <input type="checkbox" />{item.report_id} {item.reason}
                                </label>
                            </div>
                        ))
                    }

                    {
                        data.map((item, index) => (
                            <div className="right-list" >
                                <div className='heading'><div> Asunto</div> <span>{item.reason}</span></div>
                                <div className='full-bar'>ID <span>{item.report_id}</span></div>
                                <div className='full-bar'>De <span>{item.user_id}</span></div>
                                <div className='full-bar'>El Dirigente Digital @dirigentedigital ha denunciado el siguiente post: <span><div className='assemblyDataLink'></div></span></div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    );
}

export default Reports