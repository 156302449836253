import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

const AddStreaming = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [duration, setDuration] = useState("");
  const [startTime, setStartTime] = useState("");
  const [title, setTitle] = useState("");
  const [teamId, setTeamId] = useState("-1");
  const [streamingUrl, setStreamingUrl] = useState("");
  const [media, setMedia] = useState(null);
  const [prev, setPrev] = useState(null);

  const createStream = async () => {
    setIsLoading(true);
    const endpoint = `${GlobalURL}/api/dev/app_moderator/streaming/addStream`;
    const formData = new FormData();
    formData.append("duration", duration);
    formData.append("start_time", startTime);
    formData.append("title", title);
    formData.append("team_id", teamId);
    formData.append("streaming_url", streamingUrl);
    formData.append("media", media);

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        setIsLoading(false);
        Swal.fire(t("Error del Servidor"));
      } else if (response.ok) {
        setIsLoading(false);
        Swal.fire(t("Hecho"));
        navigate("/streaming");
      }
      setIsLoading(false);
      const data = await response.json();
      console.log("Response:", data);
    } catch (error) {
      setIsLoading(false);
      Swal.fire(t("Error del Servidor"));
    }
  };

  const fetchTeamIds = async () => {
    try {
      const response = await fetch("", {
        method: "GET",
        headers: {
          // Include any additional headers here, e.g., authorization
        },
      });

      if (!response.ok) {
        Swal.fire(t("Error del Servidor"));
      }

      const data = await response.json();
      console.log("Response:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createStream();
  };

  useEffect(() => {
    fetchTeamIds();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="add-streaming-page">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="news-header">
                    <div
                      className="back-sec"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <img src="./img/volver.png" alt="back_icon" />
                    </div>
                    <h3
                      className="header-title inter-page-header-title"
                      style={{ fontFamily: "Francois One" }}
                    >
                      {t("Agregar Stream")}
                    </h3>
                    <div className="search-icon">{/* <FiSearch /> */}</div>
                  </div>

                  <div className="common-input-forms addstreaming">
                    <form onSubmit={handleSubmit}>
                      <div className="common-form-control">
                        <label>{t("Nombre de Stream")}</label>
                        <input
                          placeholder={t("Inserte el título del stream aquí")}
                          required
                          name="title"
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                      <div className="common-form-control">
                        <label>{t("Duración" + t("(hour)"))}</label>
                        <input
                          placeholder={t(
                            "Inserte la duración de la transmisión aquí"
                          )}
                          required
                          name="title"
                          type="number"
                          value={duration}
                          onChange={(e) => {
                            if (e.target.value < 1) {
                              return;
                            }
                            setDuration(e.target.value);
                          }}
                        />
                      </div>
                      <div className="common-form-control">
                        <div>
                          <label>{t("Foto/Video")}</label>
                          <input
                            placeholder={t(
                              "Inserte la foto o video del stream aquí"
                            )}
                            accept="image/*"
                            type="text"
                            style={{
                              marginBottom: "-20px",
                              background: "transparent",
                            }}
                            readOnly={true}
                          />

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <input
                              placeholder={t(
                                "Inserte la foto o video de la noticia aquí"
                              )}
                              accept="image/*"
                              type="file"
                              onChange={(e) => {
                                setMedia(e.target.files[0]);
                                e.target.files[0] &&
                                  setPrev(
                                    URL?.createObjectURL(e.target.files[0])
                                  );
                              }}
                              style={{ opacity: 0, marginTop: "-12px" }}
                            />
                            {prev && (
                              <img
                                src={prev}
                                alt="Preview"
                                className="preview-img-icon"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="common-form-control">
                        <label>{t("Fecha de Stream")}</label>
                        <input
                          placeholder={t(
                            "Inserte la duración de la transmisión aquí"
                          )}
                          type="date"
                          required
                          name="content"
                          value={startTime}
                          onChange={(e) => {
                            setStartTime(e.target.value);
                          }}
                        />
                      </div>
                      <div className="common-form-control">
                        <label>{t("URL de Streaming")}</label>
                        <input
                          required
                          placeholder={t("Inserte el link al stream aquí")}
                          name="content"
                          type="text"
                          value={streamingUrl}
                          onChange={(e) => setStreamingUrl(e.target.value)}
                        />
                      </div>
                      <div className="news-btn-sec">
                        <button
                          className="green-btn"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading ? t("Cargando...") : t("Guardar")}
                        </button>
                      </div>
                    </form>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStreaming;
