import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

const StreamingPage = () => {
  const [deleting, setDeleting] = useState(false);

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [today, setToday] = useState([]);
  const [week, setWeek] = useState([]);
  const [weekaf, setWeekaf] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState([]);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/streaming/getAllStreaming`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Cookie: "GCLB=CIjZ8bOcusOyEg", // Include your specific cookie value
          },
        }
      );

      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (!response.ok) {
      }

      const result = await response.json();
      console.log("streaming data", result);

      setToday(result.data.today);
      setWeek(result.data.thisWeek);
      setWeekaf(result.data.afterThisWeek);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteStreaming = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("Estas seguro"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await fetch(
              `${GlobalURL}/api/dev/app_moderator/streaming/deleteStream`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                  "Content-Type": "application/json",
                  Cookie: "GCLB=CIjZ8bOcusOyEg", // Include your specific cookie value
                },
                body: JSON.stringify({
                  streaming_id: id,
                }),
              }
            );
            if (response.code === 401) {
              Swal.fire("La sesión expiró, vuelva a iniciar sesión");
              navigate("/login");
            }

            if (!response.ok) {
              Swal.fire("Error del Servidor");
            }
            if (response.ok) {
              fetchData();
              swalWithBootstrapButtons.fire({
                title: t("¡Eliminado!"),
                text: "",
                icon: "success",
              });
            }
            const result = await response.json();
            console.log(result);
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: t("Cancelado"),
            text: "",
            icon: "error",
          });
        }
      });
  };

  const handleSearch = (e) => {
    const dataTemp = originalData;
    console.log("originalData: ", originalData);
    setSearchTerm(e.target.value);
    console.log("Search Term: ", e.target.value);
    if (e.target.value === "") {
      // setDateData(originalData);
      return;
    }
    const result = dataTemp.filter((item) =>
      item.title.toLowerCase().includes(e.target.value)
    );
    console.log("result: ", result);

    if (result.length === 0) {
      console.log("Empty Arry executed:  ");
      return;
    }
    // setDateData(result);
  };

  useEffect(() => {
    fetchData();
    //fetchTeams();
  }, []);

  useEffect(() => {
    fetchData();
    //fetchTeams();
  }, [deleting]);

  return (
    <>
      <div className="dashboard sp-page">
        <div className="dashboard-container">
          <div className="streamingPage">
            <Container fluid>
              <Row>
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="streamingPage-sec">
                    <div className="news-header">
                      <div
                        className="back-sec"
                        onClick={() => {
                          navigate("/homepage");
                        }}
                      >
                        <img src="./img/volver.png" alt="" />
                      </div>
                      <h3 className="main-title">{t("Streaming")}</h3>
                      <div className="search-icon" style={{ display: "flex" }}>
                        {searchActive && (
                          <div className="searchBarInput">
                            <input
                              className="search-tag-bar"
                              placeholder={t("Buscar")}
                              value={searchTerm}
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                            />
                          </div>
                        )}
                        <FiSearch
                          className="search-icon-click"
                          onClick={() => {
                            setSearchActive(!searchActive);
                          }}
                        />
                      </div>
                    </div>
                    <div className="streaming-sec legend-tabs">
                      <Tabs defaultActiveKey="hoy" id="fill-tab-example" fill>
                        <Tab
                          eventKey="hoy"
                          title={t("HOY")}
                          onSelect={(eventKey) => {
                            console.log(eventKey);
                          }}
                        >
                          <div className="todays-sec">
                            <div className="streaming-card-sec">
                              {today?.length > 0 ? (
                                today.map((item, index) => (
                                  <div className="streaming-card" key={index}>
                                    {/* <img
                                      className="delete-img-streaming"
                                      src="./img/delete.png"
                                      alt="delete"
                                    /> */}
                                    <div className="card-img">
                                      <div className="blurImg-sec">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                      <MdDeleteForever
                                        className="delete-img-streaming"
                                        onClick={() => {
                                          deleteStreaming(item.streaming_id);
                                        }}
                                      />
                                      <div className="profile-img">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                    </div>
                                    <div className="card-content">
                                      <p className="user-name">{item.title}</p>
                                      <p className="user-hrs">
                                        {item.duration} hr{" "}
                                        {item.team_id === -1
                                          ? "Mess-I global"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="messege">
                                  {t("No hay streaming de hoy disponible")}
                                </div>
                              )}
                            </div>
                            <div className="center-space-btn">
                              <button
                                className="green-btn"
                                onClick={() => {
                                  navigate("/addstreaming");
                                }}
                              >
                                {t("Agregar Stream")}
                              </button>
                            </div>
                          </div>
                        </Tab>
                        <Tab
                          eventKey="PRÓXIMA-SEMANA"
                          title={t("PRÓXIMA SEMANA")}
                          onSelect={(eventKey) => {
                            console.log(eventKey);
                          }}
                        >
                          <div className="todays-sec">
                            <div className="streaming-card-sec">
                              {week?.length > 0 ? (
                                week.map((item, index) => (
                                  <div className="streaming-card" key={index}>
                                    <div className="card-img">
                                      <div className="blurImg-sec">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                      <MdDeleteForever
                                        className="delete-img-streaming"
                                        onClick={() => {
                                          deleteStreaming(item.streaming_id);
                                        }}
                                      />
                                      <div className="profile-img">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                    </div>
                                    <div className="card-content">
                                      <p className="user-name">{item.title}</p>
                                      <p className="user-hrs">
                                        {item.duration} hr{" "}
                                        {item.team_id === -1
                                          ? "Mess-I global"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="messege">
                                  {t(
                                    "No hay transmisión disponible la próxima semana"
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="center-space-btn">
                              <button
                                className="green-btn"
                                onClick={() => {
                                  navigate("/addstreaming");
                                }}
                              >
                                {t("Agregar Stream")}
                              </button>
                            </div>
                          </div>
                        </Tab>
                        <Tab
                          eventKey="vale-la-pena-esperar"
                          title={t("Vale la pena esperar")}
                          onSelect={(eventKey) => {
                            console.log(eventKey);
                          }}
                        >
                          <div className="todays-sec">
                            <div className="streaming-card-sec">
                              {weekaf?.length > 0 ? (
                                weekaf.map((item, index) => (
                                  <div className="streaming-card" key={index}>
                                    <div className="card-img">
                                      <div className="blurImg-sec">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                      <MdDeleteForever
                                        className="delete-img-streaming"
                                        onClick={() => {
                                          deleteStreaming(item.streaming_id);
                                        }}
                                      />
                                      <div className="profile-img">
                                        <img src={item.thumbnail_url} alt="" />
                                      </div>
                                    </div>
                                    <div className="card-content">
                                      <p className="user-name">{item.title}</p>
                                      <p className="user-hrs">
                                        {item.duration} hr{" "}
                                        {item.team_id === -1
                                          ? "Mess-I global"
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div className="messege">
                                  {t("No Streaming Available")}
                                </div>
                              )}
                            </div>
                            <div className="center-space-btn">
                              <button
                                className="green-btn"
                                onClick={() => {
                                  navigate("/addstreaming");
                                }}
                              >
                                {t("Agregar Stream")}
                              </button>
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default StreamingPage;
