import React, { useEffect } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./style34.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';
import GlobalURL from '../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';
import { FiSearch } from 'react-icons/fi';

export const data = [
    ["Subscription", "Users"],
    ["Diamond", 14],
    ["Silver", 38],
    ["Gold", 58],
];

export const options = {
    title: "",
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
        color: '#FEF6F2'
    },
}

const DigitalSecTwo = () => {


    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const [bloeli, setBloeli] = useState([]);
    const [bloeliBl, setBloeliBl] = useState([]);
    const [bloeliEl, setBloeliEl] = useState([]);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(null);
    const [dummyData, setDummyData] = useState([]);

    const [tab, setTab] = useState('todas');


    const [originalData, setOriginalData] = useState('');
    const [originalDataEL, setOriginalDataEL] = useState('');
    const [originalDataBL, setOriginalDataBL] = useState('');

    const [searchActive, setSearchActive] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [rerender, setRerender] = useState(false);


    const handleToggleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    async function getBlockEliminatedList() {
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllBlockedEliminatedUser`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                // body:JSON.stringify({'search':''})
            });

            // Check if the request was successful (status code 2xx)
            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }
            if (response.ok) {
                const responseData = await response.json();
                setBloeli(responseData.data);
                setOriginalData(responseData.data);

                const temp = responseData.data;

                // Filter and show all data where isBlocked is true
                const blockedData = temp.filter(item => item.is_blocked === true);
                setBloeliBl(blockedData);
                setOriginalDataBL(blockedData);

                // Filter and show all data where isDeleted is true
                const deletedData = temp.filter(item => item.is_deleted === true);
                setBloeliEl(deletedData);
                setOriginalDataEL(deletedData);

                console.log("Blocked/Eliminated List", responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    };
    function formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate;
    };
    const eliminateUser = (async (id) => {

        const apiUrl = `${GlobalURL}/api/dev/app_moderator/eliminateUser`;
        const token = localStorage.getItem('token');

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: t("Estas seguro"),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("Sí"),
            cancelButtonText: "No",
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                            "ngrok-skip-browser-warning": "69420",
                        },

                        body: JSON.stringify({ "user_id": id })
                    });

                    // Check if the request was successful (status code 2xx)
                    if (response.code === 401) {
                        Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                        navigate('/login');
                    }
                    if (response.ok) {
                        const responseData = await response.json();
                        console.log(responseData.data);
                        if (responseData.message === "User Already eliminated") {
                            swalWithBootstrapButtons.fire({
                                title: "Usuario ya eliminado",
                                text: "no puede incorporarse de nuevo",
                                icon: "success"
                            });

                        }
                        else {
                            swalWithBootstrapButtons.fire({
                                title: "Eliminado!",
                                text: "",
                                icon: "success"
                            });
                        }
                        setRerender(!rerender);


                    } else {
                        // If the server returns an error status code
                        Swal.fire(t('Error del Servidor'))
                    }
                } catch (error) {
                    // Handle any network or other errors that may occur during the fetch
                    console.error('Error:', error.message);
                    Swal.fire(t('Error del Servidor'))
                }

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) { }
        });
    });
    async function blockUnblock(id) {
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/changeUserStatus`;
        const token = localStorage.getItem('token');
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: t("Estas seguro"),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("Sí"),
            cancelButtonText: "No",
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await fetch(apiUrl, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                            "ngrok-skip-browser-warning": "69420",
                        },
                        body: JSON.stringify({
                            "user_id": id
                        })
                    });

                    if (!response.ok) {
                        Swal.fire(t('Error del Servidor'));
                    }
                    if (response.code === 401) {
                        Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                        navigate('/login');
                    }
                    if (response.ok) {
                        const data = await response.json();
                        console.log('API Response:', data.message);
                        swalWithBootstrapButtons.fire({
                            title: "Hecho",
                            text: "",
                            icon: "success"
                        });
                    }
                    setRerender(!rerender);
                }
                catch (error) {
                    console.error('Error fetching data:', error.message);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) { }
        });

    };

    useEffect(() => {
        getBlockEliminatedList();
    }, [])
    useEffect(() => {
        getBlockEliminatedList();
    }, [rerender]);

    const handleSearch = (e) => {

        switch (tab) {
            case 'bloqueados':
                const dataTemp1 = originalDataBL;
                console.log('originalData: ', originalDataBL)
                setSearchTerm(e.target.value);
                console.log('Search Term: ', e.target.value);
                if (e.target.value === '') {
                    setBloeliBl(originalData);
                    return
                }
                const result1 = dataTemp1.filter((item) =>
                    item.first_name.toLowerCase().includes(e.target.value)
                );
                console.log('result: from blocked ', result1)
                if (result1.length === 0) {
                    console.log('Empty Arry executed:')
                    return
                }
                setBloeliBl(result1);
                break;

            case 'eliminados':
                const dataTemp2 = originalDataEL;
                console.log('originalData: ', originalDataEL)
                setSearchTerm(e.target.value);
                console.log('Search Term: ', e.target.value);
                if (e.target.value === '') {
                    setBloeliEl(originalDataEL);
                    return
                }
                const result2 = dataTemp2.filter((item) =>
                    item.first_name.toLowerCase().includes(e.target.value)
                );
                console.log('result: ', result2)
                if (result2.length === 0) {
                    console.log('Empty Arry executed:')
                    return
                }
                setBloeliEl(result2);
                break;


            default:
                const dataTemp = originalData;
                console.log('originalData: ', originalData)
                setSearchTerm(e.target.value);
                console.log('Search Term: ', e.target.value);
                if (e.target.value === '') {
                    setBloeli(originalData);
                    return
                }
                const result = dataTemp.filter((item) =>
                    item.first_name.toLowerCase().includes(e.target.value)
                );
                console.log('result: ', result)
                if (result.length === 0) {
                    console.log('Empty Arry executed:')
                    return
                }
                setBloeli(result);
                break;
        }
    }

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    //     if (!e.target.value) {
    //         getBlockEliminatedList();
    //     }
    // }

    async function getSearchData() {
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllBlockedEliminatedUser`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },

                // body:JSON.stringify({'search':searchTerm})
            });

            // Check if the request was successful (status code 2xx)
            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }
            if (response.ok) {
                const responseData = await response.json();
                setDummyData(responseData.data);

            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    };

    return (
        <>
            <div className="dashboard">
                <div className='search-icon-in managers' style={{ display: 'flex' }} >
                    {searchActive && <div className="searchBarInput"><input className='search-tag-bar' placeholder={t('Buscar')} value={searchTerm} onChange={handleSearch} /></div>}
                    <FiSearch className='search-icon-click' onClick={getSearchData} />
                </div>
                <div clasdigitalsectionsName="dashboard-container">
                    <div className='Digital-sec'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="16">
                                    <div className='tabs-section' >
                                        <div className='tabs-cards-sec'>
                                            {/* disisection 2 */}

                                            {
                                                dummyData.length > 0 ? (
                                                    <Tabs>
                                                        <Tab eventKey="todas" title={t('TODAS')} >
                                                            <div className='tab-content-wrapper'>
                                                                {
                                                                    dummyData.length < 1 ? (<div>{t('Ningún miembro todavía')}</div>) : (
                                                                        dummyData.map((item, index) => (<>
                                                                            <div className="todas-card-main">
                                                                                <div
                                                                                    className={`todas-card-list ${index === activeIndex ? 'active' : ''}`}
                                                                                >
                                                                                    <div className="todas-card todas-three-item mb-0" >

                                                                                        <div className='left-sec' onClick={() => handleToggleClick(index)}>
                                                                                            <div className='profile-img'>
                                                                                                <img src={item.image_url} alt="" />
                                                                                            </div>
                                                                                            <div className='profile-content'>
                                                                                                <h5>{item.first_name} {item.last_name}</h5>
                                                                                                <p>{item.username}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mid-sec" onClick={() => handleToggleClick(index)}>
                                                                                            <span className='todas-img-mid'>
                                                                                                {/* <img src="./img/details.png" alt="" /> */}
                                                                                                {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className='right-sec'>

                                                                                            <span className='todas-img right-img'>
                                                                                                {!(index === activeIndex) && (<img src='./img/ban.png' alt='' onClick={() => { blockUnblock(item.user_id) }} />)}
                                                                                                {!(index === activeIndex) && (<img src='./img/delete2.png' alt='' onClick={() => { eliminateUser(item.user_id) }} />)}
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className={`todas-card-content`} >
                                                                                        <div className="tc-Maintitle">
                                                                                            {/* <p className='mb-0'>
                                                                            Solicitud de verificación de Dirigente Digital
                                                                        </p> */}
                                                                                        </div>
                                                                                        <div className="tc-Semititle">
                                                                                            <p className='mb-0'>
                                                                                                {t('USUARIO')} {item.is_deleted ? 'ELIMINADO' : 'BLOQUEADO'} {item.is_blocked && item.is_deleted ? 'Y BLOQUEADO' : ''}
                                                                                            </p>

                                                                                        </div>
                                                                                        <ul className="verification-requirements p-0 m-0">
                                                                                            <li>
                                                                                                {t('cantidad de denuncias:')} {item.complaints}
                                                                                            </li>
                                                                                            <li>
                                                                                                {t('detalles:')}
                                                                                            </li>

                                                                                            <li>
                                                                                                -
                                                                                            </li>
                                                                                            {item.is_blocked && (
                                                                                                <li>
                                                                                                    {t('El usuario fue bloqueado el día')} {item.last_blocked ? formatDate(item.last_blocked) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                            {item.is_deleted && (
                                                                                                <li>
                                                                                                    {t('El usuario fue eliminado el día')} {item.last_deleted ? formatDate(item.last_deleted) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>

                                                                                        <div className="tc-footer">
                                                                                            <div className="tc-profile" onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                                                                <p>{t('Perfil')}</p>
                                                                                                <img src="./img/userprofile.png" alt="" />
                                                                                            </div>
                                                                                            <div className="tc-conditions">
                                                                                                <div className="todas-img"><p>{t('Rechazar')}</p> <img src="./img/ban.png" onClick={() => { blockUnblock(item.user_id) }} alt="" /></div>
                                                                                                <div className="todas-img right-img">
                                                                                                    <div className="todas-img"><p>{t('Eliminar')}</p> <img src='./img/delete2.png' onClick={() => { eliminateUser(item.user_id) }} alt="" /></div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </>))
                                                                    )
                                                                }
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                ) : (
                                                    <>
                                                        <Tabs defaultActiveKey="todas" id="fill-tab-example" style={{ fontFamily: 'Francois One' }} fill onSelect={(eventKey) => setTab(eventKey)} >
                                                            <Tab eventKey="todas" title={t('TODAS')} >
                                                                {
                                                                    bloeli.length < 1 ? (<div>{t('Ningún miembro todavía')}</div>) : (
                                                                        bloeli.map((item, index) => (<>
                                                                            <div className="todas-card-main">
                                                                                <div
                                                                                    className={`todas-card-list ${index === activeIndex ? 'active' : ''}`}
                                                                                >
                                                                                    <div className="todas-card todas-three-item mb-0" >

                                                                                        <div className='left-sec' onClick={() => handleToggleClick(index)}>
                                                                                            <div className='profile-img'>
                                                                                                <img src={item.image_url} alt="" />
                                                                                            </div>
                                                                                            <div className='profile-content'>
                                                                                                <h5>{item.first_name} {item.last_name}</h5>
                                                                                                <p>{item.username}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mid-sec" onClick={() => handleToggleClick(index)}>
                                                                                            <span className='todas-img-mid'>
                                                                                                {/* <img src="./img/details.png" alt="" /> */}
                                                                                                {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className='right-sec'>

                                                                                            <span className='todas-img right-img'>
                                                                                                {!(index === activeIndex) && (<img src='./img/ban.png' alt='' onClick={() => { blockUnblock(item.user_id) }} />)}
                                                                                                {!(index === activeIndex) && (<img src='./img/delete2.png' alt='' onClick={() => { eliminateUser(item.user_id) }} />)}
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className={`todas-card-content`} >
                                                                                        <div className="tc-Maintitle">
                                                                                            {/* <p className='mb-0'>
                                                                            Solicitud de verificación de Dirigente Digital
                                                                        </p> */}
                                                                                        </div>
                                                                                        <div className="tc-Semititle">
                                                                                            <p className='mb-0'>
                                                                                                {t('USUARIO')} {item.is_deleted ? 'ELIMINADO' : 'BLOQUEADO'} {item.is_blocked && item.is_deleted ? 'Y BLOQUEADO' : ''}
                                                                                            </p>

                                                                                        </div>
                                                                                        <ul className="verification-requirements p-0 m-0">
                                                                                            <li>
                                                                                                {t('cantidad de denuncias:')} {item.complaints}
                                                                                            </li>
                                                                                            <li>
                                                                                                {t('detalles:')}
                                                                                            </li>

                                                                                            <li>
                                                                                                -
                                                                                            </li>
                                                                                            {item.is_blocked && (
                                                                                                <li>
                                                                                                    {t('El usuario fue bloqueado el día')} {item.last_blocked ? formatDate(item.last_blocked) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                            {item.is_deleted && (
                                                                                                <li>
                                                                                                    {t('El usuario fue eliminado el día')} {item.last_deleted ? formatDate(item.last_deleted) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>

                                                                                        <div className="tc-footer">
                                                                                            <div className="tc-profile" onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                                                                <p>{t('Perfil')}</p>
                                                                                                <img src="./img/userprofile.png" alt="" />
                                                                                            </div>
                                                                                            <div className="tc-conditions">
                                                                                                <div className="todas-img"><p>{t('Rechazar')}</p> <img src="./img/ban.png" onClick={() => { blockUnblock(item.user_id) }} alt="" /></div>
                                                                                                <div className="todas-img right-img">
                                                                                                    <div className="todas-img"><p>{t('Eliminar')}</p> <img src='./img/delete2.png' onClick={() => { eliminateUser(item.user_id) }} alt="" /></div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </>))
                                                                    )
                                                                }


                                                            </Tab>
                                                            <Tab eventKey="bloqueados" title={t('BLOQUEADOS')}>
                                                                {
                                                                    bloeliBl.length < 1 ? (<div>{t('No Member Blocked Yet')}</div>) : (
                                                                        bloeliBl.map((item, index) => (<>
                                                                            <div className="todas-card-main">
                                                                                <div
                                                                                    className={`todas-card-list ${index === activeIndex ? 'active' : ''}`}
                                                                                >
                                                                                    <div className="todas-card todas-three-item mb-0" >

                                                                                        <div className='left-sec' onClick={() => handleToggleClick(index)}>
                                                                                            <div className='profile-img'>
                                                                                                <img src={item.image_url} alt="" />
                                                                                            </div>
                                                                                            <div className='profile-content'>
                                                                                                <h5>{item.first_name} {item.last_name}</h5>
                                                                                                <p>{item.username}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mid-sec" onClick={() => handleToggleClick(index)}>
                                                                                            <span className='todas-img-mid'>
                                                                                                <img src="./img/details.png" alt="" />
                                                                                                {/* {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />} */}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className='right-sec'>

                                                                                            <span className='todas-img right-img'>
                                                                                                {!(index === activeIndex) && (<img src='./img/ban.png' alt='' />)}
                                                                                                {!(index === activeIndex) && (<img src='./img/delete2.png' alt='' />)}
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className={`todas-card-content`} >
                                                                                        <div className="tc-Maintitle">
                                                                                            {/* <p className='mb-0'>
                                                                            Solicitud de verificación de Dirigente Digital
                                                                        </p> */}
                                                                                        </div>
                                                                                        <div className="tc-Semititle">
                                                                                            <p className='mb-0'>
                                                                                                {t('USUARIO')} {item.is_deleted ? t('ELIMINADO') : t('BLOQUEADO')} {item.is_blocked && item.is_deleted ? t('Y BLOQUEADO') : ''}
                                                                                            </p>

                                                                                        </div>
                                                                                        <ul className="verification-requirements p-0 m-0">
                                                                                            <li>
                                                                                                {t('cantidad de denuncias:')} {item.complaints}
                                                                                            </li>
                                                                                            <li>
                                                                                                {t('detalles:')}
                                                                                            </li>

                                                                                            <li>
                                                                                                -
                                                                                            </li>
                                                                                            {item.is_blocked && (
                                                                                                <li>
                                                                                                    {t('El usuario fue bloqueado el día')} {item.last_blocked ? formatDate(item.last_blocked) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                            {item.is_deleted && (
                                                                                                <li>
                                                                                                    {t('El usuario fue eliminado el día')} {item.last_deleted ? formatDate(item.last_deleted) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>

                                                                                        <div className="tc-footer">
                                                                                            <div className="tc-profile" onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                                                                <p>{t('Perfil')}</p>
                                                                                                <img src="./img/userprofile.png" alt="" />
                                                                                            </div>
                                                                                            <div className="tc-conditions">
                                                                                                <div className="todas-img"><p>{t('Rechazar')}</p> <img src="./img/ban.png" onClick={() => { blockUnblock(item.user_id) }} alt="" /></div>
                                                                                                <div className="todas-img right-img">
                                                                                                    <div className="todas-img"><p>{t('Eliminar')}</p> <img src='./img/delete2.png' onClick={() => { eliminateUser(item.user_id) }} alt="" /></div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>))
                                                                    )
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="eliminados" title={t('ELIMINADOS')}>
                                                                {
                                                                    bloeliEl.length < 1 ? (<div>{t('Ningún miembro eliminado todavía')}</div>) : (
                                                                        bloeliEl.map((item, index) => (<>
                                                                            <div className="todas-card-main">
                                                                                <div
                                                                                    className={`todas-card-list ${index === activeIndex ? 'active' : ''}`}
                                                                                >
                                                                                    <div className="todas-card todas-three-item mb-0" >

                                                                                        <div className='left-sec' onClick={() => handleToggleClick(index)}>
                                                                                            <div className='profile-img'>
                                                                                                <img src={item.image_url} alt="" />
                                                                                            </div>
                                                                                            <div className='profile-content'>
                                                                                                <h5>{item.first_name} {item.last_name}</h5>
                                                                                                <p>{item.username}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mid-sec" onClick={() => handleToggleClick(index)}>
                                                                                            <span className='todas-img-mid'>
                                                                                                {/* <img src="./img/details.png" alt=""  style={{ 'opacity': '0.5' }} /> */}
                                                                                                {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />}
                                                                                            </span>
                                                                                        </div>

                                                                                        <div className='right-sec'>

                                                                                            <span className='todas-img right-img'>
                                                                                                {!(index === activeIndex) && (<img src='./img/ban.png' alt='' />)}
                                                                                                {!(index === activeIndex) && (<img src='./img/delete2.png' alt='' />)}
                                                                                            </span>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className={`todas-card-content`} >
                                                                                        <div className="tc-Maintitle">
                                                                                            {/* <p className='mb-0'>
                                                                            Solicitud de verificación de Dirigente Digital
                                                                        </p> */}
                                                                                        </div>
                                                                                        <div className="tc-Semititle">
                                                                                            <p className='mb-0'>
                                                                                                {t('USUARIO')} {item.is_deleted ? 'ELIMINADO' : 'BLOQUEADO'} {item.is_blocked && item.is_deleted ? 'Y BLOQUEADO' : ''}
                                                                                            </p>

                                                                                        </div>
                                                                                        <ul className="verification-requirements p-0 m-0">
                                                                                            <li>
                                                                                                {t('cantidad de denuncias:')} {item.complaints}
                                                                                            </li>
                                                                                            <li>
                                                                                                {t('detalles:')}
                                                                                            </li>

                                                                                            <li>
                                                                                                -
                                                                                            </li>
                                                                                            {item.is_blocked && (
                                                                                                <li>
                                                                                                    {t(' El usuario fue bloqueado el día')} {item.last_blocked ? formatDate(item.last_blocked) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                            {item.is_deleted && (
                                                                                                <li>
                                                                                                    {t('El usuario fue eliminado el día')} {item.last_deleted ? formatDate(item.last_deleted) : 'N/A'}
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>

                                                                                        <div className="tc-footer">
                                                                                            <div className="tc-profile" onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                                                                <p>{t('Perfil')}</p>
                                                                                                <img src="./img/userprofile.png" alt="" />
                                                                                            </div>
                                                                                            <div className="tc-conditions">
                                                                                                <div className="todas-img"><p>{t('Rechazar')}</p> <img src="./img/ban.png" onClick={() => { blockUnblock(item.user_id) }} alt="" /></div>
                                                                                                <div className="todas-img right-img">
                                                                                                    <div className="todas-img"><p>{t('Eliminar')}</p> <img src='./img/delete2.png' onClick={() => { eliminateUser(item.user_id) }} alt="" /></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </>))
                                                                    )
                                                                }

                                                            </Tab>
                                                        </Tabs>
                                                    </>
                                                )
                                            }

                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalSecTwo
