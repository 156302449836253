const THANKFULL = "https://truly-thankful-marten.ngrok-free.app";
const SMASHING = "https://smashing-shrew-peaceful.ngrok-free.app";
const MESSICE = "https://mess-ice-app.uc.r.appspot.com";
const SUNBIRD = "https://sunbird-top-happily.ngrok-free.app";
export const STERLING = "https://sterling-skylark-daily.ngrok-free.app";
const FANCY = `https://fancy-valued-goat.ngrok-free.app`;
const STAGING = "https://stagging-dot-mess-ice-app.uc.r.appspot.com";

const LIVE = "https://development-dot-mess-ice-app.uc.r.appspot.com";

const GlobalURL = STAGING;
export default GlobalURL;
