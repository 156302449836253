import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
// import { FiSearch } from "react-icons/fi";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import "./style.css";
import Swal from "sweetalert2";
// import GlobalURL from "../BaseURL";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

const EventDateEdit = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };
  const navigate = useNavigate();
  const loc = useLocation();

  console.log("loc.state: ", loc.state);

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(loc.state.title);
  const [type, setType] = useState(loc.state.event_type);
  const [desc, setDesc] = useState(loc.state.description);
  const [link, setLink] = useState(loc.state.streaming_link);
  const [imgUrl, setImgUrl] = useState(loc.state.image_url);
  const [date, setDate] = useState(loc.state.event_date);
  const [eId, setEId] = useState(loc.state.event_id);
  const [loading, setLoading] = useState(false);

  const [Data, setData] = useState("");

  // https://sterling-skylark-daily.ngrok-free.app

  async function getEventDetails() {
    const token = localStorage.getItem("token");
    const url = `${GlobalURL}/api/dev/app_moderator/event/getEventDetails`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "ngrok-skip-browser-warning": "69420",
    };
    const data = {
      event_id: loc.state.event_id,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        Swal.fire("Server Error");
      }

      const result = await response.json();
      setData(result.data);
      console.log("Result ", result);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  // Call the function to make the API request

  useEffect(() => {
    getEventDetails();
  }, []);

  // handle deletion of events
  const handleDeletionEvent = () => {
    if (eId) {
      Swal.fire({
        title: t("¿Está seguro?"),
        text: "",
        showDenyButton: true,
        confirmButtonText: t("Borrar"),
        denyButtonText: t(`Cancelar`),
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("delete Event Runned: ");
          setLoading(true);
          fetch(`${GlobalURL}/api/dev/app_moderator/event/deleteEvent`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ event_id: eId }),
          })
            .then((resp) => {
              console.log("Resp: ", resp);
              if (resp.status === 200) {
                setLoading(false);
                Swal.fire(t("Borrado exitosamente!"));
                navigate("/events");
              } else {
                setLoading(false);
                Swal.fire("Error", "Error in deletion!", "error");
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              Swal.fire("Error", "Error in deletion!", "error");
            });
        }
      });
    } else {
      setLoading(false);
      Swal.fire("Delete", "¡Seleccione evento antes de eliminar!", "info");
    }
  };

  function formatDate(inputDate) {
    const eventDate = new Date(inputDate);
    const dayPart = eventDate.getDate();
    return dayPart;
  }

  return (
    <>
      <div className="dashboard eevent-page">
        <div className="dashboard-container">
          <div className="event-date-page">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="event-page-content">
                    <div className="news-header">
                      <div
                        className="back-sec"
                        onClick={() => navigate("/events")}
                        style={{ cursor: "pointer" }}
                      >
                        <img src="./img/volver.png" alt="" />
                      </div>
                      <h3 className="header-title inter-page-header-title">
                        {t("EDITAR EVENTO")}
                      </h3>
                      <div className="search-icon">{/* <FiSearch /> */}</div>
                    </div>
                    <div className="event-calendar-sec">
                     
                          <div className="event-edit-box">
                            <div className="edit-box-date mb-20">
                              <div className="date-box">
                                <span>{formatDate(date)}</span>
                              </div>
                              <div className="day-box">
                                {editMode ? (
                                  <>
                                    <input
                                      value={title}
                                      onChange={(e) => {
                                        setTitle(e.target.value);
                                      }}
                                    />
                                  </>
                                ) : (
                                  <span>{title}</span>
                                )}
                              </div>
                            </div>
                            <div className="event-name-box justify-content-between mb-20">
                              <div className="event-name">
                                {editMode ? (
                                  <select>
                                    <option></option>
                                  </select>
                                ) : (
                                  <span>{type}</span>
                                )}
                              </div>
                              <div className="event-time">
                                <span></span>
                              </div>
                            </div>
                            <div className="event-box-img mb-20">
                              <img
                                src={imgUrl || "./img/blankimg.png"}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="event-box-content mb-20">
                              <p className="mb-0">
                                {editMode ? (
                                  <input
                                    style={{ width: "340px", height: "200px" }}
                                    value={desc}
                                    onChange={(e) => {
                                      setDesc(e.target.value);
                                    }}
                                  />
                                ) : (
                                  <div dangerouslySetInnerHTML={{ __html: desc }} />
                                )}
                              </p>
                            </div>
                            <div className="event-box-btn text-center">
                              <div className="event-link-box">
                                {editMode ? (
                                  <input value={link} />
                                ) : (
                                  <span>{link}</span>
                                )}
                              </div>
                            </div>
                          </div>
                        
                    </div>
                    <div className="event-btns">
                      <button
                        className="red-btn"
                        onClick={(e) => handleDeletionEvent()}
                        disabled={loading}
                      >
                        {loading ? (
                          <div
                            className=""
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div className="loading-adjust">
                              <div className="Loader d-flex justify-content-center align-items-center">
                                <div
                                  className="spinner-border spinner-border-sm my-5"
                                  role="status"
                                >
                                  <span className="sr-only"></span>
                                </div>
                                <div className="my-5">
                                  {" "}
                                  &nbsp; {t("Eliminando")}...
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          t("Eliminar")
                        )}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventDateEdit;
