import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import "./style.css";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";

const ExperiencePage = () => {
  const [selectedOption, setSelectedOption] = useState("Cuadrícula");

  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();

  const [exp, setExp] = useState([]);
  const [loading, setLoading] = useState(false);

  const [originalData, setOriginalData] = useState([]);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    const formattedDate = new Intl.DateTimeFormat("en-GB", options).format(
      date
    );

    // Extract day, month, and year components
    const [day, month, year] = formattedDate.split("/");

    // Format as DD/MM/YY
    const formattedDateDDMMYY = `${day}/${month}/${year.slice(-2)}`;

    return formattedDateDDMMYY;
  }

  useEffect(() => {
    fetchExperiences();
  }, []);

  const fetchExperiences = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/miTeam/appModerator/getAllExperiences`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (!response.ok) {
        Swal.fire(t("Error del Servidor"));
      }
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      const data = await response.json();
      console.log(data.data);
      setExp(data.data);
      setOriginalData(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSearch = (e) => {
    const dataTemp = originalData;
    console.log("originalData: ", originalData);
    setSearchTerm(e.target.value);
    console.log("Search Term: ", e.target.value);
    if (e.target.value === "") {
      setExp(originalData);
      return;
    }
    const result = dataTemp.filter((item) =>
      item.title.toLowerCase().includes(e.target.value)
    );
    console.log("result: ", result);
    if (result.length === 0) {
      console.log("Empty Arry executed:  ");
      return;
    }
    setExp(result);
  };

  return (
    <>
      <div className="dashboard bb-page">
        <div className="dashboard-container">
          <div className="experience-page">
            <Container fluid>
              <Row>
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="news-header">
                    <div className="back-sec">
                      <img
                        src="./img/volver.png"
                        alt=""
                        onClick={() => {
                          navigate("/homepage");
                        }}
                      />
                    </div>
                    <h3 className="main-title">{t("Vivencias Mess Ice")}</h3>
                    <div className="search-icon" style={{ display: "flex" }}>
                      {searchActive && (
                        <div className="searchBarInput">
                          <input
                            className="search-tag-bar"
                            placeholder={t("Buscar")}
                            value={searchTerm}
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />
                        </div>
                      )}
                      <FiSearch
                        className="search-icon-click"
                        onClick={() => {
                          setSearchActive(!searchActive);
                        }}
                      />
                    </div>
                  </div>

                  <div className="grid-list-sec">
                    <div className="radio-btns-sec" style={{ width: "24%" }}>
                      <div
                        className={`radio-sec ${
                          selectedOption === "Lista" ? "selected" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          value={t("Cuadrícula")}
                          checked={selectedOption === "Cuadrícula"}
                          onChange={handleRadioChange}
                        />
                        <label htmlFor="test1">{t("Cuadrícula")}</label>
                      </div>

                      <div className="left-radios-sec">
                        <div
                          className={`radio-sec ${
                            selectedOption === "Lista" ? "selected" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id="test2"
                            name="radio-group"
                            value={t("Lista")}
                            checked={selectedOption === "Lista"}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="test2">{t("Lista")}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="experience-sec21 common-dash-padding"
                    style={{ height: "800px" }}
                  >
                    {selectedOption === "Cuadrícula" && (
                      <div className="experience-card-sec">
                        <div className="billboard-sec-inner2">
                          <Row>
                            {loading ? (
                              <div
                                className=""
                                style={{
                                  height: "500px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="loading-adjust">
                                  <div className="Loader d-flex justify-content-center align-items-center">
                                    <div
                                      className="spinner-border spinner-border-sm my-5"
                                      role="status"
                                    >
                                      <span className="sr-only"></span>
                                    </div>
                                    <div className="my-5">
                                      {" "}
                                      &nbsp; {t("Cargando...")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              exp.map((item, index) => (
                                <Col md="6" xl="4" key={index}>
                                  <div
                                    className="exp-card"
                                    onClick={() => {
                                      navigate("/editexperience", {
                                        state: item,
                                      });
                                    }}
                                  >
                                    <div className="card-heading">
                                      <h3>{item.title}</h3>
                                    </div>
                                    <div className="card-img">
                                      {item.experience_media[0]?.url.endsWith(
                                        ".mp4"
                                      ) ? (
                                        <video className="video-grid-view3">
                                          <source
                                            src={item.experience_media[0]?.url}
                                            type="video/mp4"
                                          />{" "}
                                          {t(
                                            "Su navegador no soporta la etiqueta de vídeo."
                                          )}
                                        </video>
                                      ) : (
                                        <img
                                          className="imgage-exp"
                                          src={item.experience_media[0]?.url}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              ))
                            )}
                          </Row>
                        </div>
                      </div>
                    )}

                    {selectedOption === "Lista" && (
                      <div className="bboardSec" style={{ height: "100%" }}>
                        <div
                          className="lista-content"
                          style={{ height: "100%" }}
                        >
                          {loading ? (
                            <div
                              className=""
                              style={{
                                height: "500px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="loading-adjust">
                                <div className="Loader d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm my-5"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                  <div className="my-5">
                                    {" "}
                                    &nbsp; {t("Cargando...")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="billboard-sec-inner2">
                              <div className="lista-titles">
                                <div className="lista-name">{t("Nombre")}</div>
                                <div className="lista-creation">
                                  {t("Fecha de Creación")}
                                </div>
                                <div className="lista-modification">
                                  {t("Fecha de Modificación")}
                                </div>
                                {/* <div className='lista-right'> {deleteOption ? 'Eliminar' : ''} </div> */}
                              </div>
                              {exp.map((item, i) => (
                                <div
                                  className="myTeamBanner billboard-cards"
                                  onClick={() => {
                                    navigate("/editexperience", {
                                      state: item,
                                    });
                                  }}
                                >
                                  <div className="myTeamBanner billboard-cards">
                                    <div className="lista-name ps-0">
                                      <div className="card-img">
                                        {/* <img src={item.experience_media[0]?.url} alt="" /> */}
                                        {item.experience_media[0]?.url.endsWith(
                                          ".mp4"
                                        ) ? (
                                          // Render video element if it's a video URL
                                          <video>
                                            <source
                                              src={
                                                item.experience_media[0]?.url
                                              }
                                              type="video/mp4"
                                            />
                                            Your browser does not support the
                                            video tag.
                                          </video>
                                        ) : (
                                          // Otherwise, render image element
                                          <img
                                            src={item.experience_media[0]?.url}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div className="card-heading">
                                        <h3>{item.title}</h3>
                                      </div>
                                    </div>

                                    <div className="lista-creation">
                                      <div className="card-heading">
                                        <p>{formatDate(item.createdAt)}</p>
                                      </div>
                                    </div>
                                    <div className="lista-modification">
                                      <div className="card-heading">
                                        <p>{formatDate(item.createdAt)}</p>
                                      </div>
                                    </div>
                                    {/* <div className='lista-right'>
                                                                        {
                                                                            deleteOption && (
                                                                                <RiDeleteBin6Line style={{
                                                                                    color: '#ff6868',
                                                                                    marginLeft: '30px',
                                                                                    background: '#131313c4',
                                                                                    cursor: 'pointer',
                                                                                    padding: '7px',
                                                                                    borderRadius: '30px',
                                                                                    width: '35px',
                                                                                    height: '35px',
                                                                                }}
                                                                                    onClick={() => { handleDelete(banner[0].banner_id) }}
                                                                                />)
                                                                        }
                                                                    </div> */}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="exp-btn-sec">
                    <button
                      className="green-btn"
                      onClick={() => {
                        navigate("/Addexperience");
                      }}
                    >
                      {t("Agregar Vivencia")}
                    </button>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExperiencePage;
