import React, { useState, useEffect } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { FiSearch } from "react-icons/fi";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from 'sweetalert2';

import { useNavigate } from 'react-router-dom';
import './style.css'
import GlobalURL from '../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';



const MultimediaImages = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();
    const [images, setImages] = useState();
    const [videos, setVideos] = useState();

    const [choosenType, setChoosenType] = useState('');
    const [choosen, setChoosen] = useState('');

    const [deleting, setDeleting] = useState(true);

    const handleClose = () => {
        setShow(false)
    }

    async function deleteMultimedia(mid) {
        console.log(mid);

        Swal.fire({
            title: t("Estas seguro"),
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Sí")
        }).then(async (result) => {
            if (result.isConfirmed) {
                setDeleting(true);
                const url = `${GlobalURL}/api/dev/miteam/multimedia/deleteMultimedia`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Cookie': 'GCLB=CKbYobSRvaH9IA',
                    "ngrok-skip-browser-warning": "69420",
                };

                const data = {
                    multimedia_id: mid
                }

                try {
                    const response = await fetch(url, {
                        method: 'POST',
                        headers: headers,
                        mode: "cors",
                        body: JSON.stringify(data)
                    });

                    if (!response.ok) {
                        setDeleting(false);
                        Swal.fire({
                            title: t("Error del Servidor"),
                            text: "",
                            icon: "Alert"
                        });
                        functionI();
                        functionV();
                        Swal.fire(t('Error del Servidor'));
                    }

                    const result = await response.json();
                    if (response.ok === 200) {
                        Swal.fire({
                            title: t("¡Eliminado!"),
                            text: "",
                            icon: "success"
                        });
                        functionI();
                        functionV();
                    }
                    console.log('Delete Multimedia Result:', result);
                    functionI();
                    functionV();
                } catch (error) {
                    console.error('Error deleting multimedia:', error.message);
                }
            }
        });


    }

    const handleClickedMedia = (mediaURL, mediaType) => {
        setShow(true);
        setChoosen(mediaURL);
        setChoosenType(mediaType);
    }
    const token = localStorage.getItem('token');
    const [show, setShow] = useState(false);

    const functionI = () => {
        console.log(".ENV: ", process.env.REACT_APP_BASE_URL);
        fetch(`${GlobalURL}/api/dev/miteam/appModerator/getAllImages`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "ngrok-skip-browser-warning": "69420",
                "team_id": 4
            },
        })
            .then((response) => {
                if (response.code === 401) {
                    Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                    navigate('/login');
                }
                if (response.status === 404) {
                    console.log("status: ", response)
                }
                else if (response.status === 200) {
                    console.log(response);
                    setDeleting(false);
                    return response.json();
                }
            }).then(res => {
                console.log(res.data);
                setImages(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);

            });
    }
    const functionV = () => {
        console.log(".ENV: ", process.env.REACT_APP_BASE_URL);
        fetch(`${GlobalURL}/api/dev/miteam/appModerator/getAllVideos`, {
            method: "get",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "ngrok-skip-browser-warning": "69420",
                "team_id": 4
            },
        })
            .then((response) => {
                if (response.code === 401) {
                    Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                    navigate('/login');
                }
                if (response.status === 404) {
                    console.log("status: ", response)
                }
                else if (response.status === 200) {
                    console.log(response);
                    setDeleting(false);
                    return response.json();
                }
            }).then(res => {
                setVideos(res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);

            });
    }
    // useEffect for images
    useEffect(() => {
        functionI();
    }, [])

    // useEffect for video
    useEffect(() => {
        functionV();
    }, [])

    return (
        <>
            <div className='dashboard hl-page'>
                <div className='dashboard-container'>
                    <div className='legend-page'>
                        <Container fluid>
                            <Row >
                                <Col className="first-sec" lg="3" md="12"></Col>
                                <Col className="second-sec" lg="6" md="12">
                                    <div className='legend-sec'>
                                        <div className='news-header-2'>
                                            <div className='back-sec' onClick={() => { navigate('/homepage') }} style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='main-title'>{t('MULTIMEDIA')}</h3>
                                            <div className='search-icon'>
                                                <FiSearch style={{ opacity: '0' }} />
                                            </div>
                                        </div>
                                        <div className='legend-tabs'>
                                            {images ? (<Tabs
                                                defaultActiveKey="Imágenes"
                                                id="fill-tab-example"
                                                fill
                                            >
                                                <Tab eventKey="Imágenes" title={t("Imágenes")} style={{ marginTop: '-65px' }} >
                                                    <div className='historical-legends-2'>
                                                        {
                                                            images && images.map(image => {
                                                                return (
                                                                    <div className='legend-card' key={image.multimedia_id}>

                                                                        {
                                                                            <><img className='legend-img' src={image.multimedia_url} data-src="./img/leg2.png"
                                                                                alt={image.multimedia_id} onClick={() => { handleClickedMedia(image.multimedia_url, image.multimedia_type) }} />
                                                                                <RiDeleteBin6Line style={{
                                                                                    position: 'absolute',
                                                                                    top: '6px',
                                                                                    left: '3px',
                                                                                    color: '#ff6868',
                                                                                    background: '#131313c4',
                                                                                    cursor: 'pointer',
                                                                                    padding: '7px',
                                                                                    borderRadius: '30px',
                                                                                    width: '35px',
                                                                                    height: '35px',
                                                                                }}
                                                                                    onClick={() => { deleteMultimedia(image.multimedia_id) }}
                                                                                />
                                                                            </>

                                                                        }

                                                                        {/* <img src='./img/delete.png' alt='delete' className='deletebutton-videosimages'/> */}
                                                                    </div>

                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="Videos" title={t("Videos")} style={{ marginTop: '-65px' }}>
                                                    <div className='historical-legends-2'>
                                                        {
                                                            videos && videos.map(video => {
                                                                return (
                                                                    <div className='legend-card' key={video.multimedia_id}>
                                                                        <video className='legend-img' poster={video.thumbnail_url} onClick={() => { handleClickedMedia(video.multimedia_url, video.multimedia_type) }}
                                                                            src={video.multimedia_url} alt={video.multimedia_id} />
                                                                        <RiDeleteBin6Line style={{
                                                                            position: 'absolute',
                                                                            top: '6px',
                                                                            left: '3px',
                                                                            color: '#ff6868',
                                                                            background: '#131313c4',
                                                                            cursor: 'pointer',
                                                                            padding: '7px',
                                                                            borderRadius: '30px',
                                                                            width: '35px',
                                                                            height: '35px',
                                                                        }}
                                                                            onClick={() => { deleteMultimedia(video.multimedia_id) }}

                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>

                                                </Tab>
                                            </Tabs>) : (
                                                <div className="" style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div className="loading-adjust">
                                                        <div className="Loader d-flex justify-content-center align-items-center">
                                                            <div className="spinner-border spinner-border-sm my-5" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            <div className="my-5"> &nbsp; {t('Cargando...')}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className='center-btn-sec'>
                                                <button className='green-btn' onClick={() => { navigate('/addmedia') }}>{t('Agregar MULTIMEDIA')}</button>
                                            </div>
                                        </div>
                                    </div>


                                    <Modal show={show} className="proposal-popup common-popup" onHide={handleClose} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title className='modal-title-pv'></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="img-show-position">

                                                {choosenType === 'video' && (
                                                    <video controls alt='multimedia' src={choosen} />
                                                )}

                                                {choosenType === 'image' && (
                                                    <img alt='multimedia' src={choosen} />
                                                )}

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className='border-0 p-0'>
                                            <div className='news-btn-sec '></div>
                                        </Modal.Footer>
                                    </Modal>


                                </Col>
                                <Col className="third-sec" lg="3" md="12"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultimediaImages
