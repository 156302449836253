
import React from 'react'
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Swal from 'sweetalert2';
import GlobalURL from '../BaseUrl';

const AdminPage = (props) => {

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjNhM2JkODk4ZGE1MGE4OWViOWUxY2YwYjdhN2VmZTM1OTNkNDEwNjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbWVzcy1pY2UtYXBwIiwiYXVkIjoibWVzcy1pY2UtYXBwIiwiYXV0aF90aW1lIjoxNzAyMzAzNTAzLCJ1c2VyX2lkIjoiWmtSSEx5eG9wYlUzZ3hrTmFZUG4yUlk4ODlCMyIsInN1YiI6IlprUkhMeXhvcGJVM2d4a05hWVBuMlJZODg5QjMiLCJpYXQiOjE3MDIzMDM1MDMsImV4cCI6MTcwMjMwNzEwMywiZW1haWwiOiJrYXBpbC5yYUBjaXNpbmxhYnMuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsia2FwaWwucmFAY2lzaW5sYWJzLmNvbSJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.A2SVakXFrAECACcHmUmrtF_K6OZ3_oN7vtmuQpZbnk5b0qqEgLHOL-r_SJw7VV0nBxt0UzLcTVSSbW3LVP1O6qa9k8qrGHu_Yae7VtEQivkbTo5KGdUZrrAOt5IwcSyCf0oREiA9wFxARkl-QXOwoNAaFx-l8KGhedxzoWZNsXtL7gSHdj642I5MmzzFws1JMCO0ROOf-faMZ3ZdaPnJ5OTE6m2XksqWLfpG09WL1eGc1daf2wPnwws-rrKH06E-xfuV6LSu4Yb95eEZnKxvzaLyUHojCaC0c_24xuntp4lk597Qz-brdxYLixhfbSTqtYbvFFhhgqAdAZn20vIQ8A';

    const handleApiCall = async () => {
        try {
            const response = await fetch(`${GlobalURL}/api/dev/app_moderator/contactAdmin`, {
                method: 'POST',
                body: JSON.stringify({
                    title: title,
                    message: message
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`
                }
            });

            if (!response.ok) {
                return
            }
            if (response.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                navigate('/login');
            }


            const data = await response.json();

            // Handle the data from the API as needed
            console.log('API Response:', data);
        } catch (error) {
            // Handle errors from the API call
            console.error('Error:', error.message);
        }
    };

    const navigate = useNavigate();

    return (
        <>
            <div className='admin-page-sec vh-100'>
                <div className='login-wrapper'>
                    <div className='admin-container'>
                        <div className='admin-logo'>
                            <img src="./img/LogoMess.png" alt="admin_logo" />
                            
                        </div>

                        <div className='admin-nav-sec' style={{ cursor: "pointer" }} onClick={() => { navigate('/login') }}>
                            <img src="./img/volver.png" alt="" />
                            <div className="contact-admin-heading">CONTACTO ADMIN</div>
                            <img src="./img/volver.png" alt="" style={{opacity:0}} />
                        </div>
                        <div className='admin-form'>
                            <form action="">
                                <div className='admin-form-control'>
                                    <input className='admin-input' placeholder='asunto' type="text" onChange={(e) => { setTitle(e.target.value) }} />
                                </div>
                                <div className='admin-form-control'>
                                    <input className='admin-input' placeholder='mensaje' type="text" onChange={(e) => { setMessage(e.target.value) }} />
                                </div>
                                <div className='admin-form-control'>
                                    <textarea className='admin-textarea' disabled={true}></textarea>
                                </div>
                                <div className='admin-form-btn'>
                                    <button onClick={() => { handleApiCall() }}>Enviar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminPage
