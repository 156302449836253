import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { FiSearch } from "react-icons/fi";
import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { socket } from '../../../socket';
// import GlobalURL from '../BaseURL';
// import Swal from 'sweetalert2';
import { FiPlus } from "react-icons/fi";
import Swal from 'sweetalert2';
import { useRef } from 'react';
// import GlobalURL from '../BaseURL';
import "./style.css";
import GlobalURL from '../../BaseUrl';
import { FiTriangle } from "react-icons/fi";


const DebateChatRoom = () => {
    const navigate = useNavigate();
    const stateData = useLocation();
    const [messageTyped, setMessageTyped] = useState();
    const [groupChat, setGroupChat] = useState();
    const [participantsList, setParticipantsList] = useState();
    const [media, setMedia] = useState(null);
    const formData = new FormData();
    const [mediaURL, setMediaURL] = useState();
    const [addMember, setAddMember] = useState();
    const [selectedMember, setSelectedMember] = useState();
    const [digitalManager, setDigitalManager] = useState(null);
    const [searchWord, setSearchWord] = useState();
    const [searchMemberList, setSearchMemberList] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [activeTabRequired, setActiveTabRequired] = useState(false);
    const [mediaType, setMediaType] = useState('MEDIA');
    const [allMedia, setAllMedia] = useState();
    const [isWait, setIsWait] = useState();
    const [flag, setFlag] = useState(false);

    // const [preview, setPreview] = useState();

    // useEffect(() => {
    //     socket.on('errorHandler', (data) => {
    //         if (data.includes("unauthenticated")) {
    //             socket.emit('connect_user', { user_id: localStorage.getItem("user_id") });
    //             console.log("reconnected: ", data)
    //             setFlag(() => true);
    //             getMsg();
    //             // setInboxUid(stateData.state.item.inbox_uid)
    //         }
    //     })
    // }, [flag])

    // const getMsg = () => {
    //     const messageHistory = (data) => {
    //         console.log("message history:- ", data);
    //         setGroupChat(data.messages);
    //     }
    //     if (flag) {
    //         console.log("flag exe....")
    //         socket.emit('get_message_history_in_board_meeting', { inbox_uid: stateData.state.item.inbox_uid });
    //         socket.on('message_history_in_board_meeting', messageHistory);
    //     }
    //     setFlag(false)
    // }

    // const socket = stateData.socket;    
    console.log("loc obj: ", stateData);
    const inputRef = useRef(null);
    const lastMessageRef = useRef(null);

    const handleMediaSelection = (e) => {
        setIsWait(true);
        setMedia(e.target.files[0])
        console.log('e.target.files[0]: ', e.target.files[0]);
        if (e.target.files[0]) {
            formData.append("media", e.target.files[0]);
            formData.append("inbox_uid", stateData.state.item.inbox_uid)

            fetch(`${GlobalURL}/api/dev/debate/chat/sendmultimedia`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("access-token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData
            }).then(function (res) {
                if (res.ok) {
                    return res.json()
                } else {
                    Swal.fire("Error, in send multimedia api of board meeting chat.");
                }
            }).then((resp) => {
                setIsWait(false)
                console.log("media bmc url: ", resp.data);
                setMediaURL(resp.data);
            }).catch(function (e) {
                console.log(e)
                setIsWait(false);
                Swal.fire("Error, In send multimedia api of board meeting chat.");
            });
        } else {
            console.log("select media");
        }
    }
    const getMediaPreview = () => {
        let mType = media.type;
        console.log('mType: ', mType);
        if (mType.includes('image')) {
            return <img src={mediaURL} alt={media.name}></img>
        }
        else if (mType.includes('video')) {
            return <video src={mediaURL} width='180px' />
        }
    }
    function handleclickinput(e) {
        inputRef.current.click();
    }
    console.log('stateData: ', stateData);
    // handle socket connection
    // useEffect(() => {
    //     socket.connect();
    //     socket.emit('connect_user', { user_id: localStorage.getItem("user_id"), role_id: 3 });

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, [])
    useEffect(() => {
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [groupChat])

    useEffect(() => {
        let inbox_uid = stateData.state.item.inbox_uid;
        console.log("group id in debate chat room: ", inbox_uid);
        const messageHistory = (data) => {
            console.log("message history:- ", data);
            setGroupChat(data.messages);
        }
        const handleParticipants = (data) => {
            console.log("participants: ", data.participants);
            setParticipantsList(data.participants);
        }

        socket.on('boardMeetingMessage', (data) => {
            console.log("listen continuosly: ", data);
            if (data)
                setGroupChat((prev) => [...prev, data.latestmessage]);
            // setMessageTyped("");
        });

        if (!inbox_uid) {
            navigate(-1);
            Swal.fire("Info", "Please select group meeting first!", "warning");
            return;
        }
        let event_data = { inbox_uid };
        if (stateData.first) {
            socket.emit('get_message_history_in_board_meeting', event_data);
            socket.on('message_history_in_board_meeting', messageHistory);
        } else {
            socket.emit('get_message_history_in_small_table', event_data);
            socket.on('message_history_in_small_table', messageHistory);

        }

        socket.emit('get_group_info', event_data);
        socket.on('group_info', handleParticipants);

        return () => {
            socket.off('message_history_in_board_meeting', messageHistory);
            socket.off('group_info', handleParticipants);
        }
        // eslint-disable-next-line 
    }, [socket])
    // handle chats msg
    const renderChat = () => {
        // console.log("history:-- ", groupChat);
        if (groupChat) {
            if (groupChat.length) {
                return groupChat.map((msg, i) => {
                    //check message_chat type (img,video,text)
                    const checkMsgType = () => {
                        // console.log('msg.message_type: ', msg.message_type);
                        if (msg.message_type === 'TEXT') {
                            return <p>{msg?.message}</p>
                        }
                        else if (msg.message_type === 'IMAGE') {
                            return <img className='chat_img' src={msg.multimedia_url} />
                        }
                        else if (msg.message_type === 'VIDEO') {
                            return <video className='chat_img' src={msg.multimedia_url} controls />
                        }
                    }
                    // console.log('msg.user_id: ', typeof msg.user_id);

                    //print msg.
                    if (msg.user_id === 108) {
                        return (
                            <div className="msg right-msg" key={msg.id}
                                ref={((i + 1) == groupChat.length) ? lastMessageRef : null}
                            >
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className="msg-text">

                                            {checkMsgType()}
                                        </div>
                                        {/* <div className="msg-text">
                                            <p>{msg?.message}</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="msg-img">
                                    <img src={msg.user.image_url ? msg.user.image_url : `https://picsum.photos/100/100`} alt='' />
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="msg left-msg" key={msg.id}
                                ref={((i + 1) == groupChat.length) ? lastMessageRef : null}
                            >
                                <div className="msg-img">
                                    <img src={msg.user.image_url ? msg.user.image_url : `https://picsum.photos/100/100`} alt='' />
                                </div>
                                <div className="msg-item">
                                    <div className="msg-bubble">
                                        <div className="msg-text">
                                            <div className="msg-user-name pb-2">
                                                {msg.user.first_name} {msg.user.last_name}
                                            </div>
                                            {checkMsgType()}
                                            {/* <p>{msg?.message}</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            } else {
                return (
                    <div className="Loader d-flex justify-content-center">
                        <div className="my-5" role="status">
                            {/* <span className="sr-only"></span> */}
                        </div>
                        <div className=" my-5 my-auto" >
                            start conversation. . .
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="" style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="loading-adjust">
                        <div className="Loader d-flex justify-content-center align-items-center">
                            <div className="spinner-border spinner-border-sm my-5" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="my-5"> &nbsp; Eliminando...</div>
                        </div>
                    </div>
                </div>
            )
        }
    }
    //handle paricipants
    const renderParticipants = (updatedMemberList) => {
        // console.log("updateee::: ", participantsList);
        if (updatedMemberList) {
            if (updatedMemberList.length) {
                return updatedMemberList.map((member) => {
                    //print msg.
                    if (!member.is_admin) {
                        return (
                            <li className='' key={member.user_id}>
                                <div className='partcp-profile'>
                                    <div className='partcp-img'>
                                        {/* <img src="./img/cmt-img.png" alt="" /> */}
                                        <img src={member.user.image_url ? member.user.image_url : `https://picsum.photos/100/100`} alt="" />
                                        <p className='partcp-name'>{member.user.first_name} {member.user.last_name}</p>
                                    </div>


                                    <div className='delete-img'>
                                        <img src="./img/delete.png" alt="" onClick={() => handleRemoveMember(member.user.user_id)} />
                                    </div>
                                </div>
                            </li>
                        )
                    }
                    return null;
                })
            }
            // else {
            //     return (
            //         <div className="Loader d-flex justify-content-center">
            //             <div className="my-5" role="status">
            //                 {/* <span className="sr-only"></span> */}
            //             </div>
            //             <div className=" my-5 my-auto" >
            //                 start conversation. . .
            //             </div>
            //         </div>
            //     )
            // }
        } else {
            return (
                <div className="Loader d-flex justify-content-center">
                    <div className="spinner-border my-5" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <div className=" my-5 my-auto" > &nbsp;
                        Loading...
                    </div>
                </div>
            )
        }
    }
    const handleRemoveMember = (member_id) => {
        console.log("remove icon clicked");
        if (stateData.first) {
            Swal.fire({
                title: "Are you sure?",
                text: 'Do you want to remove participents!',
                showDenyButton: true,
                confirmButtonText: "Remove",
                denyButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'boardmeeting',
                        participant_id: member_id
                    }
                    socket.emit("remove_participant", eventParameters);
                    socket.on("group_logging", () => {
                        Swal.fire("Removed!", "", "success");
                    })
                }
            });
        }
        else {
            Swal.fire({
                title: "Are you sure?",
                text: 'Do you want to remove participents!',
                showDenyButton: true,
                confirmButtonText: "Remove",
                denyButtonText: `Cancel`
            }).then((result) => {
                if (result.isConfirmed) {
                    let eventParameters = {
                        inbox_uid: stateData.state.item.inbox_uid,
                        group_type: 'smalltable',
                        participant_id: member_id
                    }
                    socket.emit("remove_participant", eventParameters);
                    socket.on("group_logging", () => {
                        Swal.fire("Removed!", "", "success");
                    })
                }
            });
        }
    }
    //handle send message
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (stateData.first) {
            const messageHistory = (data) => {
                console.log("message history:- ", data);
                setMessageTyped("");
                setGroupChat(data.messages);
            }

            let inbox_uid = stateData.state.item.inbox_uid;
            let msgParameter = {
                inbox_uid: inbox_uid,
                message: messageTyped,
                message_type: "TEXT"
            }
            if (messageTyped?.trim()?.length) {
                socket.emit('send_message_in_board_meeting', msgParameter);
                socket.on('message_history_in_board_meeting', messageHistory);
            } else if (mediaURL) {
                if (media.type.includes('image')) {
                    msgParameter.message_type = 'IMAGE';
                }
                else if (media.type.includes('video')) {
                    msgParameter.message_type = 'VIDEO';
                }
                msgParameter.media_url = mediaURL;
                socket.emit('send_message_in_board_meeting', msgParameter);
                socket.on('message_history_in_board_meeting', messageHistory);
                setMedia(null);
                setMediaURL(null);
            }

        } else {
            //send_message_in_small_table
            const messageHistory = (data) => {
                console.log("message history:- ", data);
                setMessageTyped("");
                setGroupChat(data.messages);
            }

            let inbox_uid = stateData.state.item.inbox_uid;
            let msgParameter = {
                inbox_uid: inbox_uid,
                message: messageTyped,
                message_type: "TEXT"
            }
            if (messageTyped?.trim()?.length) {
                socket.emit('send_message_in_small_table', msgParameter);
                socket.on('get_message_history_in_small_table', messageHistory);
            } else if (mediaURL) {
                if (media.type.includes('image')) {
                    msgParameter.message_type = 'IMAGE';
                }
                else if (media.type.includes('video')) {
                    msgParameter.message_type = 'VIDEO';
                }
                msgParameter.media_url = mediaURL;
                socket.emit('send_message_in_small_table', msgParameter);
                socket.on('get_message_history_in_small_table', messageHistory);
                setMedia(null);
                setMediaURL(null);
            }
        }



    }
    const handleAddParticipantes = () => {
        console.log("handle add paricepents called...");
        fetch(`${GlobalURL}/api/dev/app_moderator/getAllUserByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("team_id") || 108 })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                    // Swal.fire("error", "Not able to get particepants list", "error");
                }
            })
            .then(res => {
                setErrorMsg(true)
                setDigitalManager(res.data);
                console.log('Digital managers:', res);
            })
            .catch((err) => {
                console.log("error occured: in Digital managers ", err);
            });
        // if (digitalManager) {
        console.log("get digital manager: ", digitalManager);
        const memberList = document.querySelector(".add-users-list");
        memberList.classList.add("add-member-active");
        // }
    }
    const handleSelectMember = (e, user_data) => {
        if (selectedMember) {
            selectedMember.classList.remove("highlight");
        }
        setSelectedMember(e.target.closest(".legendlist"));
        e.target.closest(".legendlist").classList.add("highlight");
        setAddMember(user_data.user);
    }
    const handleAddParticepant = () => {
        let isPresent = false;
        if (selectedMember) {
            participantsList.forEach((item) => {
                console.log("inside mem list")
                if (item.user.user_id === addMember.user_id) {
                    Swal.fire("Already Present", "", "info");
                    isPresent = true;
                }
            })
            if (!isPresent) {
                let eventParameter = {
                    inbox_uid: stateData.state.item.inbox_uid,
                    group_type: "boardmeeting",
                    member_ids: [addMember.user_id]
                }
                socket.emit("add_participants", eventParameter);
                Swal.fire('Adding member...',);
                socket.on('group_logging', () => {
                    Swal.fire("Member Add!", "", "success");
                });
            }
        } else {
            Swal.fire("error", "Please select member!", "error");
        }
    }
    const handleCloseList = () => {
        const memberList = document.querySelector(".add-users-list");
        memberList.classList.remove("add-member-active");
    }
    const handleSearch = (e) => {
        setSearchWord(e.target.value);
        let newMemberList = participantsList.filter((item) => {
            return item.user.first_name.includes(e.target.value);
        })
        setSearchMemberList(newMemberList);
    }
    const getMediaForGroupChat = (media_type) => {
        fetch(`${GlobalURL}/api/dev/debate/chat/getinboxmedia`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({
                inbox_uid: stateData.state.item.inbox_uid,
                media_type: media_type
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true);
                }
            })
            .then(res => {
                setErrorMsg(true)
                setAllMedia(res.data);
                console.log('getMediaForGroupChat: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
    }
    useEffect(() => {
        if (activeTabRequired) {
            try {
                if (mediaType)
                    getMediaForGroupChat(mediaType);
                console.log("media type :- ", mediaType);
            }
            catch (error) {
                console.log("error occured: ", error);
            }
        }
    }, [activeTabRequired, mediaType])

    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='legend-page debate-page'>
                        <Container fluid>
                            <Row >
                                <Col xs lg="3">
                                    {activeTabRequired
                                        && (
                                            <div className='participatns-sec group-user-list'>
                                                <div className='msger-header my-auto'>
                                                    <p className='my-auto'>Participantes</p>
                                                    {/* <div
                                                        className="add-member-icon"
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FiPlus
                                                            onClick={handleAddParticipantes}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </div> */}
                                                </div>
                                                <main className='msger partcpt-section'>
                                                    <div className='member-searchBox'>
                                                        <input type='text'
                                                            name='serachWord'
                                                            placeholder='search...'
                                                            onChange={handleSearch}
                                                            value={searchWord}
                                                        />
                                                    </div>
                                                    <ul className='participants-list'>
                                                        {searchWord?.length
                                                            ? (
                                                                <>
                                                                    {searchMemberList?.map((admin, i) => {
                                                                        if (admin.is_admin) {
                                                                            return (
                                                                                <li className='' key={i}>
                                                                                    <div className='partcp-profile'>
                                                                                        <div className='partcp-img'>
                                                                                            <img
                                                                                                src={admin.user.image_url
                                                                                                    ? admin.user.image_url
                                                                                                    : `https://picsum.photos/100/100`}
                                                                                                alt="" />
                                                                                            <p className='partcp-name'>
                                                                                                {admin.user.first_name}
                                                                                                {admin.user.last_name}
                                                                                            </p>
                                                                                        </div>

                                                                                        <span className='partcp-admin'>Admin</span>
                                                                                        {/* <div className='delete-img'>
                                                                                            <img src="./img/delete.png" alt="" />
                                                                                        </div> */}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                        return null;
                                                                    })}
                                                                    {renderParticipants(searchMemberList)}
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    {participantsList?.map((admin, i) => {
                                                                        if (admin.is_admin) {
                                                                            return (
                                                                                <li className='' key={i}>
                                                                                    <div className='partcp-profile'>
                                                                                        <div className='partcp-img'>
                                                                                            <img
                                                                                                src={admin.user.image_url
                                                                                                    ? admin.user.image_url
                                                                                                    : `https://picsum.photos/100/100`}
                                                                                                alt="" />
                                                                                            <p className='partcp-name'>
                                                                                                {admin.user.first_name}
                                                                                                {admin.user.last_name}
                                                                                            </p>
                                                                                        </div>

                                                                                        <span className='partcp-admin'>Admin</span>
                                                                                        {/* <div className='delete-img'>
                                                                                            <img src="./img/delete.png" alt="" />
                                                                                        </div> */}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                        return null;
                                                                    })}
                                                                    {renderParticipants(participantsList)}
                                                                </>
                                                            )
                                                        }
                                                    </ul>
                                                </main>
                                            </div>
                                        )
                                    }
                                </Col>
                                <Col xs lg="6">
                                    <div>
                                        <div className='add-users-list'>
                                            <div className="legend-box">
                                                <ul className="">
                                                    {digitalManager
                                                        ? (digitalManager.length
                                                            ? (
                                                                digitalManager.map((item, i) => {
                                                                    return (<li id="4" className="legendlist"
                                                                        key={i}
                                                                        onClick={(e) => handleSelectMember(e, item)}
                                                                        style={{ cursor: "pointer" }}>
                                                                        <div className="legendprofile">
                                                                            <img src={item.user.image_url
                                                                                ? item.user.image_url
                                                                                : 'https://picsum.photos/100/100'}
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="legendprofilename">
                                                                            <span className="legPro-name mb-1">
                                                                                <strong>{item.user?.first_name} {item.user?.last_name}</strong>
                                                                            </span>
                                                                            <span className="legPro-username fw-light">
                                                                                {item.user.username
                                                                                    ? `@${item.user.username}`
                                                                                    : item.user?.email
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </li>)
                                                                })
                                                            )
                                                            : (
                                                                <li className='legendlist ' >
                                                                    <div className='legendprofile'>
                                                                    </div>
                                                                    <div className='legendprofilename'>
                                                                        <span className='legPro-name mb-1' >
                                                                            <strong >No digital manager found!</strong>
                                                                        </span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )
                                                        : (errorMsg
                                                            ? (<li className='legendlist  justify-content-center'>
                                                                <div className='legendprofile'>
                                                                </div>
                                                                <div className='legendprofilename text-center'>
                                                                    <span className='legPro-name mb-1' >
                                                                        <strong >Error Occured, try again!</strong>
                                                                    </span>
                                                                </div>
                                                            </li>

                                                            )
                                                            : (<li className='legendlist  justify-content-center'>
                                                                <div className='legendprofile'>
                                                                </div>
                                                                <div className='legendprofilename text-center'>
                                                                    <span className='legPro-name mb-1' >
                                                                        <strong >loading...</strong>
                                                                    </span>
                                                                </div>
                                                            </li>)
                                                        )
                                                    }
                                                </ul>
                                                <div className='legendBtn mx-auto'>
                                                    <button className='red-btn' onClick={handleCloseList}>CLOSE</button>
                                                    <button className='blue-btn' onClick={handleAddParticepant}>AGREGAR</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='legend-sec'>
                                        <div className='news-header'>
                                            <div className='back-sec'
                                                onClick={() => { navigate(-1) }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title '>
                                                ASAMBLEA GENERAL
                                            </h3>
                                            <div className='search-icon'>
                                                <FiSearch />
                                            </div>
                                        </div>
                                        <div className='legend-tabs'>
                                            <Tabs defaultActiveKey="CONVERSACIÓN"
                                                onSelect={(eventKey) => {
                                                    eventKey === 'DETALLES' ? setActiveTabRequired(true) : setActiveTabRequired(false)
                                                }}
                                                id="fill-tab-example" fill>
                                                <Tab eventKey="CONVERSACIÓN" title="CONVERSACIÓN">

                                                    <div className='cal-list-card-sec'>
                                                        <div className='cal-list-card'>
                                                            <div className='left-sec'>
                                                                <div className='profile-img26'>
                                                                    {stateData.state.item?.group_image_url
                                                                        ? <img src={stateData.state.item?.group_image_url} alt="" className='group-icon' />
                                                                        : <img src='./img/message.png' alt="" />
                                                                    }
                                                                </div>
                                                                <span className='event-data' style={{'marginLeft':'10px'}}>
                                                                    <h4>{stateData.state.item.group_name}</h4>
                                                                    <p>{stateData.state.item.group_description}</p>
                                                                </span>
                                                            </div>
                                                            <div className='right-sec d-flex justify-content-center align-items-center'>
                                                                {/* <div className='profile-img2'>
                                                                    <RiDeleteBin6Line
                                                                        style={{
                                                                            color: 'crimson',
                                                                            cursor: 'pointer',
                                                                            borderRadius: '30px',
                                                                            width: '35px',
                                                                        }}
                                                                        onClick={() => { console.log("delete clicked") }}
                                                                    />
                                                                </div> */}
                                                                <h3>548 DDs</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='chatPage-sec'>
                                                        <div className='participatns-sec'>
                                                            <div className='msger-header my-auto'>
                                                                <p className='my-auto'>Participantes</p>
                                                                {/* <div
                                                                    className="add-member-icon"
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <FiPlus
                                                                        onClick={handleAddParticipantes}
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                            <main className='msger partcpt-section'>
                                                                <div className='member-searchBox'>
                                                                    <input type='text'
                                                                        name='serachWord'
                                                                        placeholder='search...'
                                                                        onChange={handleSearch}
                                                                        value={searchWord}
                                                                    />
                                                                </div>
                                                                <ul className='participants-list'>
                                                                    {
                                                                        searchWord?.length
                                                                            ? (
                                                                                <>
                                                                                    {searchMemberList?.map((admin, i) => {
                                                                                        if (admin.is_admin) {
                                                                                            return (
                                                                                                <li className='' key={i}>
                                                                                                    <div className='partcp-profile'>
                                                                                                        <div className='partcp-img'>
                                                                                                            {/* <img
                                                                                                                src={admin.user.image_url ? admin.user.image_url : `https://picsum.photos/100/100`}
                                                                                                                alt="" /> */}
                                                                                                            <p className='partcp-name'>{admin.user.first_name} {admin.user.last_name}</p>
                                                                                                        </div>

                                                                                                        <span className='partcp-admin'>Admin</span>
                                                                                                        {/* <div className='delete-img'>
                                                                                            <img src="./img/delete.png" alt="" />
                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                        return null;
                                                                                    })}
                                                                                    {renderParticipants(searchMemberList)}
                                                                                </>
                                                                            )
                                                                            : (
                                                                                <>
                                                                                    {participantsList?.map((admin, i) => {
                                                                                        if (admin.is_admin) {
                                                                                            return (
                                                                                                <li className='' key={i}>
                                                                                                    <div className='partcp-profile'>
                                                                                                        <div className='partcp-img'>
                                                                                                            <img
                                                                                                                src={admin.user.image_url ? admin.user.image_url : `https://picsum.photos/100/100`}
                                                                                                                alt="" />
                                                                                                            <p className='partcp-name'>{admin.user.first_name} {admin.user.last_name}</p>
                                                                                                        </div>

                                                                                                        <span className='partcp-admin'>Admin</span>
                                                                                                        {/* <div className='delete-img'>
                                                                                            <img src="./img/delete.png" alt="" />
                                                                                        </div> */}
                                                                                                    </div>
                                                                                                </li>
                                                                                            )
                                                                                        }
                                                                                        return null;
                                                                                    })}
                                                                                    {renderParticipants(participantsList)}
                                                                                </>
                                                                            )
                                                                    }
                                                                </ul>
                                                            </main>
                                                        </div>
                                                        <section className="msger">
                                                            <div className="msger-header">
                                                                <p>Conversation</p>
                                                            </div>

                                                            <main className="msger-chat">
                                                                {
                                                                    renderChat()
                                                                }
                                                            </main>

                                                            <div className="input-form">

                                                                <div className="input-form-wrap">
                                                                    <form className="msger-inputarea" onSubmit={handleSendMessage} >
                                                                        <div
                                                                            className="msger-attachment-btn left-btn"
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            <input type='file'
                                                                                // className="msger-attachment-btn left-btn"
                                                                                accept='image/*, video/*, audio/*, .pdf'
                                                                                ref={inputRef}
                                                                                style={{ display: 'none' }}
                                                                                onChange={handleMediaSelection}
                                                                            // multiple
                                                                            />
                                                                            <FiPlus
                                                                                onClick={handleclickinput}
                                                                            />
                                                                        </div>
                                                                        {<>
                                                                            {isWait && (
                                                                                <div className='previewMedia my-2'>
                                                                                    Loading media...
                                                                                </div>
                                                                            )}
                                                                            {mediaURL && (
                                                                                <div className='previewMedia'>
                                                                                    {getMediaPreview()}
                                                                                </div>
                                                                            )}
                                                                        </>

                                                                        }

                                                                        <input
                                                                            type="text"
                                                                            className="msger-input"
                                                                            value={messageTyped}
                                                                            onChange={(e) => { setMessageTyped(e.target.value) }}
                                                                            placeholder="Type a message" />

                                                                        <FiTriangle className='triangle-rot' />

                                                                        {/* <button className="send-message-button-style" type='submit'>
                                                                            <img src="./img/rightArr.png" alt="" />
                                                                        </button> */}

                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>

                                                </Tab>

                                                <Tab eventKey="DETALLES" title="DETALLES">
                                                    <div className='cal-list-card-sec'>
                                                        <div className='cal-list-card'>
                                                            <div className='left-sec'>
                                                                <div className='profile-img26'>
                                                                    {stateData.state.item?.group_image_url
                                                                        ? <img src={stateData.state.item?.group_image_url} alt="" className='group-icon' />
                                                                        : <img src='./img/message.png' alt="" />
                                                                    }
                                                                </div>
                                                                <span className='event-data' style={{ 'marginLeft': '10px' }}>
                                                                    <h4>{stateData.state.item.group_name}</h4>
                                                                    <p>{stateData.state.item.group_description}</p>
                                                                </span>
                                                            </div>
                                                            <div className='right-sec d-flex justify-content-center align-items-center'>

                                                                <h3>548 DDs</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tabGallerySec'>
                                                        <div className='tabGalleryInner'>
                                                            <Tabs
                                                                defaultActiveKey="MEDIA"
                                                                className='tabgalleryList'
                                                                onSelect={(eventKey) => setMediaType(eventKey)}
                                                            >
                                                                <Tab eventKey="MEDIA" title="Media">
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.map((item, i) => {
                                                                                if (item.message_type === 'IMAGE') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <img src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                                else if (item.message_type === 'VIDEO') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <video src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                            })
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="LINK" title="Enlaces">
                                                                    {
                                                                        allMedia && (
                                                                            allMedia.map((item, index) => (
                                                                                <Link to="/">
                                                                                    <div className="enlaces-first-box">
                                                                                        <div className="campass-img">
                                                                                            <img src="./image/campass.png" alt="" />
                                                                                        </div>
                                                                                        <div className="enlaces-text">
                                                                                            <div className="enlaces-title">
                                                                                                <h6 className="mb-0">TikTok ° Edwin R Suqui</h6>
                                                                                            </div>
                                                                                            <div className="enlaces-content">
                                                                                                <p>5175 me gusta, 90 comentarios. Echa un vistazo al video de Edwin R Suqui</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="enlaces-second-box">
                                                                                        <a href="javascript:void(0)">
                                                                                            <span>buen día https://vm.tiktok.com/ZM2oFW/</span>
                                                                                            <img src="./image/angle-right.svg" alt="" />
                                                                                        </a>
                                                                                    </div>
                                                                                </Link>
                                                                            ))
                                                                        )
                                                                    }


                                                                </Tab>
                                                                <Tab eventKey="DOC" title="Docs" >
                                                                    Docs
                                                                </Tab>
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DebateChatRoom
