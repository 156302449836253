import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./ReportsandComplaints.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import { FaBan } from "react-icons/fa";
import GlobalURL from "../../../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../../../locals/translation";

import { IoReloadCircleSharp } from "react-icons/io5";
import "./ReportsandComplaints.css";

function ReportsandComplaints() {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [data, setData] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [disabled, setDisable] = useState(false);
  const [reportCount, setReportCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const [reports, setReports] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [repdetails, setRepdetails] = useState({
    type: "",
    id: "",
    reason: "",
    by: "",
  });
  const [showRepDet, setShowRepDet] = useState(false);

  const [veriList, getVeriList] = useState([]);

  const [changeReports, setChangeReports] = useState(false);

  const navigate = useNavigate();

  const handlePageCount = async (e) => {
    setCurrentPage(currentPage + 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager?page=${
      currentPage + 1
    }&limit=5`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 10) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  };
  const handlePageCountdec = async (e) => {
    setCurrentPage(currentPage - 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager?page=${
      currentPage - 1
    }&limit=5`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 10) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  };
  async function getData() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
        console.log("user profiles", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  async function getReports() {
    setLoading(true);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllReport`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        if (responseData.message === "No Data Found") {
          return;
        }
        setReports(responseData.data);
        console.log("responseData.data: of reports ", responseData.data);
        setReportCount(responseData.data.length);

        console.log("reports", responseData.data);
      } else {
        setLoading(false);
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      setLoading(false);
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  const formatDate = (dateString) => {
    const today = new Date();
    const parsedDate = new Date(dateString);

    if (
      parsedDate.getDate() === today.getDate() &&
      parsedDate.getMonth() === today.getMonth() &&
      parsedDate.getFullYear() === today.getFullYear()
    ) {
      // The date is today
      const hours = parsedDate.getHours();
      const minutes = parsedDate.getMinutes();
      const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}hs`;

      return `hoy, ${formattedTime}`;
    } else {
      // If it's not today, you can customize the format as needed
      const options = { weekday: "long", hour: "numeric", minute: "numeric" };
      return parsedDate.toLocaleString("es-ES", options);
    }
  };

  const markAsReadFunction = () => {
    setChangeReports(!changeReports); // when the report will chnge response OK
    if (selectedReports.length < 1) {
      Swal.fire(t("Seleccione los informes primero"));
      return;
    }
    // fetch API for mrk read Reports
  };

  const markAsUnReadFunction = () => {
    setChangeReports(!changeReports); // when the report will chnge response OK
    if (selectedReports.length < 1) {
      Swal.fire(t("Seleccione los informes primero"));
      return;
    }
    // fetch API for mrk read Reports
  };

  const readByModFunction = (item) => {
    // fetch API for read by App moderator...
  };

  useEffect(() => {
    getData();
    getReports();
    handlePageCount();
  }, []);

  useEffect(() => {
    getReports();
  }, [changeReports]);

  return (
    <div className="digital-table-wrap">
      <div className="digital-table digitalInner-table">
        <div className="digitalTable-left">
          <div
            className="digitalTable-head"
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <IoReloadCircleSharp
              className={
                loading
                  ? `retoation-animation reloading-button`
                  : `reloading-button`
              }
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowRepDet(false);
                getReports();
              }}
            />
          </div>
          <div className="digitalLeft-body">
            <ul className="digital-tudo-list">
              <li
                onClick={() => {
                  setShowRepDet(false);
                  getReports();
                }}
              >
                <Link>
                  <span className="tudo-name">{t("Reportes")}</span>
                  <span className="tudo-counter">{reportCount}</span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowRepDet(false);
                }}
              >
                <Link>
                  <span
                    className="tudo-name"
                    onClick={() => {
                      markAsReadFunction();
                    }}
                  >
                    {" "}
                    {t("Marcar como leído")}
                  </span>
                </Link>
              </li>
              <li
                onClick={() => {
                  setShowRepDet(false);
                }}
              >
                <Link>
                  <span
                    className="tudo-name"
                    onClick={() => {
                      markAsUnReadFunction();
                    }}
                  >
                    {t("Marcar como no leído")}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {showRepDet ? (
          <div className="digital-right">
            <div className="digitalTable-head d-flex flex-wrap">
              <div className="digihead-left">
                <p className="mb-0">{t("Asunto")}</p>
              </div>
              <div className="digihead-right">
                <p className="mb-0">{repdetails.reason}</p>
              </div>
            </div>

            <div className="digitalRight-body">
              <ul className="Digiright-table p-0 mb-0">
                <li>
                  <div className="digitalId-div">
                    <p className="mb-0">{t("ID")}</p>
                  </div>
                  <div className="digiRight-content-outer">
                    <div className="digiRight-content">
                      <div className="digiRight-report">
                        <p className="mb-0">
                          {t("Denuncia")} #{repdetails.id}
                        </p>
                      </div>
                      <div className="digiRight-text">
                        <p className="mb-0">{repdetails.reason}</p>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="digitalId-div">
                    <p className="mb-0">{t("De")}</p>
                  </div>
                  <div className="digiRight-content-outer">
                    <div className="digiRight-content">
                      <div className="digiRight-report">
                        <p className="mb-0">@{repdetails.username}</p>
                      </div>
                      <div className="digiRight-text">
                        <p className="mb-0">{repdetails.reason}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="digipost-sec">
                <div className="ds-text">
                  <p className="mb-0">{`El Dirigente Digital @${repdetails.first_name} ha denunciado el siguiente post:`}</p>
                </div>

                <div className="comment-box-outer black-bg cm-box">
                  <div className="comment-box">
                    <div className="reciver-left">
                      <img src="./img/pr-img.png" alt="" />
                    </div>
                    <div className="profile-text">
                      <span className="profile-name">
                        <strong>
                          {" "}
                          {t("TODAY Thursday - SUBSCRIBED EVENT")}
                        </strong>
                      </span>
                      <span className="profile-desc">
                        {t("Streaming del Kun Agüero")}
                      </span>
                    </div>
                    <div className="profile-time">
                      <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                            stroke="#FEF6F2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                            stroke="#FEF6F2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                            stroke="#FEF6F2"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div className="comment-sec">
                    <ul className="social-comment">
                      <li>
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 10.7 9.954"
                          >
                            <path
                              id="comentario_icon"
                              data-name="comentario icon"
                              d="M56.394,64H49.306A1.309,1.309,0,0,0,48,65.306v4.477a1.309,1.309,0,0,0,1.306,1.306h.933v1.865l2.185-1.822a.187.187,0,0,1,.12-.043h3.851A1.309,1.309,0,0,0,57.7,69.783V65.306A1.309,1.309,0,0,0,56.394,64Z"
                              transform="translate(-47.5 -63.5)"
                              fill="none"
                              stroke="#000"
                              stroke-linejoin="round"
                              stroke-width="1"
                            />
                          </svg>
                        </Link>
                        <span>5</span>
                      </li>
                      <li>
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 10.372 10.775"
                          >
                            <g
                              id="like_icon"
                              data-name="like icon"
                              transform="translate(0.35 0.35)"
                            >
                              <path
                                id="Trazado_2828"
                                data-name="Trazado 2828"
                                d="M.417,2.609h2.02a.289.289,0,0,1,.288.29V7.537a.289.289,0,0,1-.288.29H.417a.29.29,0,0,1-.29-.29V2.9a.291.291,0,0,1,.29-.288"
                                transform="translate(-0.127 2.248)"
                                fill="none"
                                stroke="#000"
                                stroke-linejoin="round"
                                stroke-width="0.7"
                              />
                              <path
                                id="Trazado_2829"
                                data-name="Trazado 2829"
                                d="M1.815,9.112V5.788a4.412,4.412,0,0,1,.662-2.314A11.963,11.963,0,0,1,3.661,2.029C3.734,1.951,4,1.722,4,1.594c0-.161.02-.421.025-.908A.564.564,0,0,1,4.587.128H4.6a.789.789,0,0,1,.713.509,2.654,2.654,0,0,1,.031,2.024,5.751,5.751,0,0,1-.439,1.016s-.117.17.255.162L7.51,3.83a.729.729,0,0,1,.672.836.752.752,0,0,1-.5.736.04.04,0,0,0-.023.053.038.038,0,0,0,.02.02.88.88,0,0,1,.491.707A.648.648,0,0,1,7.61,6.9a.038.038,0,0,0-.029.047.043.043,0,0,0,.02.025.712.712,0,0,1,.37.675.62.62,0,0,1-.468.6.039.039,0,0,0-.027.049.035.035,0,0,0,.016.022.607.607,0,0,1,.245.552.589.589,0,0,1-.607.6l-5.039.01s-.274,0-.274-.366"
                                transform="translate(1.489 -0.128)"
                                fill="none"
                                stroke="#000"
                                stroke-linejoin="round"
                                stroke-width="0.7"
                              />
                            </g>
                          </svg>
                        </Link>
                        <span>23</span>
                      </li>
                      <li>
                        <Link>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 8.461 10.7"
                          >
                            <path
                              id="compartir"
                              d="M101.6,51.358h.933a.933.933,0,0,1,.933.933v4.477a.933.933,0,0,1-.933.933h-5.6A.933.933,0,0,1,96,56.767V52.29a.933.933,0,0,1,.933-.933h.933m3.731-1.492L99.731,48l-1.865,1.865m1.865,4.5V48"
                              transform="translate(-95.5 -47.5)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                            />
                          </svg>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* post */}

                {/* <div className='comment-box black-bg white-comment-box'>
                                                                    <div className="reciver-left">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 5.78146C1 3.646 2.62571 2 4.76316 2H14.2368C16.3973 2 18 3.76883 18 5.78146V11.7417C18 13.9004 16.2523 15.5232 14.2368 15.5232H8.01974C7.37166 15.5232 6.78381 15.7363 6.23901 16.127L2.59201 18.8059C2.28819 19.0291 1.88468 19.0625 1.54834 18.8922C1.212 18.7219 1 18.377 1 18V5.78146ZM3 16.0246L5.06751 14.5059C5.90319 13.9051 6.89305 13.5232 8.01974 13.5232H14.2368C15.182 13.5232 16 12.7619 16 11.7417V5.78146C16 4.81395 15.2342 4 14.2368 4H4.76316C3.74271 4 3 4.7381 3 5.78146V16.0246Z" fill="black" />
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5308 8.62091C19.7401 8.10986 20.3242 7.86529 20.8352 8.07467C22.0953 8.59089 23.0002 9.78417 23.0002 11.1825V22C23.0002 22.3668 22.7994 22.7042 22.477 22.879C22.1546 23.0539 21.7623 23.0382 21.4548 22.8382L17.9548 20.5608C17.933 20.5466 17.9117 20.5316 17.8911 20.5157C17.5071 20.2207 16.9269 20.0584 16.2355 20.0584H10.3679C8.9812 20.0584 7.68927 19.2926 7.09946 18.0694C6.8596 17.5719 7.06844 16.9742 7.56592 16.7343C8.0634 16.4944 8.66113 16.7033 8.901 17.2008C9.13472 17.6855 9.69573 18.0584 10.3679 18.0584H16.2355C17.1787 18.0584 18.2301 18.27 19.0758 18.9041L21.0002 20.1563V11.1825C21.0002 10.683 20.6699 10.1683 20.077 9.92537C19.5659 9.716 19.3214 9.13197 19.5308 8.62091Z" fill="black" />
                                                                        </svg>
                                                                    </div>
                                                                    <div className="profile-text">
                                                                        <span className='profile-name'><strong> Nombre de Asamblea General</strong></span>
                                                                        <span className='profile-desc'>Debates de temas generales o específicos</span>
                                                                    </div>
                                                                    <div className="profile-time">
                                                                        <span>548 DDs</span>
                                                                    </div>
                                                                </div>
                                                                general assembly */}
              </div>
            </div>
          </div>
        ) : (
          <div className="digital-right">
            <div className="digitalTable-head">
              <p className="mb-0">{t("REPORTES & DENUNCIAS")}</p>
            </div>
            <div className="digitalRight-body">
              {loading ? (
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loading-adjust">
                    <div className="Loader d-flex justify-content-center align-items-center">
                      <div
                        className="spinner-border spinner-border-sm my-5"
                        role="status"
                      >
                        <span className="sr-only"></span>
                      </div>
                      <div className="my-5"> &nbsp; {t("Cargando...")}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <ul className="Digiright-table p-0 mb-0">
                  {reports.length < 1 ? (
                    <div> {t("Aún no hay informes")}</div>
                  ) : (
                    reports.map((item, index) => (
                      <li key={index}>
                        <div className="checkbox-div">
                          <input
                            type="checkbox"
                            onClick={() =>
                              setSelectedReports((prevSelectedReports) => [
                                ...prevSelectedReports,
                                item.report_id,
                              ])
                            }
                          />
                        </div>
                        <div
                          className={
                            item?.is_read
                              ? "digiRight-content-outer"
                              : "digiRight-content-outer-unread"
                          }
                        >
                          <div
                            className="digiRight-content"
                            onClick={() => {
                              setShowRepDet(true);
                              readByModFunction(item);
                              setRepdetails({
                                id: item.report_id,
                                reason: item.reason,
                                by: item.user.first_name,
                              });
                            }}
                          >
                            <div className="digiRight-report">
                              <p className="mb-0">
                                {t("Denuncia")} #{item.report_id}
                              </p>
                            </div>
                            <div className="digiRight-text">
                              <p className="mb-0">{item.reason}</p>
                            </div>
                          </div>
                          <div className="digiright-time">
                            <p className="mb-0">{formatDate(item.createdAt)}</p>
                          </div>
                        </div>
                      </li>
                    ))
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportsandComplaints;
