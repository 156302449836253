// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA6wzhheeqrcIgwbqiSqobFmGvGe0lbX9c",
  authDomain: "mess-ice-app.firebaseapp.com",
  projectId: "mess-ice-app",
  storageBucket: "mess-ice-app.appspot.com",
  messagingSenderId: "229022988461",
  appId: "1:229022988461:web:5989139d8d54c0e68bee24",
  measurementId: "G-MJZDMRPFBX",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
