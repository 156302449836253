import React from "react";
import { Chart } from "react-google-charts";

const BarChart = ({ optionsList }) => {
  const optionsColor = ["green", "yellow", "#67267a", "#f43c09"];

  const options = {
    // title: "Votes & proposal",
    width: 280,
    height: 250,
    backgroundColor: "transparent",
    color: "white",
    bar: { groupWidth: "80%" },
    legend: { position: "none" },
  };

  const data = [
    [
      "Element",
      "Density",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
  ];

  optionsList.forEach((element, i) => {
    data.push([element.option_text, element.votes, optionsColor[i], null]);
  });

  return (
    <div className="chart-content ">
      <Chart
        chartType="BarChart"
        width="100%"
        // height="200px"
        data={data}
        options={options}
        loader={
          <span className="fw-lighter font-monospace my-2 text-secondary">
            Loading Chart...
          </span>
        }
      />
    </div>
  );
};

export default BarChart;
