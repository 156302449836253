import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'
import "./style.css"
import { IoMenu } from 'react-icons/io5';


import { useSelector } from 'react-redux';
import translations from '../../locals/translation';
import { setLanguage, selectLanguage } from '../../redux/languageSlice';

const Sidebar = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
      // Check if translations exist for the current language
      if (translations[language]) {
        // Access translation for the provided key
        const translation = translations[language][key];
        // Return translation if found, otherwise return the key itself
        return translation !== undefined ? translation : key;
      } else {
        // Return the key itself if translations for the current language are not available
        return key;
      }
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <button className='menu-icons-btn' onClick={toggleSidebar}>
                <IoMenu />
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <ul className='sidebar-link-sec'>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/proposalvote">
                            <img src="./img/propuestas.png" alt="" /><span>{t('PROPUESTAS & VOTOS')}</span>
                        </NavLink>
                    </li >
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="  ">
                            <img src="./img/chaticon.png" alt="" /><span>{t('SALÓN DE DEBATES')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/billboard">
                            <img src="./img/cartelera.png" alt="" /><span>{t('CARTELERA')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/streaming-page">
                            <img src="./img/streaming.png" alt="" /><span>{t('STREAMING')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/experiencepage">
                            <img src="./img/VIVENCIASMESS_ICE.png" alt="" /><span>{t('VIVENCIAS MESS ICE')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/events">
                            <img src="./img/Eventos.png" alt="" /><span>{t('EVENTOS')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/multimedia">
                            <img src="./img/multimedia.png" alt="" /><span>{t('MULTIMEDIA')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="  ">
                            <img src="./img/AdminEquipo.png" alt="" /><span>{t('ADMINISTRACIÓN DE EQUIPO')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="  ">
                            <img src="./img/envalope.png" alt="" /><span>{t('INSTITUCIONAL')}</span>
                        </NavLink>
                    </li>
                    <li onClick={toggleSidebar}>
                        <NavLink className="sidebarLink" to="/store">
                            <img src="./img/store.png" alt="" /><span>{t('TIENDA VIRTUAL')}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;