import React, { useEffect } from 'react'
import { FiSearch } from "react-icons/fi"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./AllVerifiedLeaders.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import Swal from 'sweetalert2';
import { ImCross } from "react-icons/im";
import { FaBan } from "react-icons/fa";
import GlobalURL from '../../../BaseUrl';
import { useSelector } from 'react-redux';
import translations from '../../../../locals/translation';
import Chart from 'react-google-charts';
import './style14.css';


function AllVerifiedLeaders({ search }) {
    console.log("search Tearms:: AVL ", search);
    const [originalData, setOriginalData] = useState('');

    const [searchActive, setSearchActive] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const [dummyAvailable, setDummyAvailable] = useState(false);

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const [data, setData] = useState([]);
    const [leaders, setLeaders] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [disabled, setDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const [query, setQuery] = useState('');

    const [data2, setData2] = useState([]);

    const [reports, setReports] = useState([]);
    const [repdetails, setRepdetails] = useState({
        'type': '',
        'id': '',
        'reason': '',
        'by': '',
    });
    const [showRepDet, setShowRepDet] = useState(false);

    const [veriList, getVeriList] = useState([]);

    const [tab, setTab] = useState('todas');
    const [dummyData, setDummyData] = useState([]);

    const [dD, setDD] = useState(true);
    const [dV, setDV] = useState(false);
    const [sV, setSV] = useState(false);
    const [rD, setRD] = useState(false);
    const [eB, setEB] = useState(false);

    const navigate = useNavigate();

    const handlePageCount = async (e) => {
        setCurrentPage(currentPage + 1);
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`; // endpoint
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ 'search': '' })
            });

            // Check if the request was successful (status code 2xx)

            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.data < 5) {
                    setDisable(true);
                }


                const temp = { gold: 0, platinum: 0, diamond: 0 };

                responseData.data.forEach(item => {
                    switch (item.plan_id) {
                        case 1:
                            temp.gold++;
                            break;
                        case 2:
                            temp.gold++;
                            break;
                        case 3:
                            temp.gold++;
                            break;
                        case 4:
                            temp.gold++;
                            break;
                        case 5:
                            temp.platinum++;
                            break;
                        case 6:
                            temp.diamond++;
                            break;
                        default:
                            break;
                    }
                });

                const updatedData = [
                    ["Subscription", "Users"],
                    ["Plata", temp.platinum],  // Update Platinum count
                    ["Oro", temp.gold],        // Update Gold count
                    ["Diamante", temp.diamond] // Update Diamond count
                ];

                setData2(updatedData);


                setData(responseData.data);
                console.log("user profiles", responseData.data);
                setOriginalData(responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));

            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    }
    const handlePageCountdec = async (e) => {
        setCurrentPage(currentPage - 1);
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`; // endpoint
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ 'search': searchTerm })
            });

            // Check if the request was successful (status code 2xx)

            if (response.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();


                const temp = { gold: 0, platinum: 0, diamond: 0 };

                responseData.data.forEach(item => {
                    switch (item.plan_id) {
                        case 1:
                            temp.gold++;
                            break;
                        case 2:
                            temp.gold++;
                            break;
                        case 3:
                            temp.gold++;
                            break;
                        case 4:
                            temp.gold++;
                            break;
                        case 5:
                            temp.platinum++;
                            break;
                        case 6:
                            temp.diamond++;
                            break;
                        default:
                            break;
                    }
                });

                const updatedData = [
                    ["Subscription", "Users"],
                    ["Plata", temp.platinum],  // Update Platinum count
                    ["Oro", temp.gold],        // Update Gold count
                    ["Diamante", temp.diamond] // Update Diamond count
                ];

                setData2(updatedData);


                setData(responseData.data);
                console.log("user profiles", responseData.data);
                setOriginalData(responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire('Error del Servidor');
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire('Error del Servidor');
        }
    }
    async function getData() {
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ 'search': '' })
            });

            // Check if the request was successful (status code 2xx)

            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();


                const temp = { gold: 0, platinum: 0, diamond: 0 };

                responseData.data.forEach(item => {
                    switch (item.plan_id) {
                        case 1:
                            temp.gold++;
                            break;
                        case 2:
                            temp.gold++;
                            break;
                        case 3:
                            temp.gold++;
                            break;
                        case 4:
                            temp.gold++;
                            break;
                        case 5:
                            temp.platinum++;
                            break;
                        case 6:
                            temp.diamond++;
                            break;
                        default:
                            break;
                    }
                });

                const updatedData = [
                    ["Subscription", "Users"],
                    ["Plata", temp.platinum],  // Update Platinum count
                    ["Oro", temp.gold],        // Update Gold count
                    ["Diamante", temp.diamond] // Update Diamond count
                ];

                setData2(updatedData);


                setData(responseData.data);
                console.log("user profiles", responseData.data);
                setOriginalData(responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    }
    async function getReports() {
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllReport`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
            });

            // Check if the request was successful (status code 2xx)
            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.message === "No Data Found") {
                    return
                }
                setReports(responseData.data);

                console.log("reports", responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    }
    const formatDate = (dateString) => {
        const today = new Date();
        const parsedDate = new Date(dateString);

        if (
            parsedDate.getDate() === today.getDate() &&
            parsedDate.getMonth() === today.getMonth() &&
            parsedDate.getFullYear() === today.getFullYear()
        ) {
            // The date is today
            const hours = parsedDate.getHours();
            const minutes = parsedDate.getMinutes();
            const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}hs`;

            return `hoy, ${formattedTime}`;
        } else {
            // If it's not today, you can customize the format as needed
            const options = { weekday: 'long', hour: 'numeric', minute: 'numeric' };
            return parsedDate.toLocaleString('es-ES', options);
        }
    };
    async function getDataOfSearch() {
        setDummyAvailable(true);
        const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
        const token = localStorage.getItem('token');

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    "ngrok-skip-browser-warning": "69420",
                },
                body: JSON.stringify({ 'search': searchTerm })
            });

            // Check if the request was successful (status code 2xx)

            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();
                if (responseData.data == null || responseData.data == []) {
                    return
                }
                setDummyData(responseData.data);
                console.log("user profiles", responseData.data);
                setOriginalData(responseData.data);
            } else {
                // If the server returns an error status code
                const errorData = await response.json();
                Swal.fire(t('Error del Servidor'));
            }
        } catch (error) {
            // Handle any network or other errors that may occur during the fetch
            console.error('Error:', error.message);
            Swal.fire(t('Error del Servidor'));
        }
    }
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value) {
            getData();
        }
    }
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value === '') {
            getData();
            setSearchTerm('')
            console.log('Executed');
            setDummyData([]);
            setDummyAvailable(false);
        }
    }
    useEffect(() => {
        getData();
        getReports();

    }, [])


    const options = {
        title: "",
        legend: "none",
        pieSliceText: "label",
        backgroundColor: "transparent",
        titleTextStyle: {
            color: '#FEF6F2'
        },
        slices: {
            0: {
                offset: 0.1,
                color: '#005EAA',
                link: '/path/to/some/page'
            },
            1: {
                color: '#25762E',
                link: '/another/page'
            },
            2: {
                color: '#C37704',
                link: '/yet/another/page'
            },
        },
    };

    return (
        <div>
            <div className='search-icon-in managers' style={{ display: 'flex' }} >
                {searchActive && <div className="searchBarInput"><input className='search-tag-bar'
                    placeholder={t('Buscar')} value={searchTerm}

                    onChange={(e) => {
                        handleSearchChange(e);
                    }}

                /></div>}
                <FiSearch className='search-icon-click' onClick={getDataOfSearch} />
            </div>


            {/* verified leaders */}
            {
                dummyAvailable ? (

                    <>
                        <Tabs id="fill-tab-example" style={{ fontFamily: 'Francois One', background: 'transparent', color: 'white', }} >
                            <Tab eventKey="todas" title={t('Resultado de búsqueda')} style={{ borderBottomColor: '#cb8b15' }} >
                                <div className='tab-content-wrapper'>
                                    {
                                        dummyData.length < 1 ? (<div style={{ fontSize: '18px', marginTop: '20px' }}>{t('No se encontró ningún líder')}</div>)
                                            :
                                            (
                                                dummyData.map((item, index) => {
                                                    return (
                                                        <div className='todas-card' style={{ marginTop: '40px' }} key={index} onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                            <div className='left-sec'>
                                                                <div className='profile-img'>
                                                                    <img src={item.image_url} alt="" />
                                                                </div>
                                                                <div className='diam-img'>
                                                                    {item.is_verified && (<img src="./img/Diamante.png" className='gem-for-veri' alt="gem" />)}
                                                                </div>
                                                                <div className='profile-content'>
                                                                    <h5>{item.first_name + item.last_name}</h5>
                                                                    <p>{item.username}</p>
                                                                </div>
                                                            </div>
                                                            <div className='right-sec'>
                                                                <span className='todas-img'>
                                                                    {item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4 && (<img src="./img/gold-bar.png" alt="" />)}
                                                                    {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                    {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                    }
                                </div>
                            </Tab>
                        </Tabs>

                    </>) : (
                    <>

                        <div className='tabs-cards-sec'>
                            {/* implement loader */}
                            <Tabs defaultActiveKey="todas" id="fill-tab-example" style={{ fontFamily: 'Francois One' }} fill onSelect={(eventKey) => { setTab(eventKey); setSearchTerm('') }} >
                                <Tab eventKey="todas" title={t('TODAS')}  >
                                    <div className='tab-content-wrapper'>
                                        {
                                            data.length < 1 ? (<div>{t('No se encontró ningún líder')}</div>)
                                                :
                                                (
                                                    data.map((item, index) => {
                                                        return (
                                                            <div className='todas-card' key={index} onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                                <div className='left-sec'>
                                                                    <div className='profile-img'>
                                                                        <img src={item.image_url} alt="" />
                                                                    </div>
                                                                    <div className='diam-img'>
                                                                        {item.is_verified && (<img src="./img/Diamante.png" className='gem-for-veri' alt="gem" />)}
                                                                    </div>
                                                                    <div className='profile-content'>
                                                                        <h5>{item.first_name + item.last_name}</h5>
                                                                        <p>{item.username}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='right-sec'>
                                                                    <span className='todas-img'>
                                                                        {(item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4) && (<img src="./img/gold-bar.png" alt="" />)}
                                                                        {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                        {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                        }
                                    </div>
                                </Tab>


                                <Tab eventKey="oro" title={t('ORO')} >
                                    {
                                        data.filter(item => (item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4)).length < 1 ? (<div>{t('No Gold Members available')}</div>)
                                            :
                                            (
                                                data.filter(item => (item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4)).map((item, index) => {
                                                    return (
                                                        <div className='todas-card' key={index} onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                            <div className='left-sec'>
                                                                <div className='profile-img'>
                                                                    <img src={item.image_url} alt="" />
                                                                </div>
                                                                <div className='diam-img'>
                                                                    <img src="./img/Diamante.png" className='gem-for-veri' alt="gem" />
                                                                </div>
                                                                <div className='profile-content'>
                                                                    <h5>{item.first_name + item.last_name}</h5>
                                                                    <p>{item.username}</p>
                                                                </div>
                                                            </div>
                                                            <div className='right-sec'>
                                                                <span className='todas-img'>
                                                                    {(item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4) && (<img src="./img/gold-bar.png" alt="" />)}
                                                                    {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                    {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            )
                                    }
                                </Tab>


                                <Tab eventKey="platino" title={t('PLATINO')}>
                                    {
                                        data.filter(item => item.plan_id === 5).length < 1 ? (<div> {t('No Platinum Member Available')} </div>) : (
                                            data.filter(item => item.plan_id === 5).map((item, index) => {
                                                return (
                                                    <div className='todas-card' key={index} onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                        <div className='left-sec'>
                                                            <div className='profile-img'>
                                                                <img src={item.image_url} alt="" />
                                                            </div>
                                                            <div className='diam-img'>
                                                                <img src="./img/Diamante.png" className='gem-for-veri' alt="gem" />
                                                            </div>
                                                            <div className='profile-content'>
                                                                <h5>{item.first_name + item.last_name}</h5>
                                                                <p>{item.username}</p>
                                                            </div>
                                                        </div>
                                                        <div className='right-sec'>
                                                            <span className='todas-img'>
                                                                {(item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4) && (<img src="./img/gold-bar.png" alt="" />)}
                                                                {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }

                                </Tab>


                                <Tab eventKey="diamante" title={t('DIAMANTE')}>
                                    {
                                        data.filter(item => item.plan_id === 6).length < 1 ? (<div>{t('Ningún miembro de DIAMANTE encontrado')}</div>) : (
                                            data.filter(item => item.plan_id === 6).map((item, index) => {
                                                return (
                                                    <div className='todas-card' key={index} onClick={() => { navigate('/leaderprofile', { 'state': item }) }}>
                                                        <div className='left-sec'>
                                                            <div className='profile-img'>
                                                                <img src={item.image_url} alt="" />
                                                            </div>
                                                            <div className='diam-img'>
                                                                <img src="./img/Diamante.png" className='gem-for-veri' alt="gem" />
                                                            </div>
                                                            <div className='profile-content'>
                                                                <h5>{item.first_name + item.last_name}</h5>
                                                                <p>{item.username}</p>
                                                            </div>
                                                        </div>
                                                        <div className='right-sec'>
                                                            <span className='todas-img'>
                                                                {(item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4) && (<img src="./img/gold-bar.png" alt="" />)}
                                                                {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    }
                                </Tab>


                            </Tabs>

                            {/* <div className='page-shifting'>
                                <div className='current-page-text'>{t('Página:')}<div className='page-count'>{currentPage + 1}</div> </div>
                                <button className='icon-arrow' disabled={currentPage < 1} onClick={handlePageCountdec}>{<MdArrowBackIosNew />}</button>
                                <button className='icon-arrow' disabled={disabled} onClick={handlePageCount}>{<MdArrowForwardIos />}</button>
                            </div> */}
                        </div>

                        <div className='chart-graph'>
                            <div className="team-list-char-sec" >
                                <div className="piechart-sec text-white" hidden={false} onClick={() => { navigate('/digitalsection') }} >

                                    {/* <p style={{ 'display': 'flex', 'justifyContent': 'center', 'fontFamily': 'Francois One', 'font-size': '15px', 'textTransform': 'uppercase' }}>{t('Dirigentes Verificados')}</p> */}
                                </div>
                            </div>
                        </div>
                    </>
                )
            }


        </div>
    )
}

export default AllVerifiedLeaders