import React, { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Link } from "react-router-dom";
import DigitalSecTwo from "../../DigitalSecTwo/DigitalSecTwo";
import VerificationReq from "../../VerificationReq/VerificationReq";
import AllDigitalLeaders from "../DigitalSec/AllDigitalLeaders/AllDigitalLeaders";
import AllVerifiedLeaders from "./AllVerifiedLeaders/AllVerifiedLeaders";
import ReportsandComplaints from "./Reports/ReportsandComplaints";

import Chart from "../../../charts/charts";
import { useSelector } from "react-redux";
import translations from "../../../locals/translation";
import GlobalURL from "../../BaseUrl";

export const data = [
  ["Subscription", "Users"],
  [`<img src='https://img.icons8.com/ios/80/000000/diamond.png'/>`, 25],
  ["Silver", 25],
  ["Golds", 25],
  ["Empty", 25],
];

const options = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  backgroundColor: "transparent",
  titleTextStyle: {
    color: "#FEF6F2",
  },
  slices: {
    0: {
      offset: 0.1,
      color: "#25762E",
      link: "/path/to/some/page",
    },
    1: {
      color: "#C37704",
      link: "/another/page",
    },
    2: {
      color: "#005EAA",
      link: "/yet/another/page",
    },
  },
};

const DigitalSec = () => {
  const language = useSelector((state) => state.language.value);

  const [loading, setLoading] = useState(true);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [dD, setDD] = useState(false);
  const [dV, setDV] = useState(false);
  const [sV, setSV] = useState(false);
  const [rD, setRD] = useState(false);
  const [eB, setEB] = useState(true);

  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  async function getData2() {
    // const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)

      if (response.code === 401) {
        setLoading(false);
        navigate("/login");
      }

      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        console.log("responseData.data: ", responseData.data.user);

        const temp = { gold: 0, platinum: 0, diamond: 0 };

        responseData.data.user.forEach((item) => {
          switch (item.plan_id) {
            case 1:
              temp.gold++;
              break;
            case 2:
              temp.gold++;
              break;
            case 3:
              temp.gold++;
              break;
            case 4:
              temp.gold++;
              break;
            case 5:
              temp.platinum++;
              break;
            case 6:
              temp.diamond++;
              break;
            default:
              break;
          }
        });

        const updatedData = [
          ["Subscription", "Users"],
          ["Plata", temp.platinum], // Update Platinum count
          ["Oro", temp.gold], // Update Gold count
          ["Diamante", temp.diamond], // Update Diamond count
        ];

        setData2(updatedData);
        console.log("user profiles", responseData.data);
        setLoading(false);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        setLoading(false);
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      // Swal.fire(t('Error del Servidor'));
      setLoading(false);
    }
  }

  async function getData3() {
    // const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)

      if (response.code === 401) {
        setLoading(false);
        navigate("/login");
      }

      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        console.log(
          "responseData.data: of verified leaders ",
          responseData.data
        );

        const temp = { gold: 0, platinum: 0, diamond: 0 };

        responseData.data.forEach((item) => {
          switch (item.plan_id) {
            case 1:
              temp.gold++;
              break;
            case 2:
              temp.gold++;
              break;
            case 3:
              temp.gold++;
              break;
            case 4:
              temp.gold++;
              break;
            case 5:
              temp.platinum++;
              break;
            case 6:
              temp.diamond++;
              break;
            default:
              break;
          }
        });

        const updatedData = [
          ["Subscription", "Users"],
          ["Plata", temp.platinum], // Update Platinum count
          ["Oro", temp.gold], // Update Gold count
          ["Diamante", temp.diamond], // Update Diamond count
        ];

        setData3(updatedData);
        console.log("user profiles", responseData.data);
        setLoading(false);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        setLoading(false);
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      // Swal.fire(t('Error del Servidor'));
      setLoading(false);
    }
  }

  useEffect(() => {
    getData2();
    getData3();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="Digital-sec">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className=" first-sec" xs lg="3"></Col>
                <Col className="sec_digSec" lg="6" style={{ position: "relative" }}>
                  <div className="digital-heading">
                    <div
                      className="back-sec"
                      onClick={() => {
                        navigate("/homepage");
                      }}
                    >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 style={{ fontFamily: "Felix-Titling" }}>
                      {t("ADMINISTRACIÓN DE USUARIOS")}
                    </h3>
                    <div className="search-icon" style={{ display: "flex" }}>
                      {searchActive && (
                        <div className="searchBarInput">
                          <input
                            className="search-tag-bar"
                            placeholder={t("Buscar")}
                            value={searchTerm}
                          />
                        </div>
                      )}
                      <FiSearch
                        style={{ opacity: "0" }}
                        className="search-icon-click"
                        onClick={() => {
                          setSearchActive(!searchActive);
                        }}
                      />
                    </div>
                  </div>
                  <ul className="digitales-list ">
                    <li
                      className={eB ? "li-hover-data" : ""}
                      onClick={() => {
                        setDD(false);
                        setDV(false);
                        setSV(false);
                        setRD(false);
                        setEB(true);
                      }}
                    >
                      <Link>
                        <img src="./img/dl5.png" alt="" />
                        <div className="dl-text">
                          <p className="mb-0">{t("Dirigentes Digitales")}</p>
                        </div>
                      </Link>
                    </li>
                    <li
                      className={dD ? "li-hover-data" : ""}
                      onClick={() => {
                        setDD(true);
                        setDV(false);
                        setSV(false);
                        setRD(false);
                        setEB(false);
                      }}
                    >
                      <Link>
                        <img src="./img/dl1.png" alt="" />
                        <div className="dl-text">
                          <p className="mb-0">{t("Dirigentes Verificados")}</p>
                        </div>
                      </Link>
                    </li>
                    <li
                      className={rD ? "li-hover-data" : ""}
                      onClick={() => {
                        setDD(false);
                        setDV(false);
                        setSV(false);
                        setRD(true);
                        setEB(false);
                      }}
                    >
                      <Link>
                        <img src="./img/dl4.png" alt="" />
                        <div className="dl-text">
                          <p className="mb-0">
                            {t("Solicitudes de Verificación")}
                          </p>
                        </div>
                      </Link>
                    </li>
                    <li
                      className={sV ? "li-hover-data" : ""}
                      onClick={() => {
                        setDD(false);
                        setDV(false);
                        setSV(true);
                        setRD(false);
                        setEB(false);
                      }}
                    >
                      <Link>
                        <img src="./img/dl3.png" alt="" />
                        <div className="dl-text">
                          <p className="mb-0">{t("Reportes & Denuncias")}</p>
                        </div>
                      </Link>
                    </li>
                    <li
                      className={dV ? "li-hover-data" : ""}
                      onClick={() => {
                        setDD(false);
                        setDV(true);
                        setSV(false);
                        setRD(false);
                        setEB(false);
                      }}
                    >
                      <Link>
                        <img src="./img/dl2.png" alt="" />
                        <div className="dl-text">
                          <p className="mb-0">{t("Eliminación & Bloqueos")}</p>
                        </div>
                      </Link>
                    </li>
                  </ul>

                  <div className="tabs-section">
                    {eB && <AllDigitalLeaders />}
                    {dD && <AllVerifiedLeaders />}
                    {rD && <VerificationReq />}
                    {sV && <ReportsandComplaints />}
                    {dV && <DigitalSecTwo />}
                  </div>
                </Col>
                <Col className="third-sec chart-box ds-chart" xs lg="3">
                  {dD &&
                    (loading ? (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="loading-adjust">
                          <div className="Loader d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border-sm my-5"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                            <div className="my-5">
                              {" "}
                              &nbsp; {t("Cargando...")}
                            </div>
                          </div>
                        </div>
                      </div> // Placeholder for loading indicator
                    ) : (
                      <>
                        <Chart data={data3} options={options} />
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Francois One",
                            fontSize: "15px",
                            maxWidth: "300px",
                          }}
                        >
                          {t("DIRIGENTES DIGITALES")}
                        </p>
                      </>
                    ))}
                  {eB &&
                    (loading ? (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="loading-adjust">
                          <div className="Loader d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border-sm my-5"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                            <div className="my-5">
                              {" "}
                              &nbsp; {t("Cargando...")}
                            </div>
                          </div>
                        </div>
                      </div> // Placeholder for loading indicator
                    ) : (
                      <>
                        <Chart data={data2} options={options} />
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Francois One",
                            fontSize: "15px",
                            maxWidth: "300px",
                          }}
                        >
                          {t("DIRIGENTES DIGITALES")}
                        </p>
                      </>
                    ))}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalSec;
