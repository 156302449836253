import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { GrUpload } from "react-icons/gr";
import { useEffect } from "react";
import Swal from "sweetalert2";
import "./style.css";
import { useRef } from "react";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import GlobalURL from "../BaseUrl";

import { MdOutlineFormatBold } from "react-icons/md";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough } from "react-icons/bs";
import { CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { CiTextAlignCenter } from "react-icons/ci";

const EditExp = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const inputRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log("States", state);

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [desc, setDesc] = useState("");
  const [gem, setGem] = useState("");
  const [ire, setIre] = useState("");
  const experience_id = state.experience_id;
  const team_id = state.team_id;
  const [img2, setImg2] = useState(null);
  const insignia_required = state.insignia_required;
  const [file, setFile] = useState(null);
  const [mediatype, setMediatype] = useState("img");
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const formData2 = new FormData();
  const [editorContent, setEditorContent] = useState("");

  const [previewOfile, setPreviewOfile] = useState(null);

  const editorRef = useRef(null);

  function handleChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);

      setImg(URL.createObjectURL(e.target.files[0]));
      setPreviewOfile(URL.createObjectURL(e.target.files[0]));
      setImg2(e.target.files[0]);

      if (e.target.files[0].name && e.target.files[0].name.includes("mp4")) {
        setMediatype("vids");
      }
    }
  }

  function handleclickinput(e) {
    inputRef.current.click();
  }

  const handleLogState = async () => {
    if (editMode === false) {
      Swal.fire(t("Habilite el modo de edición primero"));
      return;
    }
    formData2.append("title", title);
    formData2.append("experience_id", experience_id);
    formData2.append("description", desc);
    formData2.append("team_id", team_id);
    formData2.append("gems_count", gem);
    formData2.append("insignia_required", ire);
    formData2.append("media", file);
    setSaving(true);
    try {
      const resp = await fetch(`${GlobalURL}/api/dev/miTeam/updateExperience`, {
        method: "POST",
        mode: "cors",
        headers: {
          // Remove 'Content-Type' or set it to 'multipart/form-data'
          // 'Content-Type': 'application/json',
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData2,
      });

      if (resp.ok) {
        Swal.fire(t("¡Actualizado exitosamente los datos!"));
        setSaving(false);
        navigate(-1);
      } else {
        Swal.fire(t("Error del Servidor"));
        setSaving(false);
      }
    } catch (error) {
      Swal.fire(t("Error del Servidor"));
      setSaving(false);
    }
  };

  const deleteExperienceApi = async () => {
    console.log("delete API called: ");
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: t("Estas seguro"),
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("Sí"),
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            setDeleting(true);
            const token = localStorage.getItem("token");
            const response = await fetch(
              `${GlobalURL}/api/dev/app_moderator/deleteExperience`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Cookie: "GCLB=CIjZ8bOcusOyEg", // Include your specific cookie value
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                  experience_id: experience_id,
                }),
              }
            );

            if (response.ok) {
              console.log("¡Eliminó correctamente los datos!");
              setDeleting(false);
              navigate("/experiencepage");
            } else if (response.code === 401) {
              Swal.fire(
                t("El token caduca, por favor vuelva a iniciar sesión")
              );
              navigate("/login");
              setDeleting(false);
            }
          } catch (error) {
            console.error(error);
            setDeleting(false);
          }
          swalWithBootstrapButtons.fire({
            title: t("¡Eliminado!"),
            text: "",
            icon: "success",
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: t("Cancelado"),
            text: "",
            icon: "error",
          });
        }
      });
  };

  useEffect(() => {
    setTitle(state.title);
    setImg(state.experience_media[0].url);
    setDesc(state.Description);
    setGem(state.gems_count);
    setIre(state.insignia_required);
  }, [
    state.Description,
    state.experience_media,
    state.gems_count,
    state.insignia_required,
    state.title,
  ]);

  const handleClose = () => {
    // Close the modal
    setOpen(false);
  };

  const handleBold = () => {
    document.execCommand("bold", false, null);
  };

  const handleItalic = () => {
    document.execCommand("italic", false, null);
  };

  const handleStrikeThrough = () => {
    document.execCommand("strikeThrough", false, null);
  };

  const handleTextAlign = (alignment) => {
    document.execCommand("justify" + alignment, false, null);
  };

  const handleGuardarClick = () => {
    setEditorContent(editorRef.current.innerHTML);
    console.log("editorRef.current: ", editorRef.current.innerHTML);
    setDesc(editorRef.current.innerHTML);
    handleClose();
  };

  const handleDeacriptionClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="editExp-page">
            <Container fluid>
              <div className="exp-col">
                <div className="exp-editPage">
                  <div className="news-header">
                    <div className="back-sec">
                      <img
                        src="./img/volver.png"
                        alt=""
                        onClick={() => {
                          navigate("/experiencepage");
                        }}
                      />
                    </div>
                    <h3 className="header-title">{t("Vivencias Mess Ice")}</h3>
                    <div className="search-icon">
                      <FiSearch style={{ opacity: "0" }} />
                    </div>
                  </div>
                  <div className="editExp-content">
                    <div className="edit-exp-card">
                      <div className="card-heading">
                        {editMode ? (
                          <input
                            className="inpts-edit-exp"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            value={title}
                          />
                        ) : (
                          <h3>{title}</h3>
                        )}
                      </div>

                      <div className="card-img">
                        {!editMode &&
                          (!img.endsWith(".mp4") ? (
                            <img
                              className="imgage-exp"
                              src={img}
                              value={img}
                              alt=""
                            />
                          ) : (
                            <video
                              className="video-grid-view3"
                              src={img}
                              controls
                            ></video>
                          ))}

                        {editMode && (
                          <div
                            className="upload-icon"
                            style={{ flexDirection: "column" }}
                          >
                            {file ? (
                              file.type === "video/mp4" ? (
                                <video
                                  className="video-grid-view3"
                                  src={previewOfile}
                                  controls
                                ></video>
                              ) : (
                                <img
                                  className="imgage-exp"
                                  src={previewOfile}
                                  value={file}
                                  alt=""
                                />
                              )
                            ) : !img.endsWith(".mp4") ? (
                              <img
                                className="imgage-exp"
                                src={img}
                                value={img}
                                alt=""
                              />
                            ) : (
                              <video
                                className="video-grid-view3"
                                src={img}
                                controls
                              ></video>
                            )}

                            <input
                              id="uploadBtn"
                              onChange={handleChange}
                              type="file"
                              ref={inputRef}
                              className="upload"
                              style={{ display: "none" }}
                            />
                            <div
                              className="upload-logo-button"
                              onClick={handleclickinput}
                            >
                              <GrUpload
                                style={{
                                  cursor: "pointer",
                                  padding: "5px",
                                  background: "white",
                                  color: "black",
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "7px",
                                  border: "1px solid #ccc", // Add a border for better visibility
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="edit-exp-data">
                      {editMode ? (
                        <p
                          style={{
                            borderStyle: "solid",
                            padding: "9px",
                            borderWidth: "1px",
                          }}
                          className="desc"
                          dangerouslySetInnerHTML={{ __html: desc }}
                          onClick={() => {
                            handleDeacriptionClick();
                          }}
                        ></p>
                      ) : (
                        // <textarea
                        //   className="textarea-edit-exp"
                        //   typeof="text"
                        //   onChange={(e) => {
                        //     setDesc(e.target.value);
                        //   }}
                        //   value={desc}
                        // />
                        <p
                          className="desc"
                          dangerouslySetInnerHTML={{ __html: desc }}
                        ></p>
                      )}
                      <div className="req-sec">
                        <h4 className="req-heading">{t("REQUERIMIENTOS")}</h4>
                        <ul className="req-list">
                          <li>
                            {editMode ? (
                              <input
                                className="inpts-edit-exp"
                                value={gem}
                                onChange={(e) => {
                                  setGem(e.target.value);
                                }}
                                type="number"
                              />
                            ) : (
                              <p value={gem}>
                                <img src="./img/yellowdiamond.png" alt="" />
                                {gem}
                              </p>
                            )}
                          </li>
                          <li>
                            {editMode ? (
                              <input
                                className="inpts-edit-exp"
                                value={ire}
                                onChange={(e) => {
                                  setIre(e.target.value);
                                }}
                              />
                            ) : (
                              <p value={ire}>{ire} </p>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="space-between-btn">
                    <button
                      className="blue-btn"
                      onClick={() => {
                        setEditMode(!editMode);
                      }}
                    >
                      {editMode ? t("CANCELAR") : t("EDITAR")}
                    </button>
                    <button className="green-btn" onClick={handleLogState}>
                      {" "}
                      {saving ? (
                        <div
                          className=""
                          style={{
                            height: "23px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="loading-adjust">
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className="my-5">
                                {" "}
                                &nbsp;{t("Ahorro...")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        t("GUARDAR")
                      )}{" "}
                    </button>
                    <button
                      className="red-btn"
                      onClick={() => {
                        deleteExperienceApi();
                      }}
                      disabled={deleting}
                    >
                      {deleting ? (
                        <div
                          className=""
                          style={{
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="loading-adjust">
                            <div className="Loader d-flex justify-content-center align-items-center">
                              <div
                                className="spinner-border spinner-border-sm my-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                              <div className="my-5">
                                {" "}
                                &nbsp; {t("Eliminando...")}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        t("ELIMINAR VIVENCIA")
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <Modal
                show={open}
                className="institutional-popup common-popup"
                onHide={handleClose}
                centered
              >
                <Modal.Header className="pb-0 mb-0" closeButton>
                  <Modal.Title>{t("Editar")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="custom-text-editor w-100">
                    <div className="toolbar">
                      <button onClick={handleBold}>
                        <MdOutlineFormatBold />
                      </button>
                      <button onClick={handleItalic}>
                        <BiItalic />
                      </button>
                      <button onClick={handleStrikeThrough}>
                        <BsTypeStrikethrough />
                      </button>
                      <div className="text-align-buttons">
                        <button onClick={() => handleTextAlign("Left")}>
                          <CiTextAlignLeft />
                        </button>
                        <button onClick={() => handleTextAlign("Center")}>
                          <CiTextAlignCenter />
                        </button>
                        <button onClick={() => handleTextAlign("Right")}>
                          <CiTextAlignRight />
                        </button>
                      </div>
                    </div>
                    <div
                      className="editor"
                      ref={editorRef}
                      contentEditable
                      dangerouslySetInnerHTML={{ __html: desc }}
                    ></div>
                  </div>
                </Modal.Body>

                <Modal.Footer className="border-0 p-0">
                  <div className="news-btn-sec">
                    <button
                      className="green-btn"
                      disabled={saving}
                      onClick={handleGuardarClick}
                    >
                      {t("GUARDAR")}
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditExp;
