import React, { useEffect } from 'react';
import "./PrivacyPolicy.css";


function PrivacyPolicy() {

  const fetchTandC = ()=>{

  }

  useEffect(() => {
    fetchTandC();  
  }, [])
  

  return (
    <>
      <div className='cookie-policy' id='ppi'>
        <div className='cookie-policy-inner'>
          <h1 className='title-two'>
            Términos y condiciones
          </h1>
          <h3 className='subtitle'>TÉRMINOS Y CONDICIONES DE USO DE LA APLICACIÓN MESS_ICE</h3>
          <div className='cookie-policy-content'>
            <p>En cumplimiento con las leyes que regulan los servicios de la sociedad de la información y el comercio electrónico, MESS_ICE desea ofrecerles toda la información necesaria para que puedan utilizar nuestra página web y aplicación móvil con total confianza y tranquilidad. Nuestra empresa se dedica a ofrecer una experiencia segura y de calidad en la prestación de servicios, y cumplimos con todas las obligaciones establecidas por las leyes en cuanto a la protección de datos personales, la neutralidad de la red, la privacidad y la seguridad de la información. Como usuarios de nuestros servicios, es importante que conozcan sus derechos y obligaciones, así como las políticas y términos que regulan la utilización de nuestra página web y aplicación móvil. Por esta razón, MESS_ICE les proporciona toda la información necesaria en nuestros avisos legales, incluyendo los datos identificativos de la empresa, información sobre los precios y las condiciones de uso de los servicios, y una dirección de correo electrónico de contacto.</p>
            <p>En MESS_ICE estamos comprometidos en ofrecerles la mejor atención y servicio posible. Por esta razón, en cumplimiento con las leyes, ponemos a su disposición mecanismos de resolución de conflictos y garantizamos la protección de sus datos personales.</p>
            <p>En resumen, al hacer uso de los servicios de MESS_ICE, acepta y da por hecho que ha leído y comprendido nuestros avisos legales, y está de acuerdo con las políticas y términos establecidos en los mismos. Les invitamos a leer detenidamente nuestros avisos legales para que estén informados de todas nuestras políticas y términos, y para que puedan disfrutar de nuestros servicios con total confianza y seguridad.</p>
            <div className='quetion-sec'>
              <p>&nbsp;&nbsp;  &nbsp; OBJETO</p>
              <p>&nbsp;&nbsp;  &nbsp; Este documento describe los términos y condiciones generales de uso de la aplicación móvil en adelante MESS_ICE.</p>
            </div>

            <div className='quetion-sec'>
              <p>&nbsp;&nbsp;  &nbsp; ACEPTACIÓN DE LOS TÉRMINOS</p>
              <p>&nbsp;&nbsp;  &nbsp; El ingreso y utilización de la aplicación MESS_ICE implica que usted ha leído, entendido y aceptado los presentes Términos y Condiciones de Uso. Y esta aplicación requerirá la autorización empresa de los usuarios para acceder a las funciones como Calendario, Cámara, Teléfono, SMS y GPS para brindar atención y seguimiento a los trámites y servicios solicitados en alguno de los módulos de la aplicación. El Usuario se compromete a utilizar de manera diligente, correcta y legal los contenidos ofrecidos en la aplicación móvil MESS_ICE (Contenidos). En concreto, se compromete a no utilizar los Contenidos de manera contraria a la ley, la moral, las buenas costumbres o el orden público, y se abstendrá de reproducir, copiar, distribuir, permitir acceso al público, transformar o modificar los Contenidos sin contar con la autorización del titular de los derechos correspondientes o si no está legalmente permitido.</p><br/>
              <p>Además, el Usuario se convierte en tal al usar la aplicación móvil MESS_ICE y, por lo tanto, acepta plenamente todas las condiciones que se establecen en este Aviso Legal. También se obliga a cumplir con las políticas y términos establecidos en la página web de MESS_ICE al utilizarla. De esta manera, el Usuario se compromete a utilizar los Contenidos de manera lícita y a cumplir con las condiciones establecidas para su uso, con el fin de garantizar el correcto funcionamiento de la aplicación móvil y la protección de los derechos de propiedad intelectual y de autor.</p>
            </div>
            <div className='quetion-sec'>
              <p>&nbsp;&nbsp;  &nbsp; RESPONSABILIDAD SOBRE EL USO DE LA APLICACIÓN MESS_ICE</p>
              <p>&nbsp;&nbsp;  &nbsp; Usted asume la responsabilidad de todas las acciones que ejecute, en la utilización y consulta de la MESS_ICE, mismas que se considera que usted realiza voluntariamente. Usted no utilizará, ni permitirá que otros usuarios utilicen esta aplicación móvil o cualquier servicio prestado a través de ella, de forma contraria a lo establecido en estos “Términos y Condiciones de Uso”, así como a las disposiciones legales aplicables a los servicios que ofrece la aplicación MESS_ICE. Es responsabilidad de usted todo acto que se realice en esta aplicación móvil, por medio de su “Nombre de Usuario” y “Contraseña”; asimismo, usted se responsabiliza del cuidado y custodia de los mismos, para que esta aplicación móvil únicamente sea utilizada para los fines para los cuales ha sido diseñada, por lo que cualquier mal uso que se realice en su nombre, se presumirá realizada por usted. Usted reconoce y acepta que la empresa MESS_ICE queda liberada de toda responsabilidad por el mal uso que llegara a realizarse en alguno de los módulos de la MESS_ICE.</p>
            </div>
            <div className='quetion-sec'>
              <p>Condiciones de uso de la APP</p>
              <p>El usuario que utilice la aplicación oficial de MESS_ICE se compromete a hacerlo de acuerdo con las leyes, los términos y condiciones establecidos, así como cualquier otra instrucción proporcionada, junto con las normas éticas y morales y el orden público en general. Se espera que el usuario utilice los contenidos con diligencia, corrección y legalidad, y se abstenga de (a) utilizar los contenidos de manera contraria a la ley, la moral o las buenas costumbres generalmente aceptadas o el orden público; (b) reproducir, copiar, distribuir, permitir el acceso público, transformar o modificar los contenidos sin la autorización del titular de los derechos correspondientes o en caso de que sea legalmente permitido; (c) eliminar, evitar o manipular los derechos de autor y otros datos identificativos. Cualquier forma de publicidad o información comercial directa o encubierta, así como el envío de correos masivos (spamming), está prohibida sin la debida autorización. El usuario se compromete a no introducir programas, virus, macros, applets, controles ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres que puedan causar o sean susceptibles de causar alteraciones en los sistemas informáticos de MESS_ICE y/o terceros. Para acceder a la aplicación oficial de MESS_ICE, se requiere que el usuario tenga al menos catorce (14) años de edad. Al aceptar los términos y condiciones, el usuario garantiza ser mayor de catorce años. MESS_ICE puede contactar al usuario en cualquier momento para verificar su identidad. Para verificar la identidad, el usuario debe proporcionar una copia de su Documento Nacional de Identidad o un documento de identificación equivalente. Si el usuario no proporciona esta documentación, MESS_ICE puede bloquear su acceso a la aplicación oficial.</p>
            </div>
            <div className='quetion-sec'>
              <p>POLÍTICA DE PRIVACIDAD:</p>
              <p>La privacidad y seguridad de nuestros usuarios es muy importante para nosotros en MESS_ICE. En esta política de privacidad se describe qué información recopilamos, cómo la utilizamos y compartimos, y cómo protegemos tu privacidad al utilizar nuestra aplicación.</p>
            </div>
            <div className='quetion-sec'>
              <p>Información que recopilamos:</p>
              <p>Recopilamos información personal cuando te registras en nuestra aplicación, como tu nombre, dirección de correo electrónico y número de teléfono. También podemos recopilar información sobre tu ubicación, dispositivo y actividad de la aplicación. Además, podemos recopilar información sobre tu interacción con la aplicación, incluyendo la opinión que expresan a través de botones como “Me gusta” o “No me gusta”.</p>
            </div>
            <div className='quetion-sec'>
              <p>Google:</p>
              <p>● Información de perfil público, como el nombre y la foto de perfil.</p>
              <p>● Información de contacto, como la dirección de correo electrónico y el número de teléfono.</p>
              <p>● Información de actividad, como la actividad de búsqueda y los sitios web visitados.</p>
              <p>● Información de dispositivo, como el modelo del dispositivo, el sistema operativo y la dirección IP.</p>
            </div>
            <div className='quetion-sec'>
              <p>Apple:</p>
              <p>● Información de perfil público, como el nombre y la foto de perfil.</p>
              <p>● Información de contacto, como la dirección de correo electrónico y el número de teléfono.</p>
              <p>● Información de actividad, como la actividad de búsqueda y los sitios web visitados.</p>
              <p>● Información de dispositivo, como el modelo del dispositivo, el sistema operativo y la dirección IP.</p>
            </div>
            <div className='quetion-sec'>
              <p>Facebook:</p>
              <p>● Información de perfil público, como el nombre y la foto de perfil.</p>
              <p>● Información de contacto, como la dirección de correo electrónico y el número de teléfono.</p>
              <p>● Información de actividad, como la actividad de búsqueda y los sitios web visitados.</p>
              <p>● Información de dispositivo, como el modelo del dispositivo, el sistema operativo y la dirección IP.</p>
              <p>Utilizamos la información que recopilamos para brindarte una experiencia personalizada y mejorar la calidad de nuestra aplicación. La información obtenida a través de la interacción de los usuarios con botones de opinión se utiliza para</p>
            </div>
            {/* <div className='quetion-sec'>
              <p>los siguientes fines:</p>
              <p>Para mejorar la calidad del contenido y la experiencia de usuario en la aplicación.</p>
              <p>Para personalizar el contenido y las recomendaciones de la aplicación de acuerdo con las preferencias de los usuarios.</p>
              <p>Para analizar el comportamiento y las preferencias de los usuarios y mejorar la calidad de los servicios ofrecidos.</p>
            </div> */}
            <div className='quetion-sec'>
              <p>  &nbsp; Cómo compartimos la información:</p>
              <p>  &nbsp; No compartimos la información personal de nuestros usuarios con terceros sin su</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; consentimiento, excepto en los siguientes casos:</p>
              <p>  &nbsp; Cuando sea necesario para prestar un servicio solicitado por el usuario.</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; Cuando la información deba ser compartida con proveedores de servicios externos que trabajen en nuestro nombre para proporcionar y mejorar nuestros servicios.</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; Cuando sea requerido por la ley o en respuesta a una orden judicial, solicitud gubernamental u otra disposición legal.</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; Cuando consideremos que la divulgación es necesaria o apropiada para proteger los derechos, propiedad o seguridad de MESS_ICE, nuestros usuarios y otros.</p>
            </div>

            <div className='quetion-sec'>
              <p>  &nbsp; Cómo protegemos la información:</p>
              <p>  &nbsp; Tomamos medidas de seguridad razonables para proteger la información personal que recopilamos y almacenamos. Sin embargo, no podemos garantizar la seguridad absoluta de la información. Si bien trabajamos arduamente para proteger la privacidad de nuestros usuarios, la transmisión de información a través de Internet no es completamente segura.</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; Cambios en nuestra política de privacidad:</p>
              <p>  &nbsp; Podemos actualizar esta política de privacidad de vez en cuando. Si realizamos cambios significativos, te notificaremos enviando una notificación a la dirección de correo electrónico asociada con tu cuenta o publicando una notificación en nuestra aplicación antes de que los cambios entren en vigencia</p>
            </div>
            <div className='quetion-sec'>
              <p>  &nbsp; Cómo contactarnos:</p>
              <p>  &nbsp; Si tienes alguna pregunta o preocupación sobre nuestra política de privacidad, puedes contactarnos en cualquier momento a través de la sección de soporte de nuestra aplicación. Es importante que esta política sea revisada y actualizada regularmente para asegurar que esté en conformidad con las leyes y regulaciones aplicables, así como para reflejar los cambios en las prácticas de recopilación, uso y divulgación de datos.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy