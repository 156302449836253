import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "./VerificationReq.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { FiSearch } from "react-icons/fi";

export const data = [
  ["Subscription", "Users"],
  ["Diamond", 14],
  ["Silver", 38],
  ["Gold", 58],
];
export const options = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  backgroundColor: "transparent",
  titleTextStyle: {
    color: "#FEF6F2",
  },
};

const DigitalSecTwo = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [verification, setverification] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState("");
  const [searchActive, setSearchActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [dummyData, setDummyData] = useState([]);
  const navigate = useNavigate();

  const handleToggleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  function formatDate(date) {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  }
  async function declineRequest(id) {
    setLoading(true);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/declineVerificationRequest`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          request_id: id,
        }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire("Error del Servidor");
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire("Error del Servidor");
    }
  }
  async function verifyUnverify(id) {
    const apiUrl = `${GlobalURL}api/dev/app_moderator/verifyUser`;
    // const apiUrl = 'https://mess-ice-app.uc.r.appspot.com/api/dev/app_moderator/verifyUser';
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          user_id: id,
        }),
      });
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (!response.ok) {
        Swal.fire(t("Usuario verificado"));
      }
      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data.message);
        Swal.fire(t("Usuario verificado"));
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  async function getVerificationList() {
    setLoading(true);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerificationRequest`;
    // const apiUrl = 'https://mess-ice-app.uc.r.appspot.com/api/dev/app_moderator/getAllVerificationRequest';
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.data == null) {
          setverification([]);
          setOriginalData([]);
          return;
        }
        setverification(responseData.data);
        setOriginalData(response.data);
        console.log("Verification req List", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  async function acceptRequest(id) {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/acceptVerifcationRequest`;
    // const apiUrl = 'https://mess-ice-app.uc.r.appspot.com/api/dev/app_moderator/acceptVerifcationRequest';
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({
          user_id: id,
        }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (response.ok) {
        Swal.fire(t("Usuario verificado"));
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  async function getVerficationSeach() {
    setLoading(true);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerificationRequest`;
    // const apiUrl = 'https://mess-ice-app.uc.r.appspot.com/api/dev/app_moderator/getAllVerificationRequest';
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: searchTerm }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.data == null || responseData.data == []) {
          return;
        }
        setDummyData(responseData.data);
        console.log("Verification req List", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  // const handleSearch = (e) => {
  //     const dataTemp = originalData;
  //     console.log('originalData: ', originalData)
  //     setSearchTerm(e.target.value);
  //     console.log('Search Term: ', e.target.value);
  //     if (e.target.value === '') {
  //         setverification(originalData);
  //         return
  //     }
  //     const result = dataTemp.filter((item) =>
  //         item.title.toLowerCase().includes(e.target.value)
  //     );
  //     console.log('result: ', result)
  //     if (result.length === 0) {
  //         console.log('Empty Arry executed:  ')
  //         return
  //     }
  //     setverification(result);
  // }
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (!e.target.value) {
      getVerificationList();
    }
  };
  useEffect(() => {
    getVerificationList();
  }, [loading]);
  useEffect(() => {
    getVerificationList();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="search-icon-in managers" style={{ display: "flex" }}>
          {searchActive && (
            <div className="searchBarInput">
              <input
                className="search-tag-bar"
                placeholder={t("Buscar")}
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e);
                }}
              />
            </div>
          )}
          <FiSearch
            className="search-icon-click"
            onClick={getVerficationSeach}
          />
        </div>
        <div className="dashboard-container">
          <div className="Digital-sec">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col xs lg="16">
                  {dummyData.length > 0 ? (
                    <>
                      {dummyData.length > 0 ? (
                        dummyData.map((item, index) => (
                          <>
                            <div className="todas-card-main">
                              <div
                                className={`todas-card-list ${
                                  index === activeIndex ? "active" : ""
                                }`}
                              >
                                <div className="todas-card todas-three-item mb-0">
                                  <div
                                    className="left-sec"
                                    onClick={() => handleToggleClick(index)}
                                  >
                                    <div className="profile-img">
                                      <img src={item.user?.image_url} alt="" />
                                    </div>
                                    <div className="profile-content">
                                      <h5>
                                        {item.user?.first_name}{" "}
                                        {item.user?.last_name}
                                      </h5>
                                      <p>{item.user?.username}</p>
                                    </div>
                                  </div>
                                  <div
                                    className="mid-sec"
                                    onClick={() => handleToggleClick(index)}
                                  >
                                    <span className="todas-img-mid">
                                      <img
                                        src="./img/details.png"
                                        style={{
                                          opacity: "0.5",
                                          cursor: "pointer",
                                        }}
                                        alt=""
                                      />
                                      {/* {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />} */}
                                    </span>
                                  </div>

                                  <div className="right-sec">
                                    <span className="todas-img right-img">
                                      {!(index === activeIndex) && (
                                        <img
                                          src="./img/delete2.png"
                                          alt=""
                                          onClick={() => {
                                            declineRequest(item?.user_id);
                                          }}
                                        />
                                      )}
                                      {!(index === activeIndex) && (
                                        <img
                                          src="./img/accept.png"
                                          alt=""
                                          onClick={() => {
                                            acceptRequest(item?.user_id);
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className={`todas-card-content`}>
                                  <div className="tc-Maintitle">
                                    {/* <p className='mb-0'>
                                                                        Solicitud de verificación de Dirigente Digital
                                                                    </p> */}
                                  </div>
                                  <div className="tc-Semititle">
                                    <p className="mb-0">
                                      {t(
                                        "SOLICITUD DE VERIFICACIÓN DE DIRIGENTE DIGITAL"
                                      )}
                                    </p>
                                  </div>
                                  <ul className="verification-requirements p-0 m-0">
                                    <li>
                                      {t("cumple los siguientes requisitos:")}
                                    </li>
                                    <li>
                                      {item?.follower_count}{" "}
                                      {t("Dirigentes Digitales seguidores")}
                                    </li>
                                    <li>{t("50 posteos")}</li>
                                    <li>{t("30 imágenes compartidas")}</li>

                                    <li>-</li>
                                    <li>{t("3 referidos con membresía")}</li>
                                  </ul>

                                  <div className="tc-footer">
                                    <div
                                      className="tc-profile"
                                      onClick={() => {
                                        navigate("/leaderprofile", {
                                          state: item,
                                        });
                                      }}
                                    >
                                      <p>{t("Perfil")}</p>
                                      <img src="./img/userprofile.png" alt="" />
                                    </div>
                                    <div className="tc-conditions">
                                      <div className="todas-img">
                                        <p>{t("Rechazar")}</p>{" "}
                                        <img
                                          src="./img/delete2.png"
                                          onClick={() => {
                                            declineRequest(item?.user_id);
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="todas-img right-img">
                                        <div className="todas-img">
                                          <p>{t("Eliminar")}</p>{" "}
                                          <img
                                            src="./img/accept.png"
                                            onClick={() => {
                                              acceptRequest(item?.user_id);
                                            }}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <h5 style={{ fontSize: "18px", marginTop: "30px" }}>
                          {t(
                            "No hay ninguna solicitud pendiente de verificación"
                          )}
                        </h5>
                      )}
                    </>
                  ) : (
                    <>
                      {verification.length > 1 ? (
                        verification.map((item, index) => (
                          <>
                            <div className="todas-card-main">
                              <div
                                className={`todas-card-list ${
                                  index === activeIndex ? "active" : ""
                                }`}
                              >
                                <div className="todas-card todas-three-item mb-0">
                                  <div
                                    className="left-sec"
                                    onClick={() => handleToggleClick(index)}
                                  >
                                    <div className="profile-img">
                                      <img src={item.user?.image_url} alt="" />
                                    </div>
                                    <div className="profile-content">
                                      <h5>
                                        {item.user?.first_name}{" "}
                                        {item.user?.last_name}
                                      </h5>
                                      <p>{item.user?.username}</p>
                                    </div>
                                  </div>
                                  <div
                                    className="mid-sec"
                                    onClick={() => handleToggleClick(index)}
                                  >
                                    <span className="todas-img-mid">
                                      <img
                                        src="./img/details.png"
                                        style={{
                                          opacity: "0.5",
                                          cursor: "pointer",
                                        }}
                                        alt=""
                                      />
                                      {/* {item.is_blocked && item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : item.is_deleted ? <img style={{ 'opacity': '0.5' }} src="./img/delete2.png" alt="" /> : <img style={{ 'opacity': '0.5' }} src="./img/ban.png" alt="" />} */}
                                    </span>
                                  </div>

                                  <div className="right-sec">
                                    <span className="todas-img right-img">
                                      {!(index === activeIndex) && (
                                        <img
                                          src="./img/delete2.png"
                                          alt=""
                                          onClick={() => {
                                            declineRequest(item?.user_id);
                                          }}
                                        />
                                      )}
                                      {!(index === activeIndex) && (
                                        <img
                                          src="./img/accept.png"
                                          alt=""
                                          onClick={() => {
                                            acceptRequest(item?.user_id);
                                          }}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className={`todas-card-content`}>
                                  <div className="tc-Maintitle">
                                    {/* <p className='mb-0'>
                                                                        Solicitud de verificación de Dirigente Digital
                                                                    </p> */}
                                  </div>
                                  <div className="tc-Semititle">
                                    <p className="mb-0">
                                      {t(
                                        "SOLICITUD DE VERIFICACIÓN DE DIRIGENTE DIGITAL"
                                      )}
                                    </p>
                                  </div>
                                  <ul className="verification-requirements p-0 m-0">
                                    <li>
                                      {t("cumple los siguientes requisitos:")}
                                    </li>
                                    <li>
                                      {item?.follower_count}{" "}
                                      {t("Dirigentes Digitales seguidores")}
                                    </li>
                                    <li>{t("50 posteos")}</li>
                                    <li>{t("30 imágenes compartidas")}</li>

                                    <li>-</li>
                                    <li>{t("3 referidos con membresía")}</li>
                                  </ul>

                                  <div className="tc-footer">
                                    <div
                                      className="tc-profile"
                                      onClick={() => {
                                        navigate("/leaderprofile", {
                                          state: item,
                                        });
                                      }}
                                    >
                                      <p>{t("Perfil")}</p>
                                      <img src="./img/userprofile.png" alt="" />
                                    </div>
                                    <div className="tc-conditions">
                                      <div className="todas-img">
                                        <p>{t("Rechazar")}</p>{" "}
                                        <img
                                          src="./img/delete2.png"
                                          onClick={() => {
                                            declineRequest(item?.user_id);
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="todas-img right-img">
                                        <div className="todas-img">
                                          <p>{t("Eliminar")}</p>{" "}
                                          <img
                                            src="./img/accept.png"
                                            onClick={() => {
                                              acceptRequest(item?.user_id);
                                            }}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <h5 style={{ fontSize: "18px", marginTop: "30px" }}>
                          {t(
                            "No hay ninguna solicitud pendiente de verificación"
                          )}
                        </h5>
                      )}
                    </>
                  )}

                  {/* verfication  */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalSecTwo;
