import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { BsPlusLg } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { useRef } from 'react';
import './addproduct.css';
import { useEffect } from 'react';
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import axios from 'axios';
import GlobalURL from '../../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';

function Addproduct() {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();

    const [productData, setProductData] = useState({
        name: '',
        description: '',
        price: '', //quater
        baseCurrency: 'USD', // qauter
        quantity: 100, // quater
        brand: '', // half
        category: '', // half
        gender: '', // quater
        seller_name: '', // half
        quantities: [{
            colour: '',
            size: [],
            quantity: [],
        },
        {
            colour: '',
            size: [],
            quantity: [],
        },
        {
            colour: '',
            size: [],
            quantity: [],
        },
        {
            colour: '',
            size: [],
            quantity: [],
        },
        {
            colour: '',
            size: [],
            quantity: [],
        }],
    });

    const [mediatype,] = useState("img");


    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    const [imagesFiles, setImagesFiles] = useState([]);
    const [imagesFiles2, setImagesFiles2] = useState([]);
    const [imagesFiles3, setImagesFiles3] = useState([]);
    const [imagesFiles4, setImagesFiles4] = useState([]);
    const [imagesFiles5, setImagesFiles5] = useState([]);

    const [previewImg, setPreviewImg] = useState([]);
    const [previewImg2, setPreviewImg2] = useState([]);
    const [previewImg3, setPreviewImg3] = useState([]);
    const [previewImg4, setPreviewImg4] = useState([]);
    const [previewImg5, setPreviewImg5] = useState([]);

    const [isactive, setIsactive] = useState(true);
    const [isactive2, setIsactive2] = useState(false);
    const [isactive3, setIsactive3] = useState(false);
    const [isactive4, setIsactive4] = useState(false);
    const [isactive5, setIsactive5] = useState(false);

    const [size1, setSize1] = useState('');
    const [size2, setSize2] = useState('');
    const [size3, setSize3] = useState('');
    const [size4, setSize4] = useState('');
    const [size5, setSize5] = useState('');

    // const [size , setSize] = useState([]);

    const handlePageClick = (page) => {
        // Reset all states
        setIsactive(false);
        setIsactive2(false);
        setIsactive3(false);
        setIsactive4(false);
        setIsactive5(false);

        // Set the active state based on the clicked page
        switch (page) {
            case 1:
                setIsactive(true);
                break;
            case 2:
                setIsactive2(true);
                break;
            case 3:
                setIsactive3(true);
                break;
            case 4:
                setIsactive4(true);
                break;
            case 5:
                setIsactive5(true);
                break;
            default:
                break;
        }
    };
    const handlePrevClick = () => {
        // Determine the current active page
        const currentPage = [isactive, isactive2, isactive3, isactive4, isactive5].indexOf(true) + 1;

        // Handle Prev logic
        if (currentPage > 1) {
            handlePageClick(currentPage - 1);
        }
    };
    const handleNextClick = () => {
        // Determine the current active page
        const currentPage = [isactive, isactive2, isactive3, isactive4, isactive5].indexOf(true) + 1;

        // Handle Next logic
        if (currentPage < 5) {
            handlePageClick(currentPage + 1);
        }
    };
    const handleGenderChange = (event) => {
        const { name, value } = event.target;
        setProductData((prevProductData) => ({
            ...prevProductData,
            [name]: value,
        }));
    };

    // Normal Funtions
    const getCategory = async () => {
        try {
            const resp = await fetch(`${GlobalURL}/api/dev/store/getAllProductCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `Bearer${localStorage.getItem('token')}`,
                },
            })
            const data = await resp.json();
            // const calcetinesCategory = data.find(category => category.category_name === "category_name");
            setCategories(data.data);
            if (resp.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
            }

        } catch (error) {
            console.log("error in Getting Category", error)
        }
    }
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);

        // Update setProductData.category with the selected category ID
        setProductData((prevData) => ({
            ...prevData,
            category: e.target.value,
        }));
    };
    // Normal Funtion end



    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);

    //  Click Input 
    function handleclickinput(e) {
        inputRef.current.click();
    }
    function handleclickinput2(e) {
        inputRef2.current.click();
    }
    function handleclickinput3(e) {
        inputRef3.current.click();
    }
    function handleclickinput4(e) {
        inputRef4.current.click();
    }
    function handleclickinput5(e) {
        inputRef5.current.click();
    }
    // Input Changes 
    function handleChange(e) {
        const selectedFiles = e.target.files;
        setImagesFiles([...e.target.files])
        console.log('Files ', selectedFiles);

        // Create URLs for each selected file
        let newImages = Array.from(selectedFiles).forEach((file) => {
            setPreviewImg((previewImg) => ([...previewImg, URL.createObjectURL(file)]));
        }
        );
        const newImages2 = Array.from(selectedFiles);
        console.log("newImages3", newImages);


    }
    function handleChange2(e) {
        const selectedFiles = e.target.files;
        setImagesFiles2([...e.target.files])
        console.log('Files ', selectedFiles);

        // Create URLs for each selected file
        let newImages = Array.from(selectedFiles).forEach((file) => {
            setPreviewImg2((previewImg2) => ([...previewImg2, URL.createObjectURL(file)]));
        }
        );
        const newImages2 = Array.from(selectedFiles);
        console.log("newImages3", newImages2);
    }
    function handleChange3(e) {
        const selectedFiles = e.target.files;
        setImagesFiles3([...e.target.files])
        console.log('Files ', selectedFiles);

        // Create URLs for each selected file
        let newImages = Array.from(selectedFiles).forEach((file) => {
            setPreviewImg3((previewImg3) => ([...previewImg3, URL.createObjectURL(file)]));
        }
        );
        const newImages2 = Array.from(selectedFiles);
        console.log("newImages3", newImages2);
    }
    function handleChange4(e) {
        const selectedFiles = e.target.files;
        setImagesFiles4([...e.target.files])
        console.log('Files ', selectedFiles);

        // Create URLs for each selected file
        let newImages = Array.from(selectedFiles).forEach((file) => {
            setPreviewImg4((previewImg4) => ([...previewImg4, URL.createObjectURL(file)]));
        }
        );
        const newImages2 = Array.from(selectedFiles);
        console.log("newImages3", newImages2);
    }
    function handleChange5(e) {
        const selectedFiles = e.target.files;
        setImagesFiles5([...e.target.files])
        console.log('Files ', selectedFiles);

        // Create URLs for each selected file
        let newImages = Array.from(selectedFiles).forEach((file) => {
            setPreviewImg5((previewImg5) => ([...previewImg5, URL.createObjectURL(file)]));
        }
        );
        const newImages2 = Array.from(selectedFiles);
        console.log("newImages3", newImages2);
    }


    // Delete Changed files
    const handleDeleteImageFile = (index) => {
        // Create shallow copies and remove the item at the specified index
        const newPreviewImg = [...previewImg];
        const newSelectedFiles = [...imagesFiles];

        newPreviewImg.splice(index, 1);
        newSelectedFiles.splice(index, 1);

        // Update the state using destructuring
        setPreviewImg([...newPreviewImg]);
        setImagesFiles([...newSelectedFiles]);
    };
    const handleDeleteImageFile2 = (index) => {
        // Create shallow copies and remove the item at the specified index
        const newPreviewImg2 = [...previewImg2];
        const newSelectedFiles2 = [...imagesFiles2];

        newPreviewImg2.splice(index, 1);
        newSelectedFiles2.splice(index, 1);

        // Update the state using destructuring
        setPreviewImg2([...newPreviewImg2]);
        setImagesFiles2([...newSelectedFiles2]);
    };
    const handleDeleteImageFile3 = (index) => {
        // Create shallow copies and remove the item at the specified index
        const newPreviewImg3 = [...previewImg3];
        const newSelectedFiles3 = [...imagesFiles3];

        newPreviewImg3.splice(index, 1);
        newSelectedFiles3.splice(index, 1);

        // Update the state using destructuring
        setPreviewImg3([...newPreviewImg3]);
        setImagesFiles3([...newSelectedFiles3]);
    };
    const handleDeleteImageFile4 = (index) => {
        // Create shallow copies and remove the item at the specified index
        const newPreviewImg4 = [...previewImg4];
        const newSelectedFiles4 = [...imagesFiles4];

        newPreviewImg4.splice(index, 1);
        newSelectedFiles4.splice(index, 1);

        // Update the state using destructuring
        setPreviewImg4([...newPreviewImg4]);
        setImagesFiles4([...newSelectedFiles4]);
    };
    const handleDeleteImageFile5 = (index) => {
        // Create shallow copies and remove the item at the specified index
        const newPreviewImg5 = [...previewImg5];
        const newSelectedFiles5 = [...imagesFiles5];

        newPreviewImg5.splice(index, 1);
        newSelectedFiles5.splice(index, 1);

        // Update the state using destructuring
        setPreviewImg5([...newPreviewImg5]);
        setImagesFiles5([...newSelectedFiles5]);
    };


    // handle Divs
    const divs = [];
    const divs2 = [];
    const divs3 = [];
    const divs4 = [];
    const divs5 = [];

    for (let i = 0; i < 5; i++) {
        divs.push(
            <div className='price-quant-pair' key={i}>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>Tamaño {i + 1}</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.quantities[0].size[i]} // changes here productData.size

                            onBlur={(e) => {
                                const newSize = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: [
                                        {
                                            ...prevData.quantities[0],
                                            size: [...prevData.quantities[0].size, newSize], // Insert the new size value into the existing array
                                        },
                                        ...prevData.quantities.slice(1), // Keep the rest of the quantities unchanged
                                    ],
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>cuantitativo</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.size1Quantity}
                            onBlur={(e) => {
                                const newQuantity = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: [
                                        {
                                            ...prevData.quantities[0],
                                            quantity: [...prevData.quantities[0].quantity, newQuantity], // Insert the new quantity value into the existing array
                                        },
                                        ...prevData.quantities.slice(1), // Keep the rest of the quantities unchanged
                                    ],
                                }));
                            }}


                        />
                    </div>
                </div>
            </div>
        );
    }
    for (let i = 0; i < 5; i++) {
        divs2.push(
            <div className='price-quant-pair' key={i}>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>Tamaño {i + 1}</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.quantities[0].size[i]} // changes here productData.size

                            onBlur={(e) => {
                                const newSize = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 1
                                            ? {
                                                ...quantity,
                                                size: [...quantity.size, newSize], // Insert the new size value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>cuantitativo</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.size1Quantity}
                            onBlur={(e) => {
                                const newQuantity = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 1
                                            ? {
                                                ...quantity,
                                                quantity: [...quantity.quantity, newQuantity], // Insert the new quantity value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}


                        />
                    </div>
                </div>
            </div>
        );
    }
    for (let i = 0; i < 5; i++) {
        divs3.push(
            <div className='price-quant-pair' key={i}>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>Tamaño {i + 1}</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.quantities[0].size[i]} // changes here productData.size

                            onBlur={(e) => {
                                const newSize = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 2
                                            ? {
                                                ...quantity,
                                                size: [...quantity.size, newSize], // Insert the new size value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>cuantitativo</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.size1Quantity}
                            onBlur={(e) => {
                                const newQuantity = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 2
                                            ? {
                                                ...quantity,
                                                quantity: [...quantity.quantity, newQuantity], // Insert the new quantity value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}


                        />
                    </div>
                </div>
            </div>
        );
    }
    for (let i = 0; i < 5; i++) {
        divs4.push(
            <div className='price-quant-pair' key={i}>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>Tamaño {i + 1}</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.quantities[0].size[i]} // changes here productData.size

                            onBlur={(e) => {
                                const newSize = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 3
                                            ? {
                                                ...quantity,
                                                size: [...quantity.size, newSize], // Insert the new size value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>cuantitativo</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.size1Quantity}
                            onBlur={(e) => {
                                const newQuantity = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 3
                                            ? {
                                                ...quantity,
                                                quantity: [...quantity.quantity, newQuantity], // Insert the new quantity value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}


                        />
                    </div>
                </div>
            </div>
        );
    }
    for (let i = 0; i < 5; i++) {
        divs5.push(
            <div className='price-quant-pair' key={i}>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>Tamaño {i + 1}</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.quantities[0].size[i]} // changes here productData.size

                            onBlur={(e) => {
                                const newSize = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 4
                                            ? {
                                                ...quantity,
                                                size: [...quantity.size, newSize], // Insert the new size value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}
                        />
                    </div>
                </div>
                <div className='common-form-quater-control img-video-form-control'>
                    <label>cuantitativo</label>
                    <div className='file-input-sec'>
                        <input
                            id="uploadFile"
                            className="input-quater"
                            placeholder='Inserte la foto o video de la noticia aquí'
                            // value={productData.size1Quantity}
                            onBlur={(e) => {
                                const newQuantity = e.target.value;

                                setProductData((prevData) => ({
                                    ...prevData,
                                    quantities: prevData.quantities.map((quantity, index) =>
                                        index === 4
                                            ? {
                                                ...quantity,
                                                quantity: [...quantity.quantity, newQuantity], // Insert the new quantity value into the existing array
                                            }
                                            : quantity
                                    ),
                                }));
                            }}


                        />
                    </div>
                </div>
            </div>
        );
    }


    const logFormData = (formData) => {
        for (const pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }
    };
    const generateIndividualData = async () => {
        console.log("OPD", productData);
        const quantities = [];

        productData.quantities.forEach((item) => {
            const { colour, size, quantity } = item;

            // Check if both 'size' and 'quantity' are available
            if (size && quantity && size.length === quantity.length) {
                size.forEach((s, index) => {
                    const quantitiesItem = {
                        color: colour,
                        size: s,
                        quantity: quantity[index].toString() // Convert quantity to string if needed
                    };
                    quantities.push(quantitiesItem);
                });

            } else {
                // Log a message indicating incomplete pair
                console.log("Incomplete pair: 'size' or 'quantity' is missing for item:", item);
                Swal.fire('Datos incompletos');
            }
        });
        console.log("quantities object", quantities)

        const formData = new FormData();

        // Append the quantities array
        quantities.forEach((item, index) => {
            Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                formData.append(`quantities[${index}][${nestedKey}]`, nestedValue);
            });
        });

        for (let i = 0; i < imagesFiles.length; i++) {
            formData.append('color1Image', imagesFiles[i]);
        }
        for (let i = 0; i < imagesFiles2.length; i++) {
            formData.append('color2Image', imagesFiles2[i]);
        }
        for (let i = 0; i < imagesFiles3.length; i++) {
            formData.append('color3Image', imagesFiles3[i]);
        }
        for (let i = 0; i < imagesFiles4.length; i++) {
            formData.append('color4Image', imagesFiles4[i]);
        }
        for (let i = 0; i < imagesFiles5.length; i++) {
            formData.append('color5Image', imagesFiles5[i]);
        }

        formData.append("name", productData.name);
        formData.append("description", productData.description);
        formData.append("price", productData.price);
        formData.append("baseCurrency", productData.baseCurrency);
        formData.append("quantity", productData.quantity);
        formData.append("brand", productData.brand);
        formData.append("category", selectedCategory);
        formData.append("gender", 'male');
        //formData.append("gender", productData.gender);
        formData.append("seller_name", productData.seller_name);
        formData.append("color1", productData.quantities[0].colour);
        formData.append("color2", productData.quantities[1].colour);
        formData.append("color3", productData.quantities[2].colour);
        formData.append("color4", productData.quantities[3].colour);
        formData.append("color5", productData.quantities[4].colour);
        formData.append("size1", size1);
        formData.append("size2", size2);
        formData.append("size3", size3);
        formData.append("size4", size4);
        formData.append("size5", size5);




        try {
            logFormData(formData);
            // https://mess-ice-app.uc.r.appspot.com/api/dev/store/addProduct
            const response = await axios.post(`${GlobalURL}/api/dev/store/addProduct`, formData, {
                headers: {
                    'ngrok-skip-browser-warning': '69420',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.data.ok) {
                Swal.fire('Error del Servidor');
            }

            // Handle the response data as needed
            console.log('Response Data of product', response.data);
            if (response.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                navigate('/login');
            }

            if (response.data.ok) {
                Swal.fire('Producto creado exitosamente');
                navigate('/store');
            }
        } catch (error) {
            // Handle errors during the fetch operation
            Swal.fire('Error del Servidor');
        }

        console.log("quantities2", quantities);

        // setIndividualData(quantities);
    };
    useEffect(() => {
        getCategory();
    }, [])


    return (
        <div>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='editExp-page'>
                        <Container fluid>
                            <Row>
                                <Col xs lg="3"></Col>

                                <Col xs lg="6">
                                    <div className='exp-editPage'>
                                        <div className='news-header'>
                                            <div className='back-sec'>
                                                <img src="./img/volver.png" alt="" onClick={() => { navigate('/store') }} />
                                            </div>
                                            <h3 className='header-title'>Agregar producto</h3>
                                            <div className='search-icon'>
                                                <FiSearch />
                                            </div>
                                        </div>

                                        <div className='common-input-forms newsAddContent'>
                                            <div encType="multipart/form-data">
                                                <div className='common-form-control img-video-form-control'>
                                                    <label>Nombre del vendedor</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile" className="f-input" placeholder='Inserte la foto o video de la noticia aquí'
                                                            value={productData.seller_name}
                                                            onChange={(e) => {
                                                                const newName = e.target.value;
                                                                setProductData((prevData) => ({ ...prevData, seller_name: newName }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='common-form-control img-video-form-control'>
                                                    <label>nombre del producto</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile" className="f-input" placeholder='Inserte la foto o video de la noticia aquí'
                                                            value={productData.name}
                                                            onChange={(e) => {
                                                                const newName = e.target.value;
                                                                setProductData((prevData) => ({ ...prevData, name: newName }));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='common-form-control img-video-form-control'>
                                                    <label>Descripción</label>
                                                    <div className='file-input-sec-text'>
                                                        <textarea id="uploadFile" className="f-text" placeholder='Inserte la foto o video de la noticia aquí'
                                                            value={productData.description}
                                                            onChange={(e) => {
                                                                const newName = e.target.value;
                                                                setProductData((prevData) => ({ ...prevData, description: newName }));
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div className='four-item-goup'>
                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Precio $</label>
                                                        <div className='file-input-sec'>
                                                            <input id="uploadFile" className="input-quater" placeholder='Inserte la foto o video de la noticia aquí'
                                                                value={productData.price}
                                                                onChange={(e) => {
                                                                    const newName = e.target.value;
                                                                    setProductData((prevData) => ({ ...prevData, price: newName }));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Categoría</label>
                                                        <div className='file-input-sec'>
                                                            <select className='select-tag-cat' value={selectedCategory} onChange={handleCategoryChange}>
                                                                <option value='' disabled hidden>Seleccionar</option>
                                                                {categories.map((category) => (
                                                                    <option key={category.category_id} value={category.category_id}>
                                                                        {category.category_name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>


                                                    <div className='common-form-half-control img-video-form-control'>
                                                        <label>Marca</label>
                                                        <div className='file-input-sec'>
                                                            <input id="uploadFile" className="input-half" placeholder='Inserte la foto o video de la noticia aquí'
                                                                value={productData.brand}
                                                                onChange={(e) => {
                                                                    const newName = e.target.value;
                                                                    setProductData((prevData) => ({ ...prevData, brand: newName }));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="sizearray">
                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Tamaño 1</label>
                                                        <div className='file-input-sec'>
                                                            <input

                                                                id="uploadFile"
                                                                className="input-quater"
                                                                placeholder='Ej. P'
                                                                value={size1} // changes here productData.size
                                                                onChange={(e) => { setSize1(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Tamaño 2</label>
                                                        <div className='file-input-sec'>
                                                            <input

                                                                id="uploadFile"
                                                                className="input-quater"
                                                                placeholder='Ej. M'
                                                                value={size2} // changes here productData.size
                                                                onChange={(e) => { setSize2(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Tamaño 3</label>
                                                        <div className='file-input-sec'>
                                                            <input

                                                                id="uploadFile"
                                                                className="input-quater"
                                                                placeholder='Ej. B'
                                                                value={size3} // changes here productData.size
                                                                onChange={(e) => { setSize3(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Tamaño 4</label>
                                                        <div className='file-input-sec'>
                                                            <input

                                                                id="uploadFile"
                                                                className="input-quater"
                                                                placeholder='Ej. XL'
                                                                value={size4} // changes here productData.size
                                                                onChange={(e) => { setSize4(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='common-form-quater-control img-video-form-control'>
                                                        <label>Tamaño 5</label>
                                                        <div className='file-input-sec'>
                                                            <input

                                                                id="uploadFile"
                                                                className="input-quater"
                                                                placeholder='Ej. XXL'
                                                                value={size5} // changes here productData.size
                                                                onChange={(e) => { setSize5(e.target.value) }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=''>

                                                    <label>
                                                        Male
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="male"
                                                            checked={productData.gender === 'male'}
                                                            onChange={handleGenderChange}
                                                        />
                                                    </label>

                                                    <label>
                                                        Female
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="female"
                                                            checked={productData.gender === 'female'}
                                                            onChange={handleGenderChange}
                                                        />
                                                    </label>

                                                    <label>
                                                        Unisex
                                                        <input
                                                            type="radio"
                                                            name="gender"
                                                            value="unisex"
                                                            checked={productData.gender === 'unisex'}
                                                            onChange={handleGenderChange}
                                                        />
                                                    </label>
                                                </div>

                                                <div className='forms-divs'>

                                                    <div className='form-one'>

                                                        <div className="section1" style={{ display: isactive ? 'block' : 'none' }}>

                                                            <div className='common-form-control img-video-form-control'>
                                                                <label>Color-1</label>
                                                                <div className='file-input-sec'>
                                                                    <input
                                                                        id="uploadFile"
                                                                        className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí'
                                                                        value={productData.quantities[0].colour}
                                                                        onChange={(e) => {
                                                                            const newColor = e.target.value;
                                                                            setProductData((prevData) => ({
                                                                                ...prevData,
                                                                                quantities: [
                                                                                    {
                                                                                        ...prevData.quantities[0],
                                                                                        colour: newColor,
                                                                                    },
                                                                                    ...prevData.quantities.slice(1),
                                                                                ],
                                                                            }));
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className='five-pair'>
                                                                {divs}
                                                            </div>
                                                            <div className='card-img-display'>
                                                                {previewImg.map((image, index) => (
                                                                    <div key={index} className='images-display-seperate'>
                                                                        {mediatype === "img" ? (
                                                                            <img className='imgage-exp' src={image} alt={`Img ${index}`} />
                                                                        ) : (
                                                                            <video src={image} controls width="300" height="200"></video>
                                                                        )}
                                                                        <MdDelete className='delete-colour-selected-img' onClick={() => handleDeleteImageFile(index)} />
                                                                    </div>
                                                                ))}
                                                                <div className='upload-icon'>
                                                                    <input id="uploadBtn" onChange={handleChange} type="file" ref={inputRef} className="upload" style={{ display: 'none' }} multiple="multiple" />
                                                                    <div className='upload-logo-button' onClick={handleclickinput}>
                                                                        <BsPlusLg />
                                                                    </div>
                                                                </div>
                                                                {/* add image feature */}

                                                            </div>
                                                        </div>

                                                        <div className="section2" style={{ display: isactive2 ? 'block' : 'none' }}>

                                                            <div className='common-form-control img-video-form-control'>
                                                                <label>Color-2</label>
                                                                <div className='file-input-sec'>
                                                                    <input
                                                                        id="uploadFile"
                                                                        className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí'
                                                                        value={productData.quantities[1].colour}
                                                                        onChange={(e) => {
                                                                            const newColor = e.target.value;
                                                                            setProductData((prevData) => ({
                                                                                ...prevData,
                                                                                quantities: prevData.quantities.map((quantity, index) =>
                                                                                    index === 1
                                                                                        ? {
                                                                                            ...quantity,
                                                                                            colour: newColor,
                                                                                        }
                                                                                        : quantity
                                                                                ),
                                                                            }));
                                                                        }}
                                                                    />


                                                                </div>
                                                            </div>

                                                            <div className='five-pair'>
                                                                {divs2}
                                                            </div>
                                                            <div className='card-img-display'>
                                                                {previewImg2.map((image, index) => (
                                                                    <div key={index} className='images-display-seperate'>
                                                                        {mediatype === "img" ? (
                                                                            <img className='imgage-exp' src={image} alt={`Img ${index}`} />
                                                                        ) : (
                                                                            <video src={image} controls width="300" height="200"></video>
                                                                        )}
                                                                        <MdDelete className='delete-colour-selected-img' onClick={() => handleDeleteImageFile2(index)} />
                                                                    </div>
                                                                ))}
                                                                <div className='upload-icon'>
                                                                    <input id="uploadBtn" onChange={handleChange2} type="file" ref={inputRef2} className="upload" style={{ display: 'none' }} multiple="multiple" />
                                                                    <div className='upload-logo-button' onClick={handleclickinput2}>
                                                                        <BsPlusLg />
                                                                    </div>
                                                                </div>
                                                                {/* add image feature */}

                                                            </div>
                                                        </div>

                                                        <div className="section3" style={{ display: isactive3 ? 'block' : 'none' }}>

                                                            <div className='common-form-control img-video-form-control'>
                                                                <label>Color-3</label>
                                                                <div className='file-input-sec'>
                                                                    <input
                                                                        id="uploadFile"
                                                                        className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí'
                                                                        value={productData.quantities[2].colour}
                                                                        onChange={(e) => {
                                                                            const newColor = e.target.value;
                                                                            setProductData((prevData) => ({
                                                                                ...prevData,
                                                                                quantities: prevData.quantities.map((quantity, index) =>
                                                                                    index === 2
                                                                                        ? {
                                                                                            ...quantity,
                                                                                            colour: newColor,
                                                                                        }
                                                                                        : quantity
                                                                                ),
                                                                            }));
                                                                        }}
                                                                    />


                                                                </div>
                                                            </div>

                                                            <div className='five-pair'>
                                                                {divs3}
                                                            </div>
                                                            <div className='card-img-display'>
                                                                {previewImg3.map((image, index) => (
                                                                    <div key={index} className='images-display-seperate'>
                                                                        {mediatype === "img" ? (
                                                                            <img className='imgage-exp' src={image} alt={`Img ${index}`} />
                                                                        ) : (
                                                                            <video src={image} controls width="300" height="200"></video>
                                                                        )}
                                                                        <MdDelete className='delete-colour-selected-img' onClick={() => handleDeleteImageFile3(index)} />
                                                                    </div>
                                                                ))}
                                                                <div className='upload-icon'>
                                                                    <input id="uploadBtn" onChange={handleChange3} type="file" ref={inputRef3} className="upload" style={{ display: 'none' }} multiple="multiple" />
                                                                    <div className='upload-logo-button' onClick={handleclickinput3}>
                                                                        <BsPlusLg />
                                                                    </div>
                                                                </div>
                                                                {/* add image feature */}

                                                            </div>
                                                        </div>

                                                        <div className="section4" style={{ display: isactive4 ? 'block' : 'none' }}>

                                                            <div className='common-form-control img-video-form-control'>
                                                                <label>Color-4</label>
                                                                <div className='file-input-sec'>
                                                                    <input
                                                                        id="uploadFile"
                                                                        className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí'
                                                                        value={productData.quantities[3].colour}
                                                                        onChange={(e) => {
                                                                            const newColor = e.target.value;
                                                                            setProductData((prevData) => ({
                                                                                ...prevData,
                                                                                quantities: prevData.quantities.map((quantity, index) =>
                                                                                    index === 3
                                                                                        ? {
                                                                                            ...quantity,
                                                                                            colour: newColor,
                                                                                        }
                                                                                        : quantity
                                                                                ),
                                                                            }));
                                                                        }}
                                                                    />


                                                                </div>
                                                            </div>

                                                            <div className='five-pair'>
                                                                {divs4}
                                                            </div>
                                                            <div className='card-img-display'>
                                                                {previewImg4.map((image, index) => (
                                                                    <div key={index} className='images-display-seperate'>
                                                                        {mediatype === "img" ? (
                                                                            <img className='imgage-exp' src={image} alt={`Img ${index}`} />
                                                                        ) : (
                                                                            <video src={image} controls width="300" height="200"></video>
                                                                        )}
                                                                        <MdDelete className='delete-colour-selected-img' onClick={() => handleDeleteImageFile4(index)} />
                                                                    </div>
                                                                ))}
                                                                <div className='upload-icon'>
                                                                    <input id="uploadBtn" onChange={handleChange4} type="file" ref={inputRef4} className="upload" style={{ display: 'none' }} multiple="multiple" />
                                                                    <div className='upload-logo-button' onClick={handleclickinput4}>
                                                                        <BsPlusLg />
                                                                    </div>
                                                                </div>
                                                                {/* add image feature */}

                                                            </div>
                                                        </div>

                                                        <div className="section5" style={{ display: isactive5 ? 'block' : 'none' }}>

                                                            <div className='common-form-control img-video-form-control'>
                                                                <label>Color-5</label>
                                                                <div className='file-input-sec'>
                                                                    <input
                                                                        id="uploadFile"
                                                                        className="f-input"
                                                                        placeholder='Inserte la foto o video de la noticia aquí'
                                                                        value={productData.quantities[4].colour}
                                                                        onChange={(e) => {
                                                                            const newColor = e.target.value;
                                                                            setProductData((prevData) => ({
                                                                                ...prevData,
                                                                                quantities: prevData.quantities.map((quantity, index) =>
                                                                                    index === 4
                                                                                        ? {
                                                                                            ...quantity,
                                                                                            colour: newColor,
                                                                                        }
                                                                                        : quantity
                                                                                ),
                                                                            }));
                                                                        }}
                                                                    />


                                                                </div>
                                                            </div>

                                                            <div className='five-pair'>
                                                                {divs5}
                                                            </div>
                                                            <div className='card-img-display'>
                                                                {previewImg5.map((image, index) => (
                                                                    <div key={index} className='images-display-seperate'>
                                                                        {mediatype === "img" ? (
                                                                            <img className='imgage-exp' src={image} alt={`Img ${index}`} />
                                                                        ) : (
                                                                            <video src={image} controls width="300" height="200"></video>
                                                                        )}
                                                                        <MdDelete className='delete-colour-selected-img' onClick={() => handleDeleteImageFile5(index)} />
                                                                    </div>
                                                                ))}
                                                                <div className='upload-icon'>
                                                                    <input id="uploadBtn" onChange={handleChange5} type="file" ref={inputRef5} className="upload" style={{ display: 'none' }} multiple="multiple" />
                                                                    <div className='upload-logo-button' onClick={handleclickinput5}>
                                                                        <BsPlusLg />
                                                                    </div>
                                                                </div>
                                                                {/* add image feature */}

                                                            </div>
                                                        </div>

                                                        <div className='button-pair-prev-next'>
                                                            <div className='prev-btn'>
                                                                <button className='blue-btn' onClick={handlePrevClick}>Prev</button>
                                                            </div>
                                                            <div className="pagination-sec">
                                                                <ul>
                                                                    <li>
                                                                        <Link className={`${isactive ? 'active-dots' : 'dots'}`}></Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className={`${isactive2 ? 'active-dots' : 'dots'}`}></Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className={`${isactive3 ? 'active-dots' : 'dots'}`}></Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className={`${isactive4 ? 'active-dots' : 'dots'}`}></Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link className={`${isactive5 ? 'active-dots' : 'dots'}`}></Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className='next-btn'>
                                                                <button className='blue-btn' onClick={handleNextClick}>Next</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="submit-btn">
                                                    <button onClick={() => { generateIndividualData(); }} className='green-btn'>Submit</button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </Col>

                                <Col xs lg="3"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addproduct