import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import "./style.css";
import GlobalURL from "../BaseUrl";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { MdOutlineFormatBold } from "react-icons/md";
import { BiItalic } from "react-icons/bi";
import { BsTypeStrikethrough } from "react-icons/bs";
import { CiTextAlignLeft, CiTextAlignRight } from "react-icons/ci";
import { CiTextAlignCenter } from "react-icons/ci";

const AddExperience = () => {
  const [insigniaList, setInsigniaList] = useState([]);
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [mediatype, setMediatype] = useState("img");
  const [preview, setPreview] = useState();
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [gems2, setGems2] = useState("");
  const [insignia, setInsignia] = useState("");
  const formData = new FormData();
  const [loading, setLoading] = useState(false);
  const [insigniaType, setInsigniaType] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [open, setOpen] = useState(false);
  const editorRef = useRef(null);

  function handleChange(e) {
    setFile(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0].name.includes("mp4")) {
      setMediatype("vids");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    addExperienceAPI();
  };

  const addExperienceAPI = async () => {
    // const fetch = require('node-fetch');
    setLoading(true);

    if (
      title === "" ||
      desc === "" ||
      gems2 === "" ||
      insignia === "" ||
      file === ""
    ) {
      Swal.fire(t("Cada campo es obligatorio"));
      setLoading(false);
      return;
    }
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/addExperience`; // Replace `${server}` with your actual server variable
    const bearerToken = localStorage.getItem("token");
    formData.append("title", title);
    formData.append("description", desc);
    formData.append("gems_count", gems2);
    formData.append("insignia_required", insignia);
    formData.append("media", file);
    formData.append("team_id", "6");
    formData.append("insignia", insigniaType);

    if (true) {
      console.log("formdata ", Object.fromEntries(formData));
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${bearerToken}`,
            "ngrok-skip-browser-warning": "69420",
          },
          body: formData,
        });

        if (!response.ok) {
          Swal.fire(t("Error del servidor"));
          setLoading(false);
        }
        if (response.code === 401) {
          Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
          navigate("/login");
          setLoading(false);
        }
        if (response.ok) {
          const data = await response.json();
          console.log("API response:", data);
          setLoading(false);
          navigate("/experiencepage");
          Swal.fire(t("Experiencia agregada exitosamente"));
        }
      } catch (error) {
        console.error("Error making API request:", error.message);
        setLoading(false);
      }

      // Call the function to make the API request
    }
  };

  useEffect(() => {
    getAllInsignia();
  }, []);

  async function getAllInsignia() {
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/team_moderator/getAllInsignia`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (!response.ok) {
        Swal.fire(t("Error del servidor"));
        return;
      }

      const data = await response.json();
      console.log("Insignia data", data);
      setInsigniaList(data.data);

      // Process the data further or update your component state here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      Swal.fire(t("Error del servidor"));
    }
  }

  const handleClose = () => {
    // Close the modal
    setOpen(false);
  };

  const handleBold = () => {
    document.execCommand("bold", false, null);
  };

  const handleItalic = () => {
    document.execCommand("italic", false, null);
  };

  const handleStrikeThrough = () => {
    document.execCommand("strikeThrough", false, null);
  };

  const handleTextAlign = (alignment) => {
    document.execCommand("justify" + alignment, false, null);
  };

  const handleGuardarClick = () => {
    setEditorContent(editorRef.current.innerHTML);
    console.log("editorRef.current: ", editorRef.current.innerHTML);
    setDesc(editorRef.current.innerHTML);
    handleClose();
  };

  const handleDeacriptionClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <Container fluid>
            <Row>
              <Col xs lg="3"></Col>
              <Col xs lg="6">
                <div className="addExp-sec">
                  <div className="news-header">
                    <div className="back-sec">
                      <img
                        src="./img/volver.png"
                        alt=""
                        onClick={() => {
                          navigate("/experiencepage");
                        }}
                      />
                    </div>
                    <h3
                      className="header-title"
                      style={{ fontFamily: "Francois One", fontSize: "23px" }}
                    >
                      {t("Agregar Vivencia Mess Ice")}
                    </h3>
                    <div className="search-icon"></div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="AddExp-sec-content">
                      <div className="common-input-forms newsAddContent">
                        <div>
                          <div className="common-form-control">
                            <label>{t("Título")}</label>
                            <input
                              required
                              placeholder={t(
                                "Inserte el título de la Vivencia Mess Ice aquí"
                              )}
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                          </div>

                          <div className="common-form-control">
                            <label>{t("Descripción")}</label>
                            <input
                              readOnly
                              placeholder={t(
                                "Inserte la descripción de la Vivencia Mess Ice aquí"
                              )}
                              onClick={() => {
                                handleDeacriptionClick();
                              }}
                            />
                          </div>

                          <div className="common-form-control img-video-form-control">
                            <label>Foto/Video</label>
                            <div className="file-input-sec">
                              <input
                                required
                                id="uploadFile"
                                disabled={true}
                                className="f-input"
                                placeholder={t(
                                  "Inserte la foto o video de la Vivencia Mess Ice aquí"
                                )}
                              />
                              <div
                                className="fileUpload btn btn--browse"
                                style={{ height: "110%" }}
                              >
                                <input
                                  id="uploadBtn"
                                  style={{ height: "72px" }}
                                  onChange={handleChange}
                                  type="file"
                                  className="upload"
                                />
                              </div>
                            </div>
                          </div>

                          {file && (
                            <div className="preview-imgCon-ae">
                              {mediatype === "img" ? (
                                <div className="preview-imgCon">
                                  <img src={preview} alt=""></img>
                                </div>
                              ) : (
                                <div className="preview-imgCon">
                                  <video src={preview}></video>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="common-form-control">
                            <label>{t("Gemas")}</label>
                            <input
                              required
                              placeholder={t(
                                "Ingresa aquí los requisitos necesarios de Gemas para postular a la Experiencia Mess Ice"
                              )}
                              type="number"
                              value={gems2}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  return;
                                }
                                setGems2(e.target.value);
                              }}
                            />
                          </div>
                          <div className="common-form-control">
                            <label>{t("Insignia")}</label>
                            <select
                              onChange={(e) => {
                                setInsignia(e.target.value);
                              }}
                              value={insignia}
                              placeholder={t(
                                "Ingrese aquí los requisitos de insignia necesarios para solicitar la experiencia Mess Ice"
                              )}
                            >
                              {/* <option value={""}>{t('Ingrese aquí los requisitos de insignia necesarios para solicitar la experiencia Mess Ice')}</option> */}
                              {insigniaList.map((item, map) => (
                                <option value={item.insignia_name_es}>
                                  {item.insignia_name_es}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="news-btn-sec">
                        <button
                          className="green-btn"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              className=""
                              style={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="loading-adjust">
                                <div className="Loader d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm my-5"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                  <div className="my-5">
                                    {" "}
                                    &nbsp; {t("Subiendo...")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            t("GUARDAR")
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col xs lg="3"></Col>

              <Modal
                show={open}
                className="institutional-popup common-popup"
                onHide={handleClose}
                centered
              >
                <Modal.Header className="pb-0 mb-0" closeButton>
                  <Modal.Title>{t("Editar")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="custom-text-editor w-100">
                    <div className="toolbar">
                      <button onClick={handleBold}>
                        <MdOutlineFormatBold />
                      </button>
                      <button onClick={handleItalic}>
                        <BiItalic />
                      </button>
                      <button onClick={handleStrikeThrough}>
                        <BsTypeStrikethrough />
                      </button>
                      <div className="text-align-buttons">
                        <button onClick={() => handleTextAlign("Left")}>
                          <CiTextAlignLeft />
                        </button>
                        <button onClick={() => handleTextAlign("Center")}>
                          <CiTextAlignCenter />
                        </button>
                        <button onClick={() => handleTextAlign("Right")}>
                          <CiTextAlignRight />
                        </button>
                      </div>
                    </div>
                    <div
                      className="editor"
                      ref={editorRef}
                      contentEditable
                      dangerouslySetInnerHTML={{ __html: editorContent }}
                    ></div>
                  </div>
                </Modal.Body>

                <Modal.Footer className="border-0 p-0">
                  <div className="news-btn-sec">
                    <button className="green-btn" disabled={loading} onClick={handleGuardarClick}>
                      {t("GUARDAR")}
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AddExperience;
