import { Col, Container, Row } from 'react-bootstrap'
import { FiSearch } from 'react-icons/fi'
import React from 'react';
import './style.css';

const BillboardPost = () => {
  return (
    <>
      <div className='dashboard'>
        <div className='dashboard-container'>
            <div className='billboardpostPage'>
                <Container fluid>
                    <Row>
                        <Col xs lg="3"></Col>
                        <Col xs lg="6">
                            <div className='billboardPostSec'>
                                <div className="news-header">
                                    <div className="back-sec" style={{ cursor: "pointer" }}>
                                        <img src="./img/volver.png" alt="" />
                                    </div>
                                    <h3 className="header-title">editar post</h3>
                                    <div className="search-icon">
                                        <FiSearch />
                                    </div>
                                </div>
                                <div className='editPostSec'>
                                    <div className='pathOfBadges'>
                                        <div className='pathOfBadges-card'>
                                            <div className='card-img'>
                                                <img src="./img/bg-1.png" alt="" />
                                            </div>
                                            <div className='card-content'>
                                                <p>
                                                    ¿Estás listo para llevar a tu 
                                                    club a un nuevo nivel?
                                                </p>
                                                <p>
                                                    Recorre el camino de las 
                                                    Insignias y haz que tu voz sea 
                                                    escuchada
                                                </p>                                                
                                            </div>
                                            <p>¡SACA EL MÁXIMO PROVECHO A TU HERRAMIENTA!</p>
                                        </div>
                                        <div className='choose-path-sec'>
                                            <p className='path-heading'>
                                                Elige uno de los tres caminos
                                            </p>
                                            <div className='path-sec'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs lg="3"></Col>
                    </Row>
                </Container>
            </div>
        </div>
      </div>
    </>
  )
}

export default BillboardPost
