import React from 'react'
import { Outlet } from "react-router-dom";
import Header from "../Navbar/Navbar";


const FullLayout = () => {

  return (
    <>
      <Header/>
      <Outlet />
    </>
  );
};

export default FullLayout;
