import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import "./stylepv.css";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

const AddProposalVote = () => {
  const [insigniaID, setInsigniaID] = useState(1);
  const language = useSelector((state) => state.language.value);
  const [loading, setLoading] = useState(false);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [proposalData, setProposalData] = useState({
    title: "",
    category: "",
    endDate: "",
    startDate: "",
  });
  const [optionData, setOptionData] = useState("");
  const [proposalImage, setProposalImage] = useState(null);
  const [insigniaList, setInsigniaList] = useState([]);
  const [insignia, setInsignia] = useState("");
  // const [optionCount, setOptionCount] = useState();
  const formData = new FormData();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileSelect = (e) => {
    setProposalImage(e.target.files[0]);
    console.log(e.target.value);
    document.getElementById("uploadFile").value = e.target.value.replace(
      "C:\\fakepath\\",
      ""
    );
  };

  const handleProposalData = (e) => {
    setProposalData({ ...proposalData, [e.target.name]: e.target.value });
  };

  const handleOptionData = (e) => {
    setOptionData({ ...optionData, [e.target.name]: e.target.value });
  };

  const handleSubmitProposal = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      proposalData.title == "" ||
      proposalData.category == "" ||
      proposalData.endDate == "" ||
      proposalData.startDate == ""
    ) {
      Swal.fire(t("Se requieren todos los datos del formulario"));
      return;
    }
    // console.log("proposalData: ", proposalData);
    // console.log("optionData: ", optionData);
    // console.log("proposalImage: ", proposalImage);

    const sD = proposalData.startDate;
    const eD = proposalData.endDate;

    const currentDate = new Date();
    if (sD <= currentDate) {
      Swal.fire("La fecha de inicio no puede ser en el pasado.");
    }

    if (sD == eD) {
      Swal.fire(t("Las fechas no pueden ser las mismas"));
      setLoading(false);
      return;
    } else if (sD > eD) {
      Swal.fire(t("La fecha de inicio no puede ser mayor"));
      setLoading(false);
      return;
    }

    if (
      proposalData.title &&
      proposalData.category &&
      proposalData.endDate &&
      proposalData.startDate &&
      proposalImage
      // && optionCount
    ) {
      formData.append("proposalImage", proposalImage);
      formData.append("team_id", -1);

      for (let formfields in proposalData) {
        formData.append(formfields, proposalData[formfields]);
      }

      for (let formfields in optionData) {
        if (optionData[formfields])
          formData.append(formfields, optionData[formfields]);
      }
      if (proposalData.category === "insignia") {
        formData.append("insignia_id", insignia);
        formData.append("insignia_type", type);
      }
      // ${GlobalURL}/api/dev/app_moderator/createPraposal

      const url = `${GlobalURL}/api/dev/app_moderator/createPraposal`;
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: formData,
      })
        .then((response) => {
          if (response.code === 401) {
            setLoading(false);
            Swal.fire("La sesión expiró, vuelva a iniciar sesión");
            navigate("/login");
          }
          if (response.status === 404) {
            setLoading(false);
            console.log("status: ", response);
          } else if (response.status === 200) {
            console.log("done!!");
            Swal.fire(t("votes añadido con éxito!"));
            navigate(-1);
            setLoading(false);
            return response.json();
          }
        })
        .catch((err) => {
          console.log("error occured: ", err);
          setLoading(false);
        });
    } else {
      alert("field req");
      setLoading(false);
      //apply error message for news if fields are empty
    }
  };

  async function getAllInsignia() {
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/team_moderator/getAllInsignia`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );

      if (!response.ok) {
        Swal.fire("Server Error");
        return;
      }

      const data = await response.json();
      console.log("Insignia data", data);
      setInsigniaList(data.data);

      // Process the data further or update your component state here
    } catch (error) {
      console.error("Error fetching data:", error.message);
      Swal.fire("Server Error");
    }
  }
  useEffect(() => {
    getAllInsignia();
  }, []);

  const getInsigniaId = (insigniaName) => {
    let id, type;
    console.log(
      "conole, of ITEM: ",
      insigniaList,
      "insigniaName",
      insigniaName
    );
    insigniaList.forEach((item) => {
      if (item.insignia_id == insigniaName) {
        id = item.insignia_id;
        type = item.type_es;
      }
    });
    setType(type);
    setInsignia(id);

    // setProposalData({ ...proposalData, insignia_id: id, insignia_type: type });
  };

  return (
    <>
      <div className="dashboard apv-page">
        <div className="dashboard-container">
          <div className="add-streaming-page">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="3" md="12"></Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="news-header">
                    <div
                      className="back-sec"
                      onClick={() => navigate(-1)}
                      style={{ cursor: "pointer" }}
                    >
                      <img src="./img/volver.png" alt="back_icon" />
                    </div>
                    <h3
                      className="header-title inter-page-header-title"
                      style={{ fontFamily: "Francois One", fontSize: "23px" }}
                    >
                      {t("Agregar Propuestas")}
                    </h3>
                    <div className="search-icon">{/* <FiSearch /> */}</div>
                  </div>
                  <div className="common-input-forms addstreaming">
                    <form onSubmit={handleSubmitProposal} required>
                      <div className="common-form-control">
                        <label>{t("Categoría de Propuesta")}</label>
                        <select
                          name="category"
                          onChange={handleProposalData}
                          value={proposalData.category}
                        >
                          <option value="">
                            {t(
                              "Elija categoría de la propuesta (General / Insignia - elegir camino"
                            )}
                          </option>
                          <option value="general">{t("General")}</option>
                          <option value="insignia">{t("Insignia")}</option>
                        </select>
                      </div>

                      {proposalData.category === "insignia" && (
                        <>
                          {insigniaList ? (
                            <div className="common-form-control">
                              <label>{t("Insignia Category")}</label>
                              <select
                                onChange={(e) => {
                                  getInsigniaId(e.target.value);
                                  // setInsignia(e.target.value);
                                  // setInsigniaID();
                                }}
                                value={insignia}
                              >
                                {insigniaList.map((item, map) => (
                                  <option value={item.insignia_id}>
                                    {item.insignia_name_es}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            <div
                              className=""
                              style={{
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="loading-adjust">
                                <div className="Loader d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm my-5"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="common-form-control">
                        <label>{t("Propuesta")}</label>
                        <input
                          placeholder={t(
                            "Inserte el título de la propuesta aquí"
                          )}
                          name="title"
                          onChange={handleProposalData}
                          value={proposalData.title}
                          required
                        />
                      </div>
                      <div className="common-form-control" onClick={handleShow}>
                        <label style={{ cursor: "pointer" }}>
                          {t("Opciones disponibles de Propuesta")}
                        </label>
                        <input
                          placeholder={t(
                            "Inserte las opciones de la propuesta"
                          )}
                          name="title"
                          value={""}
                          onChange={() => {
                            return;
                          }}
                        />
                      </div>
                      <div className="common-form-control img-video-form-control">
                        <label>{t("Imagenes de Propuesta")}</label>
                        <div className="file-input-sec ">
                          <input
                            style={{ zIndex: "-1" }}
                            id="uploadFile"
                            disabled={true}
                            className="f-input"
                            placeholder={t("Insertar foto de propuesta aquí")}
                          />
                          <div
                            style={{ height: "70px" }}
                            className="fileUpload btn btn--browse"
                          >
                            <input
                              id="uploadBtn"
                              accept="image/*"
                              onChange={handleFileSelect}
                              type="file"
                              className="upload"
                            />
                          </div>
                        </div>
                      </div>
                      <Row className="common-date-field">
                        <Col xs lg="6">
                          <div className="common-form-control">
                            <label>
                              {t("Fecha de inicio de la propuesta")}
                            </label>
                            <input
                              placeholder={t(
                                "Inserte la fecha de la propuesta aquí (Inicio opcional)"
                              )}
                              type="date"
                              name="startDate"
                              value={proposalData.startDate}
                              onChange={handleProposalData}
                            />
                          </div>
                        </Col>

                        <Col xs lg="6">
                          <div className="common-form-control">
                            <label>
                              {t("Fecha de finalización de la propuesta")}
                            </label>
                            <input
                              placeholder={t(
                                "Inserte la fecha de la propuesta aquí (Final - opcional)"
                              )}
                              type="date"
                              name="endDate"
                              value={proposalData.endDate}
                              onChange={handleProposalData}
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="news-btn-sec">
                        <button className="green-btn" type="submit">
                          {loading ? (
                            <div
                              className=""
                              style={{
                                height: "25px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="loading-adjust">
                                <div className="Loader d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm my-5"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                  <div className="my-5">
                                    {" "}
                                    &nbsp; {t("Cargando...")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            t("Guardar")
                          )}
                        </button>
                      </div>
                    </form>
                    <Modal
                      show={show}
                      className="proposal-popup common-popup"
                      onHide={handleClose}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="modal-title-pv">
                          {t("OPCIONES DE PROPUESTA")}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="slider-details">
                          <form>
                            <ul className="praposal-options-list p-0">
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 1 de propuesta"
                                  )}
                                  name="option1"
                                  value={optionData?.option1}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 2 de propuesta"
                                  )}
                                  name="option2"
                                  value={optionData?.option2}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 3 de propuesta"
                                  )}
                                  name="option3"
                                  value={optionData?.option3}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 4 de propuesta"
                                  )}
                                  name="option4"
                                  value={optionData?.option4}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 5 de propuesta"
                                  )}
                                  name="option5"
                                  value={optionData?.option5}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 6 de propuesta"
                                  )}
                                  name="option6"
                                  value={optionData?.option6}
                                  onChange={handleOptionData}
                                />
                              </li>
                              <li>
                                <input
                                  type="text"
                                  placeholder={t(
                                    "Ingresar texto de opción 7 de propuesta"
                                  )}
                                  name="option7"
                                  value={optionData?.option7}
                                  onChange={handleOptionData}
                                />
                              </li>
                            </ul>
                          </form>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="border-0 p-0">
                        <div className="news-btn-sec ">
                          <button
                            disabled={loading}
                            className="green-btn  "
                            onClick={() => {
                              if (optionData == "") {
                                return;
                              }
                              setShow(false);
                            }}
                          >
                            {t("GUARDAR")}
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProposalVote;
