import React, { useState } from "react";
import "./Emails.css";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import { FaBan } from "react-icons/fa";
import GlobalURL from "../BaseUrl";

import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAllScopesGoogle } from "@react-oauth/google";
import { useLocation, useSearchParams } from "react-router-dom";

function Emails() {
  const [data, setData] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [disabled, setDisable] = useState(false);

  const location = useSearchParams();
  const params = useSearchParams();

  const [reports, setReports] = useState([]);
  const [repdetails, setRepdetails] = useState({
    type: "",
    id: "",
    reason: "",
    by: "",
  });
  const [showRepDet, setShowRepDet] = useState(false);

  const [data2, setData2] = useState([]);
  const [veriList, getVeriList] = useState([]);

  const navigate = useNavigate();

  const handlePageCount = async (e) => {
    setCurrentPage(currentPage + 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager?page=${
      currentPage + 1
    }&limit=5`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 10) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire("Error del Servidor");
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire("Error del Servidor");
    }
  };
  const handlePageCountdec = async (e) => {
    setCurrentPage(currentPage - 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager?page=${
      currentPage - 1
    }&limit=5`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 10) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire("Error del Servidor");
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire("Error del Servidor");
    }
  };
  async function getData() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        setData(responseData.data);
        console.log("user profiles", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire("Error del Servidor");
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire("Error del Servidor");
    }
  }
  async function getReports() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllReport`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      // Check if the request was successful (status code 2xx)
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === "No Data Found") {
          return;
        }
        setReports(responseData.data);

        console.log("reports", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire("Error del Servidor");
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire("Error del Servidor");
    }
  }
  const formatDate = (dateString) => {
    const today = new Date();
    const parsedDate = new Date(dateString);

    if (
      parsedDate.getDate() === today.getDate() &&
      parsedDate.getMonth() === today.getMonth() &&
      parsedDate.getFullYear() === today.getFullYear()
    ) {
      // The date is today
      const hours = parsedDate.getHours();
      const minutes = parsedDate.getMinutes();
      const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}hs`;

      return `hoy, ${formattedTime}`;
    } else {
      // If it's not today, you can customize the format as needed
      const options = { weekday: "long", hour: "numeric", minute: "numeric" };
      return parsedDate.toLocaleString("es-ES", options);
    }
  };
  useEffect(() => {
    // getData();
    // getReports();
    // handlePageCount();
    console.log("ahsgdahs", location);
    // window.location.href.target = "_blank";
    // console.log('window.location.href.target: ', window.location.href.target);

    // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.action%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&response_type=code&client_id=229022988461-4vskqcikeh3ap58b6ugjd38qo72ci5e5.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftesting'
    // window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.action%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&response_type=code&client_id=229022988461-4vskqcikeh3ap58b6ugjd38qo72ci5e5.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmiappmoderator.web.app%2Ftesting';

    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.action%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&response_type=code&client_id=229022988461-4vskqcikeh3ap58b6ugjd38qo72ci5e5.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmiappmoderator.web.app%2Ftesting";

    // <Link to='https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.action%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.addons.current.message.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&response_type=code&client_id=229022988461-4vskqcikeh3ap58b6ugjd38qo72ci5e5.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmiappmoderator.web.app%2Ftesting'>
    // </Link>
    console.log("next:", location);
    console.log("params:: ", params);
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="Digital-sec">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col xs lg="3"></Col>
                <Col xs lg="6">
                  <div className="news-header">
                    <div className="back-sec" style={{ cursor: "pointer" }}>
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 className="header-title">BANDEJA DE CORREO</h3>
                    <div className="search-icon">
                      <FiSearch />
                    </div>
                  </div>
                  <div className="digital-table digitalInner-table">
                    <div className="digitalTable-left">
                      <div className="digitalTable-head">
                        <div className="blue-email-button">+ Correo Nuevo</div>
                      </div>
                      <div className="digitalLeft-body">
                        <ul className="digital-tudo-list">
                          <li
                            onClick={() => {
                              setShowRepDet(false);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">Entrada</span>
                              <span className="tudo-counter">7</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setShowRepDet(false);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">Borradores</span>
                              <span className="tudo-counter">5</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setShowRepDet(false);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">Enviados</span>
                              <span
                                className="tudo-counter"
                                style={{ opacity: 0 }}
                              >
                                78
                              </span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setShowRepDet(false);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">Papelera</span>
                              <span className="tudo-counter">2</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {showRepDet ? (
                      <div className="digital-right">
                        <div className="digitalTable-head d-flex flex-wrap">
                          <div className="digihead-left">
                            <p className="mb-0">
                              Asunto
                            </p>
                          </div>
                          <div className="digihead-right">
                            <p className="mb-0">{repdetails.reason}</p>
                          </div>
                        </div>

                        <div className="digitalRight-body">
                          <ul className="Digiright-table p-0 mb-0">
                            <li>
                              <div className="digitalId-div">
                                <p className="mb-0">ID</p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p className="mb-0">
                                      Denuncia #{repdetails.id}
                                    </p>
                                  </div>
                                  <div className="digiRight-text">
                                    <p className="mb-0">{repdetails.reason}</p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="digitalId-div">
                                <p className="mb-0">De</p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p className="mb-0">
                                      @{repdetails.username}
                                    </p>
                                  </div>
                                  <div className="digiRight-text">
                                    <p className="mb-0">{repdetails.reason}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="digipost-sec"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="digital-right">
                        <div className="digitalTable-head">
                          <p className="mb-0">REPORTES & DENUNCIAS</p>
                        </div>
                        <div className="digitalRight-body">
                          <ul className="Digiright-table p-0 mb-0">
                            (
                            <div
                              className=""
                              style={{
                                height: "500px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div className="loading-adjust">
                                <div className="Loader d-flex justify-content-center align-items-center">
                                  <div
                                    className="spinner-border spinner-border-sm my-5"
                                    role="status"
                                  >
                                    <span className="sr-only"></span>
                                  </div>
                                  <div className="my-5">
                                    {" "}
                                    &nbsp; Cargando...
                                  </div>
                                </div>
                              </div>
                            </div>
                            )
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs lg="3"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Emails;
