import React from "react";
import { Navigate } from "react-router-dom";
import FullLayout from "./components/FullLayout/FullLayout";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
