import React, { useRef } from "react";
import { useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { GrLike } from "react-icons/gr";
import { GrDislike } from "react-icons/gr";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { MoonLoader } from "react-spinners";
import BarChart from "../../charts/barchart";
import { IoIosArrowDown } from "react-icons/io";

const ProposalAndVotes = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [dain, setDain] = useState(false);
  // const [camino] = useState([t('Todas'), t('Técnico'), t('Institucional'), t('Infraestructura')]);
  const [camino] = useState([
    t("Todas"),
    t("Técnico"),
    t("Institucional"),
    t("Infraestructura"),
  ]);

  const [selectedItem, setSelectedItem] = useState(0);

  const [cardSelected, setCardSelected] = useState("general");

  const [allproposal, setAllproposal] = useState([]);

  const [general, setGeneral] = useState([]);
  const [allinsignia, setAllInsignia] = useState([]);
  const [insignia, setInsignia] = useState([]);
  const [technico, setTechnico] = useState([]);
  const [institutional, setInstitutional] = useState([]);
  const [infrastura, setInfrastura] = useState([]);

  const [filteredProposals2, setFilteredProposals2] = useState([]);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [originalData2, setOriginalData2] = useState([]);
  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [dropVisi, setDropVisi] = useState(false);
  const [optionSel, setOptionSel] = useState("");

  const dropdownRef = useRef(null);

  const handleDropDownSel = (val) => {
    setOptionSel(val);

    if (val === "created") {
      const sortedDateInsignia = insignia.sort((a, b) => {
        return (
          new Date(b.proposalData.created_at) -
          new Date(a.proposalData.created_at)
        );
      });
      setInsignia(sortedDateInsignia);

      const sortedDateGeneral = general.sort((a, b) => {
        return (
          new Date(b.proposalData.created_at) -
          new Date(a.proposalData.created_at)
        );
      });
      setGeneral(sortedDateGeneral);
    } else if (val === "expiry") {
      const sortedByExpiryInsignia = insignia.sort((a, b) => {
        return (
          new Date(a.proposalData.expiring_on) -
          new Date(b.proposalData.expiring_on)
        );
      });
      setInsignia(sortedByExpiryInsignia);

      const sortedByExpiryGeneral = general.sort((a, b) => {
        return (
          new Date(a.proposalData.expiring_on) -
          new Date(b.proposalData.expiring_on)
        );
      });
      setGeneral(sortedByExpiryGeneral);
    } else if (val === "popular") {
      const sortedByPopularityInsignia = insignia.sort((a, b) => {
        const popularityA = a.proposalData.likes - a.proposalData.dislikes;
        const popularityB = b.proposalData.likes - b.proposalData.dislikes;
        return popularityB - popularityA;
      });
      setInsignia(sortedByPopularityInsignia);

      const sortedByPopularityGeneral = general.sort((a, b) => {
        const popularityA = a.proposalData.likes - a.proposalData.dislikes;
        const popularityB = b.proposalData.likes - b.proposalData.dislikes;
        return popularityB - popularityA;
      });
      setGeneral(sortedByPopularityGeneral);
    } else if (val === "hated") {
      const sortedByDislikesInsignia = insignia.sort((a, b) => {
        return b.proposalData.dislikes - a.proposalData.dislikes;
      });
      setInsignia(sortedByDislikesInsignia);

      const sortedByDislikesGeneral = general.sort((a, b) => {
        return b.proposalData.dislikes - a.proposalData.dislikes;
      });
      setGeneral(sortedByDislikesGeneral);
    } else if (val === "liked") {
      const sortedByPopularityInsignia = insignia.sort((a, b) => {
        return b.proposalData.likes - a.proposalData.likes;
      });
      setInsignia(sortedByPopularityInsignia);

      const sortedByPopularityGeneral = general.sort((a, b) => {
        return b.proposalData.likes - a.proposalData.likes;
      });
      setGeneral(sortedByPopularityGeneral);
    }
  };

  const handleSearch = (e, cardSel) => {
    if (cardSelected === "insignia") {
      const dataTemp2 = originalData2;

      setSearchTerm(e.target.value);

      if (e.target.value === "") {
        setInsignia(originalData2);
        return;
      }
      const result2 = dataTemp2.filter((item) =>
        item.proposalData.content.toLowerCase().includes(e.target.value)
      );

      if (result2.length === 0) {
        return;
      }
      setInsignia(result2);
      return;
    }

    const dataTemp = originalData;

    setSearchTerm(e.target.value);

    if (e.target.value === "") {
      setGeneral(originalData);
      return;
    }
    const result = dataTemp.filter((item) =>
      item.proposalData.content.toLowerCase().includes(e.target.value)
    );

    if (result.length === 0) {
      return;
    }
    setGeneral(result);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const handleItemClick = (index) => {
    setSelectedItem(index);

    if (index === 0) {
      setInsignia(allinsignia);
    } else if (index === 1) {
      const y = allinsignia.filter(
        (proposal) =>
          proposal.proposalData.insignia_id == 4 ||
          proposal.proposalData.insignia_id == 7 ||
          proposal.proposalData.insignia_id == 10
      );
      if (y.length == 0) {
        setInsignia([]);
      }
      setInsignia(y);
    } else if (index === 2) {
      const y = allinsignia.filter(
        (proposal) =>
          proposal.proposalData.insignia_id == 3 ||
          proposal.proposalData.insignia_id == 6 ||
          proposal.proposalData.insignia_id == 9
      );
      if (y.length == 0) {
        setInsignia([]);
      }
      setInsignia(y);
    } else if (index === 3) {
      const y = allinsignia.filter(
        (proposal) =>
          proposal.proposalData.insignia_id == 2 ||
          proposal.proposalData.insignia_id == 5 ||
          proposal.proposalData.insignia_id == 8
      );
      if (y.length == 0) {
        setInsignia([]);
      }
      setInsignia(y);
    }
  };
  const fetchDataProposal = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${GlobalURL}/api/dev/app_moderator/getAllProposalResult`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const data = await response.json();
      if (data.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
        setLoading(false);
        return;
      }

      if (!response.ok) {
        Swal.fire(t("Error del Servidor"));
        setLoading(false);
        return;
      }
      setLoading(false);

      const x = data.data[0].filter(
        (proposal) =>
          proposal.proposalData.category === "General" ||
          proposal.proposalData.category === "general"
      );

      const y = data.data[0].filter(
        (proposal) => proposal.proposalData.category == "insignia"
      );

      setAllInsignia(y);
      setInsignia(y);
      setOriginalData2(y);

      setGeneral(x);
      setOriginalData(x);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataProposal();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if clicked outside of it
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropVisi(false);
      }
    };
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup: Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="proposal-page">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="3" md="12">
                  {dain && (
                    <div className="camino-seg">
                      <div className="camino-head">{t("CAMINOS")}</div>
                      <div
                        className={`camino-items ${
                          selectedItem === 0 ? "selected" : ""
                        }`}
                        onClick={() => handleItemClick(0)}
                      >
                        {t("Todas")}
                      </div>
                      <div
                        className={`camino-items ${
                          selectedItem === 1 ? "selected" : ""
                        }`}
                        onClick={() => handleItemClick(1)}
                      >
                        {t("Técnico")}
                      </div>
                      <div
                        className={`camino-items ${
                          selectedItem === 2 ? "selected" : ""
                        }`}
                        onClick={() => handleItemClick(2)}
                      >
                        {t("Institucional")}
                      </div>
                      <div
                        className={`camino-items ${
                          selectedItem === 3 ? "selected" : ""
                        }`}
                        onClick={() => handleItemClick(3)}
                      >
                        {t("Infraestructura")}
                      </div>
                    </div>
                  )}
                </Col>
                <Col className="second-sec" lg="6" md="12">
                  <div className="news-header-pv">
                    <div
                      className="back-sec"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/homepage");
                      }}
                    >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 className="main-title">{t("PROPUESTAS & VOTOS")}</h3>
                    <div className="search-icon" style={{ display: "flex" }}>
                      {searchActive && (
                        <div className="searchBarInput">
                          <input
                            className="search-tag-bar"
                            placeholder={t("Buscar")}
                            value={searchTerm}
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />
                        </div>
                      )}
                      <FiSearch
                        className="search-icon-click"
                        onClick={() => {
                          setSearchActive(!searchActive);
                        }}
                      />
                    </div>
                  </div>

                  <div className="proposal-vote-sec legend-tabs">
                    <Tabs
                      defaultActiveKey="generales"
                      id="fill-tab-example"
                      fill
                      onSelect={(eventKey) => {
                        if (eventKey === "DE INSIGNIA") {
                          setDain(true);
                          setCardSelected("insignia");
                        } else {
                          setCardSelected("general");
                          setDain(false);
                        }
                      }}
                    >
                      <Tab
                        eventKey="generales"
                        title={t("GENERALES")}
                        onSelect={() => {
                          setCardSelected("general");
                        }}
                      >
                        <div className="general-tab-sec">
                          {loading ? (
                            <div
                              style={{
                                "margin-top": "50px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <MoonLoader
                                color="white"
                                cssOverride={{}}
                                size={24}
                                speedMultiplier={1}
                              />
                            </div>
                          ) : general.length === 0 ? (
                            <div className="not-available-notice">
                              {t("No hay propuestas generales disponibles")}
                            </div>
                          ) : (
                            <Row>
                              {general.map((proposal, index) => (
                                <Col key={index} xs lg="4">
                                  <div
                                    className="general-card"
                                    onClick={() => {
                                      navigate("/editproposalvote", {
                                        state: proposal,
                                      });
                                    }}
                                  >
                                    <div className="card-header">
                                      <div className="card-imgs">
                                        <img
                                          src={
                                            proposal?.proposalData
                                              ? proposal.proposalData
                                                  ?.media_url[0]
                                              : "./img/exp4.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="card-dates-sec">
                                        <div className="created-date-sec">
                                          <p>{t("creada")}</p>
                                          <p>
                                            {formatDate(
                                              proposal.proposalData.created_at
                                            )}
                                          </p>
                                        </div>
                                        <div className="wins-date-sec">
                                          <p>{t("vence")}</p>
                                          <p>
                                            {formatDate(
                                              proposal.proposalData.expiring_on
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="content-sec">
                                        <div className="content-data">
                                          <p>{proposal.proposalData.content}</p>
                                        </div>
                                        <div className="like-sec">
                                          <GrLike />
                                          <span>
                                            {proposal.proposalData.likes}
                                          </span>
                                        </div>
                                        <div className="dislike-sec">
                                          <GrDislike />
                                          <span>
                                            {proposal.proposalData.dislikes}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="check-box-sec">
                                        <ul className="check-box-list">
                                          {proposal.optionsData.map(
                                            (option, optionIndex) => (
                                              <li
                                                key={optionIndex}
                                                className="list-content"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`option_${optionIndex}`}
                                                />
                                                <label>
                                                  <span></span>{" "}
                                                  {option.option_text}
                                                </label>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="bar-chart-sec">
                                        <h5 className="chart-heading">
                                          {t("Resultados")}
                                        </h5>
                                        <div className="chart-content">
                                          <BarChart
                                            optionsList={proposal.optionsData}
                                          />
                                          {/* You may replace this with your actual chart rendering logic */}
                                          {/* <img src="./img/bar-chart.png" alt="" /> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="DE INSIGNIA" title={t("DE INSIGNIA")}>
                        <div className="general-tab-sec">
                          {insignia.length === 0 ? (
                            <div className="not-available-notice">
                              {t("No hay insignias disponibles")}
                            </div>
                          ) : (
                            <Row>
                              {insignia.map((proposal, index) => (
                                <Col key={index} xs lg="4">
                                  <div
                                    className="general-card"
                                    onClick={() => {
                                      navigate("/editproposalvote", {
                                        state: proposal,
                                      });
                                    }}
                                  >
                                    <div className="card-header">
                                      <div className="card-imgs">
                                        <img
                                          src={
                                            proposal?.proposalData
                                              ? proposal.proposalData
                                                  ?.media_url[0]
                                              : "./img/exp4.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="card-dates-sec">
                                        <div className="card-logo">
                                          <img
                                            src={`./img/i${proposal?.proposalData?.insignia_id}.svg`}
                                            alt=""
                                          />
                                        </div>
                                        <div className="created-date-sec">
                                          <p>{t("creada")}</p>
                                          <p>
                                            {formatDate(
                                              proposal.proposalData.created_at
                                            )}
                                          </p>
                                        </div>
                                        <div className="wins-date-sec">
                                          <p>{t("vence")}</p>
                                          <p>
                                            {formatDate(
                                              proposal.proposalData.expiring_on
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="content-sec">
                                        <div className="content-data">
                                          <p>{proposal.proposalData.content}</p>
                                        </div>
                                        <div className="like-sec">
                                          <GrLike />
                                          <span>
                                            {proposal.proposalData.likes}
                                          </span>
                                        </div>
                                        <div className="dislike-sec">
                                          <GrDislike />
                                          <span>
                                            {proposal.proposalData.dislikes}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body">
                                      <div className="check-box-sec">
                                        <ul className="check-box-list">
                                          {proposal.optionsData.map(
                                            (option, optionIndex) => (
                                              <li
                                                key={optionIndex}
                                                className="list-content"
                                              >
                                                <input
                                                  type="checkbox"
                                                  id={`option_${optionIndex}`}
                                                />
                                                <label
                                                  htmlFor={`option_${optionIndex}`}
                                                >
                                                  <span></span>{" "}
                                                  {option.option_text}
                                                </label>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      <div className="bar-chart-sec">
                                        <h5 className="chart-heading">
                                          {t("Resultados")}
                                        </h5>
                                        <div className="chart-content">
                                          {/* You may replace this with your actual chart rendering logic */}
                                          {/* <img src="./img/bar-chart.png" alt="" /> */}
                                          <BarChart
                                            optionsList={proposal.optionsData}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                    <div className="proposal-btn-sec">
                      <Row>
                        <Col xs lg="6">
                          <div className="proposal-btns">
                            <button
                              className="green-btn"
                              onClick={() => {
                                navigate("/addproposalvote");
                              }}
                            >
                              {t("Agregar Propuesta")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" lg="3" md="12">
                  <div className="ordenarPorOuter">
                    <div
                      className="ordenarPor"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDropVisi(!dropVisi);
                      }}
                    >
                      {t("ordenar por")}
                      <span>
                        <IoIosArrowDown />
                      </span>
                    </div>

                    {dropVisi && (
                      <div className="options-bm2" ref={dropdownRef}>
                        <div
                          onClick={(e) => {
                            handleDropDownSel("created");
                            setDropVisi(false);
                          }}
                          value="created"
                          style={{
                            cursor: "pointer",
                            color:
                              optionSel === "created" ? "#C37704" : "inherit",
                          }}
                        >
                          {t("Fecha creación")}
                        </div>
                        <div
                          onClick={(e) => {
                            handleDropDownSel("expiry");
                            setDropVisi(false);
                          }}
                          value="expiry"
                          style={{
                            cursor: "pointer",
                            color:
                              optionSel === "expiry" ? "#C37704" : "inherit",
                          }}
                        >
                          {t("Fecha vencimiento")}
                        </div>
                        <div
                          onClick={(e) => {
                            handleDropDownSel("popular");
                            setDropVisi(false);
                          }}
                          value="popular"
                          style={{
                            cursor: "pointer",
                            color:
                              optionSel === "popular" ? "#C37704" : "inherit",
                          }}
                        >
                          {t("Más popular")}
                        </div>
                        <div
                          onClick={(e) => {
                            handleDropDownSel("liked");
                            setDropVisi(false);
                          }}
                          value="liked"
                          style={{
                            cursor: "pointer",
                            color:
                              optionSel === "liked" ? "#C37704" : "inherit",
                          }}
                        >
                          {t("Más querida")}
                        </div>
                        <div
                          onClick={(e) => {
                            handleDropDownSel("hated");
                            setDropVisi(false);
                          }}
                          value="hated"
                          style={{
                            cursor: "pointer",
                            color:
                              optionSel === "hated" ? "#C37704" : "inherit",
                          }}
                        >
                          {t("Más odiada")}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalAndVotes;
