import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Navigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAllScopesGoogle } from "@react-oauth/google";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
// import { atob } from 'buffer';
// const axios = require('axios');
import axios from "axios";
import { Base64 } from "js-base64";
import MyStatefulEditor from "../testing/textfields";
import RichTextEditor from "react-rte";
import { Buffer } from "buffer";
import Swal from "sweetalert2";
import "./testing.css";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

function Testing() {
  const language = useSelector((state) => state.language.value);
  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const navigate = useNavigate();
  const [code, setcode] = useState("");
  const [email, setemail] = useState("ashay.t@cisinlabs.com");
  const [token, setToken] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [msgBodyHtml, setMsgBodyHtml] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showHtml, setShowHtml] = useState(false);
  const [showcompose, setShowcompose] = useState(false);
  const [mails, setMails] = useState(true);

  const [data, setData] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [disabled, setDisable] = useState(false);

  const [subjectSel, setSubjectSel] = useState("");
  const [fromSel, setFromSel] = useState("");
  const [dateSel, setDateSel] = useState("");
  const [length, setlength] = useState("");
  const [trash, setTrash] = useState("");

  const [selectedCat, setSelectedCat] = useState("INBOX");

  const location = useSearchParams();
  const params = useSearchParams();

  const [reports, setReports] = useState([]);
  const [repdetails, setRepdetails] = useState({
    type: "",
    id: "",
    reason: "",
    by: "",
  });
  const [showRepDet, setShowRepDet] = useState(false);
  const [page, setPage] = useState(1);
  const [isAttatched, setIsAttatched] = useState(true);

  const [ids, setIds] = useState([]);
  const [attatchments, setAttatchments] = useState("");

  const [data2, setData2] = useState([]);
  const [loadMoreSta, setloadMoreSta] = useState(false);

  const [title, setTitle] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [body, setBody] = useState("");

  const [inboxCount, setInboxCount] = useState(0);
  const [spamCount, setSpamCount] = useState(0);
  const [sentCount, setSentCount] = useState(0);
  const [trashCount, setTrashCount] = useState(0);

  const [inbox, setInbox] = useState();
  const [spam, setSpam] = useState();
  const [sent, setSent] = useState();
  const [totrash, setTotrash] = useState();
  const [checkedIndex, setCheckedIndex] = useState();

  const [inboxpt, setInboxpt] = useState();
  const [spampt, setSpampt] = useState();
  const [sentpt, setSentpt] = useState();
  const [trashpt, setTrashpt] = useState();

  const senders = ["hello@mail.grammarly.com", "team@blackbox.chat"];
  const query = senders.map((sender) => `from:${sender}`).join(" OR ");

  // useEffect(() => {
  //   console.log("ahsgdahs", location);
  //   // window.location.href.target = "_blank";
  //   // console.log('window.location.href.target: ', window.location.href.target)
  //   window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.metadata%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.modify&response_type=code&client_id=966254046099-3b98aektfjomkceek2uqnbuhp6v2ch20.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftesting';

  //   console.log("next:", location);
  //   console.log("params:: ", params);
  // }, [])

  // const login = useGoogleLogin({

  //   onSuccess: codeResponse => {
  //     console.log(codeResponse);
  //     const token = codeResponse.code
  //     localStorage.setItem('Auth-code', codeResponse.code);
  //     setcode(codeResponse.code);
  //     console.log('codeResponse.code :: ', codeResponse.code)
  //     // setemail(codeResponse.hd);
  //     fetchOAuthToken(token);
  //   },
  //   flow: 'auth-code',
  // });

  const handleCheckboxChange = (item, index) => {
    setCheckedIndex(index);
    setTotrash(item.id);
  };
  async function fetchOAuthToken(q) {
    const email = localStorage.getItem("email");
    const url =
      "https://development-dot-mess-ice-app.uc.r.appspot.com/api/dev/mailbox/oauth_token";
    const data = {
      email: email,
      code: q,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("OAuth Token:", result);
      localStorage.setItem("google-auth-token", result.data.accessToken);
      localStorage.setItem(
        "google-auth-refreshtoken",
        result.data.refreshToken
      );

      fetchMessages();
      fetchTrashMessages();
      fetchSentMessages();
      fetchSpamMessages();
      // Handle the result as needed
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
      // Handle the error as needed
    }
  }

  function extractEmailAddress(fullString) {
    const emailMatch = fullString.match(/<([^>]+)>/);
    return emailMatch ? emailMatch[1] : fullString;
  }

  function findDataInParts(parts) {
    for (let part of parts) {
      if (part.body && part.body.data) {
        return part.body.data;
      }
      if (part.parts && part.parts.length > 0) {
        let nestedData = findDataInParts(part.parts);
        if (nestedData) {
          return nestedData;
        }
      }
    }
    return null;
  }

  function findDataInParts(parts, results = { data: null, images: [] }) {
    for (let part of parts) {
      if (part.body && part.body.data) {
        if (part.mimeType && part.mimeType.startsWith("image/")) {
          results.images.push({
            mimeType: part.mimeType,
            data: part.body.data,
            filename: part.filename || "image",
          });
        } else if (!results.data) {
          results.data = part.body.data;
        }
      }
      if (part.parts && part.parts.length > 0) {
        findDataInParts(part.parts, results);
      }
    }
    return results;
  }

  function decodeEmailBody(result) {
    let encodedData = result.payload.body.data;
    console.log(`ed`, encodedData);

    let decodedData = "";
    let images = [];

    if (encodedData) {
      decodedData = Base64.decode(encodedData);
      setMsgBodyHtml(decodedData);
      console.log(`html`, decodedData);
    } else if (result.payload.parts && result.payload.parts.length > 0) {
      let dataAndImages = findDataInParts(result.payload.parts);
      encodedData = dataAndImages.data;
      images = dataAndImages.images;

      if (encodedData) {
        decodedData = Base64.decode(encodedData);
        setMsgBodyHtml(decodedData);
        console.log(`html of parts`, decodedData);
      } else {
        console.error("No data found in email parts");
      }
    } else {
      console.error("No data found in email body or parts");
    }

    displayImages(images);
  }

  function displayImages(images) {
    images.forEach((image, index) => {
      let imageBlob = Base64.decode(image.data);
      let mimeType = image.mimeType;
      let filename = image.filename;

      let imgElement = document.createElement("img");
      imgElement.src = `data:${mimeType};base64,${image.data}`;
      imgElement.alt = filename;
      document.body.appendChild(imgElement); // Or append to a specific element
      console.log(`Image ${index + 1}:`, filename, mimeType);
    });
  }

  // Example usage
  // Assuming `result` is the email object fetched from the Google API

  const showSeperateEmails = async (item) => {
    console.log("item ", item);
    setShowHtml(true);
    setShowcompose(false);
    setMails(false);
    const url = `https://gmail.googleapis.com/gmail/v1/users/me/messages/${item.id}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });

      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("Google Response: ", result);
      decodeEmailBody(result);

      result.payload.headers.forEach((header) => {
        if (header.name.toLowerCase() === "subject") {
          setSubjectSel(header.value);
        }
      });

      result.payload.headers.forEach((header) => {
        if (header.name.toLowerCase() === "from") {
          console.log("header. from ", header.value);
          const mail = extractEmailAddress(header.value);
          setFromSel(mail);
        }
      });

      result.payload.headers.forEach((header) => {
        if (header.name.toLowerCase() === "date") {
          console.log("header.Date(): ", header.value);
          setDateSel(header.value);
        }
      });

      // var decodedData = atob(encodedData);
      // console.log(decodedData,"decodedData");
      // Handle the result as needed
      return result;
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
      // Handle the error as needed
    }
  };

  function getAttachments_(files) {
    console.log(`files rec`, files);
    var att = [];
    for (var file in files) {
      const base64ImageData = Buffer.from(file).toString("base64");
      att.push(
        // {
        //   mimeType: file.getMimeType(),
        //   fileName: file.getName(),
        //   bytes: Buffer.from(file.getBlob().getBytes()).toString("base64"),
        // }
        {
          type: "image/jpeg",
          name: "id2.jpg",
          data: base64ImageData,
        }
      );
    }
    return att;
  }
  function sendEmailWithAttachments() {
    var message = {
      to: {
        name: "Google Scripts",
        email: "yogendra.r@cisinlabs.com",
      },
      body: {
        text: "Mr hänn is schon lang nümme g'she.",
        html: "Mr hänn is schon <b>lang nümme</b> g'she.",
      },
      subject: "ctrlq, tech à la carte",
      files: getAttachments_(attatchments),
    };
    return message;
  }
  async function sendMessages() {
    if (to === "" || title === "" || body === "") {
      Swal.fire("Cada campo requerido");
      return;
    }

    const url = `https://gmail.googleapis.com/gmail/v1/users/me/messages/send`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;

    // const to = "yogendra.r@cisinlabs.com";
    const subject = title;
    // const body = "ddf fdsf dsf dsf dsf s"

    const messageWithAtt = sendEmailWithAttachments();

    const message = `To: ${to}\nCc:${cc}\nSubject: ${subject}\n\n${body}`;

    const raw = Buffer.from(message).toString("base64");
    console.log(raw);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "message/rfc822",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
        body: JSON.stringify({ raw: raw }),
        // body: JSON.stringify({ "raw": raw, "payload": { "filename":  getAttachments_(attatchments), "mimeType": "" } }),
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }
      if (response.ok) {
        Swal.fire("Enviado exitosamente");
      }

      const result = await response.json();
      console.log("Google Response of sending emails: ", result);
      console.log(result);
      if (result.labelIds[0] === "SENT") {
        Swal.fire("Mail sent successfully");
      }

      // Handle the result as needed
      // return result;
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
      // Handle the error as needed
    }
  }
  async function sendMesswithAttachment() {
    const apiUrl =
      "https://development-dot-mess-ice-app.uc.r.appspot.com/api/dev/mailbox/sendEmailWithAttachement";
    const authToken = localStorage.getItem("token");

    const formData = new FormData();
    console.log(`file selected`, attatchments);
    formData.append("files", attatchments); // Replace with the actual file path
    for (var i = 0; i < attatchments.length; i++) {
      formData.append("files", attatchments[i]);
    }
    formData.append("to", to);
    formData.append("subject", title);
    formData.append("body", body);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Email sent successfully:", result);
        setMails(true);
        setShowcompose(false);
        setShowHtml(false);
        // fetchTrashMessages();
        setData2(trash);
        setSelectedCat("TRASH");
        setPage(1);
        Swal.fire("Enviado exitosamente");
      } else {
        const error = await response.json();
        console.error("Error sending email:", error);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  }

  async function fetchMessages() {
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=INBOX&maxResults=5000`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    // const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=${labelId}&maxResults=${maxResults}&pageToken=${pageToken}`
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }
      const result = await response.json();
      console.log("Google Response: ", result);
      // setData2(result.threads);
      setInbox(result.threads);
      setData2(result.threads);
      setIds([result.threads]);
      setInboxpt(result.nextPageToken);
      setInboxCount(result.resultSizeEstimate);
      console.log(result.threads);
      console.log("length from messege fetch :", length);
      setlength(result.threads.length);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  const trashMail = async () => {
    if (totrash === "") {
      Swal.fire("Seleccione Correo primero");
      return;
    }
    setShowHtml(true);
    setShowcompose(false);
    setMails(false);

    const url = `https://gmail.googleapis.com/gmail/v1/users/me/messages/${totrash}/trash`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });

      if (!response.ok) {
        console.log("serve error");
        return;
      }

      if (response.ok) {
        Swal.fire("Correo tirado a la basura");
      }
      const result = await response.json();
      console.log("Google Trash response ", result);

      // setMsgBodyHtml(decodedData);
      // console.log(result.payload.headers[3].value);
      // console.log(result.payload.headers[6].value);
      // console.log(result.payload.headers[4].value);

      // setSubjectSel(result.payload.headers[3].value);
      // setFromSel(result.payload.headers[6].value);
      // setDateSel(result.payload.headers[4].value);

      // var decodedData = atob(encodedData);
      // console.log(decodedData,"decodedData");
      // Handle the result as needed
      return result;
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
      // Handle the error as needed
    }
  };
  async function fetchTrashMessages() {
    const url = `https://gmail.googleapis.com/gmail/v1/users/me/threads?labelIds=TRASH&maxResults=5000`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }
      const result = await response.json();
      console.log("Google Response for TRASH ", result);
      // setData2(result.threads);
      setTrash(result.threads);
      setIds([result.threads]);
      setTrashpt(result.nextPageToken);
      setTrashCount(result.resultSizeEstimate);
      console.log(result.threads);
      console.log("length from messege fetch :", length);
      setlength(result.threads.length);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  async function fetchSentMessages() {
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=SENT&maxResults=5000`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }
      const result = await response.json();
      console.log("Google Response: ", result);
      // setData2(result.threads);
      setSent(result.threads);
      setIds([result.threads]);
      setSentCount(result.resultSizeEstimate);
      setSentpt(result.nextPageToken);
      console.log(result.threads);
      console.log("length from messege fetch :", length);
      setlength(result.threads.length);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  async function fetchSpamMessages() {
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=SPAM&maxResults=5000`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }
      const result = await response.json();
      console.log("Google Response: ", result);
      // setData2(result.threads);
      setSpam(result.threads);
      setIds([result.threads]);
      setSpamCount(result.resultSizeEstimate);
      setSpampt(result.nextPageToken);
      console.log(result.threads);
      console.log("length from messege fetch :", length);
      setlength(result.threads.length);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }

  // Insert file attachments from Google Drive

  // const setStateData = ()=>{
  //   const promise = new Promise()

  // }

  useEffect(() => {
    const q = searchParams.get("code");
    if (!q) {
      return;
    }
    localStorage.setItem("code", q);
    console.log(q);
    setcode(q);
    fetchOAuthToken(q);

    if (q) {
      console.log("Code: ", q);
      fetchMessages();
      fetchTrashMessages();
      fetchSentMessages();
      fetchSpamMessages();
    }
  }, []);
  useEffect(() => {
    console.log(`files selected`, attatchments);
  }, [attatchments]);
  async function fetchPagSentMess() {
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds="SENT"?pageToken="${sentpt}"`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("Google Response: ", result);
      setData2(result.threads);
      console.log("length from page switch: ", length);
      setlength(result.threads.length);
      setIds([result.threads]);
      setSentpt(result.nextPageToken);
      console.log(result.threads);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  async function fetchPagSpamMess() {
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=SPAM?pageToken=${spampt}`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("Google Response: ", result);
      setData2(result.threads);
      console.log("length from page switch: ", length);
      setlength(result.threads.length);
      setIds([result.threads]);
      setSpampt(result.nextPageToken);
      console.log(result.threads);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  async function fetchPagTrashMess() {
    const url = `https://gmail.googleapis.com/gmail/v1/users/me/threads?labelIds=TRASH?pageToken=${trashpt}`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("Google Response: ", result);
      setData2(result.threads);
      console.log("length from page switch: ", length);
      setlength(result.threads.length);
      setIds([result.threads]);
      setTrashpt(result.nextPageToken);
      console.log(result.threads);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }
  async function fetchPagInboxMess() {
    console.log("fetchPagInboxMess executed : ");

    if (inboxpt === null || inboxpt === "") {
      return;
    }
    const labelId = "INBOX";
    const maxResults = 500;
    // const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=INBOX`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=${labelId}&maxResults=${maxResults}&pageToken=${inboxpt}`;
    // const url = `https://www.googleapis.com/gmail/v1/users/me/threads?labelIds=INBOX?pageToken=${inboxpt}`;
    // const url = `https://gmail.googleapis.com/gmail/v1/users/ashay.t@cisinlabs.com/threads`;
    try {
      console.log("url executed : ");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(
            "google-auth-token"
          )}`,
        },
      });
      if (!response.ok) {
        console.log("serve error");
        return;
      }

      const result = await response.json();
      console.log("Google Response: ", result);
      setInbox((prevD) => [...prevD, result.threads]);
      setData2((prevD) => [...prevD, result.threads]);
      console.log("length from page switch: ", length);
      setlength(result.threads.length);
      setIds([result.threads]);
      setInboxpt(result.nextPageToken);
      console.log(result.threads);
    } catch (error) {
      console.error("Error fetching OAuth Token:", error.message);
    }
  }

  useEffect(() => {
    // if (page > 1) {
    //   console.log("Selected Category: ", selectedCat);
    //   switch (selectedCat) {
    //     case 'TRASH': return fetchPagTrashMess();
    //     case 'SPAM': return fetchPagSpamMess();
    //     case 'SENT': return fetchPagSentMess();
    //     default: return fetchPagInboxMess();
    //   }
    // }

    console.log("Selected Category: ", selectedCat);
    switch (selectedCat) {
      case "TRASH":
        return fetchPagTrashMess();
      case "SPAM":
        return fetchPagSpamMess();
      case "SENT":
        return fetchPagSentMess();
      default:
        fetchPagInboxMess();
    }
  }, [page]);

  const handleOnChange = (newValue) => {
    // console.log('newValue: ', newValue)
    // setValue(newValue);
    console.log("newValue.toString", newValue.toString("html"));
    // setMailContent(newValue.toString('html'))
    setBody(newValue.toString("html"));
    // if (onChange) {
    //   // Send the changes up to the parent component as an HTML string.
    //   // This is here to demonstrate using `.toString()` but in a real app it
    //   // would be better to avoid generating a string on each change.
    //   onChange(newValue.toString('html'));
    // }
  };

  return (
    <React.Fragment>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="Digital-sec">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col xs lg="3"></Col>
                <Col xs lg="6">
                  <div className="news-header">
                    <div
                      className="back-sec"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/homepage");
                      }}
                    >
                      <img src="./img/volver.png" alt="" />
                    </div>
                    <h3 className="header-title">{t("BANDEJA DE CORREO")}</h3>
                    <div className="search-icon">{/* <FiSearch /> */}</div>
                  </div>
                  <div className="digital-table digitalInner-table">
                    <div className="digitalTable-left">
                      <div className="digitalTable-head">
                        <div
                          onClick={() => {
                            setShowcompose(true);
                            setMails(false);
                            setShowHtml(false);
                          }}
                          className="blue-email-button"
                        >
                          + {t("Correo Nuevo")}
                        </div>
                      </div>
                      <div className="digitalLeft-body">
                        <ul className="digital-tudo-list">
                          <li
                            onClick={() => {
                              setMails(true);
                              setShowcompose(false);
                              setShowHtml(false);
                              // fetchMessages();
                              setData2(inbox);
                              setSelectedCat("INBOX");
                              setPage(1);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">{t("Entrada")}</span>
                              <span
                                className="tudo-counter"
                                onClick={() => {
                                  setMails(true);
                                  setShowHtml(false);
                                }}
                              >
                                {inboxCount}
                              </span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setMails(true);
                              setShowcompose(false);
                              setShowHtml(false);
                              // fetchSpamMessages();
                              setData2(spam);
                              setSelectedCat("SPAM");
                              setPage(1);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">
                                {t("Borradores")}
                              </span>
                              <span className="tudo-counter">{spamCount}</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setMails(true);
                              setShowcompose(false);
                              setShowHtml(false);
                              // fetchSentMessages();
                              setData2(sent);
                              setSelectedCat("SENT");
                              setPage(1);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">{t("Enviados")}</span>
                              <span className="tudo-counter">{sentCount}</span>
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              setMails(true);
                              setShowcompose(false);
                              setShowHtml(false);
                              // fetchTrashMessages();
                              setData2(trash);
                              setSelectedCat("TRASH");
                              setPage(1);
                            }}
                          >
                            <Link>
                              <span className="tudo-name">{t("Papelera")}</span>
                              <span className="tudo-counter">{trashCount}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {showHtml && (
                      <div className="digital-right">
                        <div className="digitalTable-head d-flex flex-wrap">
                          <div className="digihead-left">
                            <p className="mb-0" style={{ fontSize: "12px" }}>
                              {t("Asunto")}
                            </p>
                          </div>
                          <div className="digihead-right">
                            {/* <p className="mb-0">{subjectSel}</p> */}
                            <p
                              className="mb-0"
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{ __html: subjectSel }}
                            ></p>
                          </div>
                        </div>

                        <div className="digitalRight-body">
                          <ul className="Digiright-table p-0 mb-0">
                            <li>
                              <div className="digitalId-div">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {t("De")}
                                </p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {fromSel}
                                    </p>
                                  </div>
                                  <div className="digiRight-text">
                                    {/* <p className="mb-0">{dateSel}</p> */}
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="digitalId-div">
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "12px" }}
                                >
                                  {t("Fecha")}
                                </p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {dateSel}
                                    </p>
                                  </div>
                                  <div className="digiRight-text">
                                    <p className="mb-0">{repdetails.reason}</p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="digipost-sec">
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{ __html: msgBodyHtml }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {mails && (
                      <div className="digital-right">
                        <div className="digitalTable-head">
                          <p className="mb-0">{t("BANDEJA DE ENTRADA")}</p>
                        </div>
                        <div className="digitalRight-body">
                          <ul className="Digiright-table p-0 mb-0">
                            {data2.map((item, index) => (
                              <li key={index}>
                                <div className="checkbox-div">
                                  <input
                                    type="checkbox"
                                    checked={index === checkedIndex}
                                    onChange={() =>
                                      handleCheckboxChange(item, index)
                                    }
                                  ></input>
                                </div>
                                <div
                                  onClick={() => {
                                    showSeperateEmails(item);
                                  }}
                                  className={
                                    true
                                      ? "digiRight-content-outer"
                                      : "digiRight-content-outer-unread"
                                  }
                                >
                                  <div
                                    className="digiRight-content"
                                    onClick={() => {
                                      setShowRepDet(true);

                                      // setRepdetails({
                                      //   'id': item.report_id,
                                      //   'reason': item.reason,
                                      //   'by': item.user.first_name,
                                      // });
                                    }}
                                  >
                                    <div className="digiRight-report">
                                      <p className="mb-0">{index + 1}</p>
                                    </div>
                                    <div className="digiRight-text">
                                      <p
                                        className="mb-0"
                                        style={{ fontSize: "12px" }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item.snippet.length > 70
                                              ? `${item.snippet.slice(
                                                  0,
                                                  70
                                                )}...`
                                              : item.snippet,
                                        }}
                                      >
                                        {/* {item.snippet.length > 70
                                          ? `${item.snippet.slice(0, 70)}...`
                                          : item.snippet} */}
                                      </p>
                                      {/* <p className='mb-0'>{item.snippet}</p> */}
                                    </div>
                                  </div>
                                  <div className="digiright-time">
                                    <p className="mb-0"></p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {/* <div className="loadMoreButton">
                            <button onClick={() => { fetchMessages() }}>Load More</button>
                          </div> */}
                        </div>
                      </div>
                    )}
                    {showcompose && (
                      <div className="digital-right">
                        <div className="digitalTable-head d-flex flex-wrap">
                          <div className="digihead-left">
                            <p className="mb-0">{t("Asunto")}</p>
                          </div>
                          <div className="digihead-right">
                            <p className="mb-0">
                              <input
                                className="inputEmails"
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                }}
                                value={title}
                              />
                            </p>
                          </div>
                        </div>

                        <div className="digitalRight-body">
                          <ul className="Digiright-table p-0 mb-0">
                            <li>
                              <div className="digitalId-div">
                                <p className="mb-0">{t("Para")}</p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p className="mb-0">
                                      <input
                                        className="inputEmails"
                                        value={to}
                                        onChange={(e) => {
                                          setTo(e.target.value);
                                        }}
                                      />
                                    </p>
                                  </div>
                                  <div className="digiRight-text">
                                    <p className="mb-0">{repdetails.reason}</p>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="digitalId-div">
                                <p className="mb-0">CC</p>
                              </div>
                              <div className="digiRight-content-outer">
                                <div className="digiRight-content">
                                  <div className="digiRight-report">
                                    <p className="mb-0">
                                      <input
                                        className="inputEmails"
                                        value={cc}
                                        onChange={(e) => {
                                          setCc(e.target.value);
                                        }}
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className="digipost-sec">
                            {/* <textarea value={body} onChange={(e)=>{setBody(e.target.value)}}/> */}
                            {/* <RichTextEditor
                              value={body}
                              onChange={handleOnChange}
                            /> */}
                            <textarea
                              className="textareaGmail"
                              value={body}
                              onChange={(e) => {
                                setBody(e.target.value);
                              }}
                            />
                            <input
                              type="file"
                              multiple
                              onChange={(e) => {
                                setAttatchments(e.target.files);
                                setIsAttatched(true);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="pagination-mailing">
                    <button
                      className="prev-but"
                      onClick={() => {
                        if (page > 1) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {"<"}
                    </button>
                    <div className="page-num">{page}</div>
                    <button
                      className="for-but"
                      disabled={disabled}
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    >
                      {">"}
                    </button>
                  </div> */}

                  <div className="billboard-btn-sec">
                    {/* <button className='green-btn' onClick={() => { }}>ACTUALIZAR</button> */}
                    <button
                      className="red-btn"
                      onClick={() => {
                        trashMail();
                      }}
                    >
                      {t("ELIMINAR")}
                    </button>
                  </div>
                </Col>
                <Col xs lg="3"></Col>
              </Row>
            </Container>
          </div>

          <div className="proposal-btns">
            {showcompose && (
              <button
                disabled={!totrash}
                className="green-btn"
                onClick={() => {
                  if (attatchments.length > 0) {
                    sendMesswithAttachment();
                  } else {
                    sendMessages();
                  }
                }}
              >
                {t("ENVIAR")}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Testing;
