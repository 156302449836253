import { Col, Container, Row } from 'react-bootstrap';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { IoIosArrowUp } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import "react-calendar/dist/Calendar.css";
import { FiSearch } from "react-icons/fi";
import React, { useEffect } from 'react';
import { socket } from '../../../socket';
import { useState } from 'react';
import Swal from 'sweetalert2';
import "./style.css";
import GlobalURL from '../../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../../locals/translation';


function BoardMeeting() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [first, setFirst] = useState(true);
    const [addFormDebate, setAddFormDebate] = useState(false);
    const handleClose = () => setAddFormDebate(false);
    const handleShow = () => setAddFormDebate(true);
    const [addFormSmallTab, setAddFormSmallTab] = useState(false);
    const handleCloseTab = () => setAddFormSmallTab(false);
    const handleShowTab = () => setAddFormSmallTab(true);
    const [allMemberList, setAllMemberList] = useState();
    const [errorMsg, setErrorMsg] = useState(false);
    const [addMemberList, setAddMemberList] = useState([]);
    const [selectedMember, setSelectedMember] = useState();
    const [allBoardMeetings, setAllBoardMeetings] = useState(null);
    // const [createDebate, setCreateDebate] = useState({
    //     board_name: "",
    //     board_member_ids: [],
    //     board_meeting_description: ""
    // })
    // const [createDebate, setCreateDebate] = useState({
    //     team_id: -1,
    //     team_channel_name: '',
    //     tc_member_ids: [localStorage.getItem('user_id')],
    //     tc_description: '',
    //     tc_icon: ''
    // })
    // {   team_id,
    //     team_channel_name,
    //     tc_member_ids:[localStorage.getItem('user_id')],
    //     tc_description,
    //     tc_icon }

    const [allSmallTable, setAllSmallTable] = useState();
    const [insigniaList, setInsigniaList] = useState();
    const [selectedInsignia, setSelectedInsignia] = useState();
    const [selectionStatus, setSelectionStatus] = useState();
    const [smallTabUser, setSmallTabUser] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [createSmallTab, setCreateSmallTab] = useState({
        small_table_name: "",
        small_table_description: "",
        insignia_icon: null,
        small_table_member_ids: '',
        insignia_id: 0,
        proposal_title: "",
        team_id: '',
    })


    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const [teams, setTeams] = useState([]);

    const [preview, setPreview] = useState('');

    const handleSelectedInsignia = (e, insignia_data) => {
        if (selectionStatus) {
            selectionStatus.classList.remove("highlight");
        }
        setSelectionStatus(e.target.closest(".insignia-item"));
        e.target.closest(".insignia-item").classList.add("highlight");
        setSelectedInsignia(insignia_data);
    }
    // handle meething data

    const handleSelectedMember = (index, id) => {
        // Toggle the selected state for the clicked item
        setSelectedItems((prevSelectedItem) => (prevSelectedItem === index ? null : index));

        // Update the team_id property in createSmallTab
        setCreateSmallTab((prevCreateSmallTab) => ({
            ...prevCreateSmallTab,
            team_id: id,
        }));
    };

    // const handleMeetingData = (e) => {
    //     if (first)
    //         setCreateDebate({ ...createDebate, [e.target.name]: e.target.value });
    //     else
    //         setCreateSmallTab({ ...createSmallTab, [e.target.name]: e.target.value });
    // }

    // handle - socket connection
    useEffect(() => {
        socket.connect();
        // socket.emit('connect_user', { user_id: localStorage.getItem("user_id"), role_id: 3 });
        console.log(`connect_user`);
        socket.emit('connect_user', { user_id: localStorage.getItem("user_id") });
        const boardMeeting = (data) => {
            console.log('team_channels:', data);
            setAllBoardMeetings(data.team_channels);
        }
        // const smallTable = (data) => {
        //     console.log('smalltable meetings:', data.smalltable);
        //     setAllSmallTable(data.smalltable);
        // }
        socket.on('team_channels_list', boardMeeting);
        // socket.on('small_table_list', smallTable);
        return () => {
            socket.off('board_meeting_list', boardMeeting);
            // socket.off('small_table_list', smallTable);
        };
    }, [])


    // handle socket - create board meeting
    // const handleBoardMeeting = () => {
    //     if (first) {
    //         console.log("Group details ", createDebate);

    //         if (createDebate.board_name
    //             && createDebate.proposal_title
    //             && createDebate.small_table_member_ids
    //             && createDebate.small_table_description) {
    //             socket.emit('create_small_table', createDebate);
    //             handleCloseTab();
    //             Swal.fire("success", "Group created successfully!", "success");
    //         }
    //         else {
    //             Swal.fire("Error", "Required all fields!", "error");
    //         }
    //     }
    //     else {
    //         if (smallTabUser?.length) {
    //             let userIdList = smallTabUser.map((item) => {
    //                 return item.user_id
    //             })
    //             createSmallTab.small_table_member_ids = userIdList;
    //             console.log("small tab user ID List: ", userIdList);
    //         }
    //         if (createSmallTab.small_table_name
    //             && selectedInsignia
    //             // && createSmallTab.board_meeting_description
    //             && createSmallTab.proposal_title) {

    //             createSmallTab.insignia_id = selectedInsignia.insignia_id;
    //             createSmallTab.insignia_icon = selectedInsignia.insignia_icon;
    //             console.log("small tabs:  ", createSmallTab);
    //             socket.emit('create_small_table', createSmallTab);
    //             handleCloseTab();
    //             Swal.fire("success", "Group created successfully!", "success");
    //         }
    //         else {
    //             Swal.fire("Error", "Required all fields!", "error");
    //         }
    //     }
    // }
    //handle socket - get board meetings
    // useEffect(() => {
    //     const boardMeeting = (data) => {
    //         console.log('board meetings:', data.boardmeeting);
    //         setAllBoardMeetings(data.boardmeeting);
    //     }
    //     if (first) {
    //         socket.emit('get_board_meeting_list');
    //         socket.on('board_meeting_list', boardMeeting);
    //     }
    //     else {
    //         // socket.emit('small_table_list');
    //     }
    //     return () => {
    //         socket.off('board_meeting_list', boardMeeting);
    //     };
    // }, [first])

    //handle navigate - group chat
    // const handleGetDebateChat = (inbox_id) => {
    //     if (inbox_id) {
    //         return console.log("need inbox_id");
    //     }
    //     navigate('/debatechatroom', { state: inbox_id });
    // }

    //handle ui - add user list
    const handleAddUsers = () => {
        let div = document.querySelector(".meeting-user");
        div.classList.toggle("add-list-active");
    }
    const handleInsignia = () => {
        let div = document.querySelector(".small-table-insignia");
        div.classList.toggle("add-list-active");
    }
    // handle render data
    useEffect(() => {
        console.log("handle add paricepents called...");
        fetch(`${GlobalURL}/api/dev/app_moderator/getAllUserByTeam`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
            },
            body: JSON.stringify({ team_id: localStorage.getItem("user_id") })
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                    // Swal.fire("error", "Not able to get particepants list", "error");
                }
            })
            .then(res => {
                setErrorMsg(true)
                setAllMemberList(res.data);
                console.log('res.data: ', res.data);
            })
            .catch((err) => {
                console.log("error occured: ", err);
            });
        // if (allMemberList) {
        // console.log("get digital manager: ", allMemberList);
        // const memberList = document.querySelector(".add-users-list");
        // memberList.classList.add("add-member-active");
        // }
    }, [])
    const handleSelectMember = (e, user_data) => {
        if (selectedMember) {
            selectedMember.classList.remove("highlight");
        }
        setSelectedMember(e.target.closest(".legendlist"));
        e.target.closest(".legendlist").classList.add("highlight");
        if (first)
            setAddMemberList([...addMemberList, user_data.user]);
        else
            setSmallTabUser([...smallTabUser, user_data.user]);
    }

    async function getTeamData() {
        try {
            const apiUrl = `${GlobalURL}/api/dev/app_moderator/getTeam`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Cookie': 'GCLB=CLfxtLnxxqrgAw',
                    'Content-Type': 'application/json',
                    "ngrok-skip-browser-warning": "69420",
                },
            });

            if (!response.ok) {
                Swal.fire('Server Error');
                return
            }

            const data = await response.json();
            console.log('Multiple teams ', data)
            setTeams(data.data);

        } catch (error) {
            console.error('Error during API call:', error);

        }
    }


    const hadleCreateTeamchannel = async () => {

        if (createSmallTab.insignia_icon === '' || createSmallTab.small_table_name === '' || createSmallTab.small_table_description === '' || createSmallTab.team_id === '') {
            Swal.fire('Cada campo requerido');
            return
        }
        try {
            const formData = new FormData();
            formData.append("media", createSmallTab.insignia_icon);
            formData.append("inbox_uid", "tc_1_National Team");
            const apiUrl = `${GlobalURL}/api/dev/debate/chat/sendmultimedia`;
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Cookie': 'GCLB=CLfxtLnxxqrgAw',
                    "ngrok-skip-browser-warning": "69420",
                },
                body: formData,
            });
            if (!response.ok) {
                Swal.fire('Server Error');
            }
            if (response.ok) {
                Swal.fire("success", "¡Grupo creado exitosamente!", "success");
                setAddFormDebate(false);
                const data = await response.json();
                console.log("Data of MEDIA URL", data.data);
                const MediaUrls = data.data;

                const teamChannelData = {
                    team_id: createSmallTab.team_id, // Replace with the appropriate type for team_id
                    team_channel_name: createSmallTab.small_table_name, // Replace with the appropriate type for team_channel_name
                    tc_member_ids: [], // Replace with the appropriate type for tc_member_ids
                    tc_description: createSmallTab.small_table_description, // Replace with the appropriate type for tc_description
                    tc_icon: MediaUrls // Replace with the appropriate type for tc_icon
                };
                console.log("Team channel data ", teamChannelData);
                socket.emit('create_team_channel', teamChannelData);
                handleCloseTab();


            }
        } catch (error) {
            console.error('Error during API call:', error);
        }

        // const MediaUrls = 'https://media.api-sports.io/football/teams/30.png';


    }

    useEffect(() => {
        fetch(`${GlobalURL}/api/dev/app_moderator/getAllInsignia`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    setErrorMsg(false)
                    return response.json();
                } else {
                    setErrorMsg(true)
                    // Swal.fire("error", "Not able to get particepants list", "error");
                }
            })
            .then(res => {
                setErrorMsg(true)
                setInsigniaList(res.data);
                console.log('res.data: ', res.data);
            })

            .catch((err) => {
                console.log("error occured: ", err);
            });
    }, [])  // remove
    useEffect(() => {
        getTeamData();
    }, [])

    const renderDirectiveComponent = () => {
        if (allBoardMeetings) {
            if (allBoardMeetings.length) {
                return (allBoardMeetings.map((item, i) => {
                    return (
                        <div className='cal-list-card-sec card_sec_list' key={i}
                            onClick={() => navigate("/debatechatroom", { state: { item } })}
                        >
                            <div className='cal-list-card'>
                                <div className='left-sec'>
                                    <div className='profile-img24'>
                                        {item?.group_image_url
                                            ? <img src={item?.group_image_url} alt="" />
                                            : <img src='./img/message.png' alt="" />
                                        }
                                    </div>
                                    <span className='event-data'>
                                        <h4>{item.group_name}</h4>
                                        <p>{item.group_description}</p>
                                    </span>
                                </div>
                                <div className='right-sec d-flex justify-content-end align-items-center'>

                                    <h3>{item.total_participants}</h3>
                                </div>
                            </div>
                        </div>
                    )
                }))
            }
            else {
                return (<div className='cal-list-card-sec'>
                    <div className="Loader d-flex justify-content-center mx-auto">
                        <div className="my-5" >
                            {t('¡No se encontraron reuniones de la junta directiva!')}
                        </div>
                    </div>
                </div>)
            }
        }
        else {
            return (<div className='cal-list-card-sec'>
                <div className="" style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="loading-adjust">
                        <div className="Loader d-flex justify-content-center align-items-center">
                            <div className="spinner-border spinner-border-sm my-5" role="status">
                                <span className="sr-only"></span>
                            </div>
                            <div className="my-5"> &nbsp; {t('Eliminando...')}</div>
                        </div>
                    </div>
                </div>
            </div>)
        }
    }

    const handleSmallTableNameChange = (event) => {
        setCreateSmallTab({ ...createSmallTab, small_table_name: event.target.value });
    };

    const handleInsigniaIdChange = (event) => {
        setCreateSmallTab({ ...createSmallTab, insignia_id: event.target.value });
    };

    const handleProposalTitleChange = (event) => {
        setCreateSmallTab({ ...createSmallTab, proposal_title: event.target.value });
    };

    const handleSmallTableMemberIdsChange = (event) => {
        setCreateSmallTab({ ...createSmallTab, small_table_member_ids: event.target.value });
    };

    const handleSmallTableDescriptionChange = (event) => {
        setCreateSmallTab({ ...createSmallTab, small_table_description: event.target.value });
    };


    const handleInsigniaIconChange = (event) => {
        const file = event.target.files && event.target.files[0];

        // Set the selected file to createSmallTab
        setCreateSmallTab({ ...createSmallTab, insignia_icon: file });

        // Generate a preview URL for the selected file
        setPreview(file ? URL.createObjectURL(file) : '');
    };


    return (
        <>
            <div className='calendar-list-sec-bm'>
                {
                    renderDirectiveComponent()
                }

                {
                    <Modal show={addFormDebate} className="debateroom-popup common-popup" onHide={handleClose} centered>

                        <Modal.Body>
                            <div class="form-group  mb-30 ">
                                <label class="mb-3">{t('NOMBRE DE LA ASAMBLEA GENERAL')}</label>
                                <input
                                    type="text"
                                    value={createSmallTab.small_table_name}
                                    onChange={handleSmallTableNameChange}
                                />
                            </div>

                            <div class="form-group  mb-30 ">
                                <label class="mb-3">{t('DESCRIPCIÓN DE LA ASAMBLEA GENERAL')}</label>
                                <input
                                    type="text"
                                    value={createSmallTab.small_table_description}
                                    onChange={handleSmallTableDescriptionChange}
                                />
                            </div>

                            <div className="cover-photo">
                                <div class="form-group  mb-30 ">
                                    <label class="mb-3">{t('FOTO DE PORTADA')}</label>
                                    <div className='add-file'>
                                        {preview ? (
                                            <img src={preview} alt="Preview" className='preview-img-icon' />
                                        ) : (
                                            <img src="./img/user.svg" alt="Default" />
                                        )}
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleInsigniaIconChange}
                                        />
                                    </div>
                                </div>
                                <div className='members-box'>
                                    <div className="members-head">
                                        <h6 className='mb-0'>{t('Membresías Habilitadas')}</h6>
                                    </div>
                                    <div className="members-body">
                                        <ul className='members-list'>
                                            {teams.map((item, index) => (
                                                <li
                                                    key={index}
                                                    className={`memberlistItem ${selectedItems === index ? 'selected' : ''}`}
                                                    onClick={() => handleSelectedMember(index, item.team_id)}
                                                >
                                                    <img src={item.team_logo_url} alt="" />
                                                    <span>{item.team_name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                        {/* <button className="blue-btn">+ {t('agregar membresía')}</button> */}
                                    </div>
                                    <div className="members-footer"></div>
                                </div>
                            </div>

                            <div className='news-btn-sec p-0 m-0 justify-content-between'>
                                <button className='green-btn rounded-pill' onClick={hadleCreateTeamchannel} >{t('CREAR')}</button>
                                <button className='red-btn rounded-pill' onClick={handleClose} >{t('CANCELAR')}</button>
                            </div>
                        </Modal.Body>

                    </Modal>
                }

                {/* <Modal show={addFormDebate} className="boardMeeting-popup common-popup"
                                            onHide={handleClose} centered>
                                            <Modal.Body >
                                                <div className='addboardingsec'>
                                                    <form>
                                                        <div className='blur-form-sec'>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">nombre de la REUNIÓN DE COMISIÓN DIRECTIVA</label>
                                                                <input className='blur-input'
                                                                    type="text" name='board_name'
                                                                    value={createDebate.board_name}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">DESCRIPCIÓN</label>
                                                                <input className='blur-input'
                                                                    type="text" name='board_meeting_description'
                                                                    value={createDebate.board_meeting_description}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">agrega dirigentes digitales</label>
                                                                <input className='blur-input' type="text" />
                                                            </div>
                                                            <div
                                                                className='blur-form-control d-flex blur-form-list text-center position-relative'
                                                                onClick={handleAddUsers}>
                                                                <img src="./img/cruz.png" alt="" />
                                                                {addMemberList?.length
                                                                    ? (
                                                                        addMemberList.map((member) => {
                                                                            return (<div className='user-add-members'>
                                                                                <img src={member.image_url
                                                                                    ? member.image_url
                                                                                    : 'https://picsum.photos/100/100'}
                                                                                    alt="" />
                                                                            </div>)

                                                                        })
                                                                    )
                                                                    : (
                                                                        <>
                                                                            <img src="./img/user.png" alt="" />
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='add-users-list meeting-user'>
                                                                <div className="legend-box">
                                                                    <ul className="">
                                                                        {allMemberList
                                                                            ? (allMemberList.length
                                                                                ? (
                                                                                    allMemberList.map((item, i) => {
                                                                                        return (<li id="4" className="legendlist"
                                                                                            key={i}
                                                                                            onClick={(e) => handleSelectMember(e, item)}
                                                                                            style={{ cursor: "pointer" }}>
                                                                                            <div className="legendprofile">
                                                                                                <img src={item.user.image_url
                                                                                                    ? item.user.image_url
                                                                                                    : 'https://picsum.photos/100/100'}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className="legendprofilename">
                                                                                                <span className="legPro-name mb-1">
                                                                                                    <strong>
                                                                                                        {item.user?.first_name}
                                                                                                        {item.user?.last_name}
                                                                                                    </strong>
                                                                                                </span>
                                                                                                <span className="legPro-username fw-light">
                                                                                                    {item.user.username
                                                                                                        ? `@${item.user.username}`
                                                                                                        : item.user?.email
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>)
                                                                                    })
                                                                                )
                                                                                : (
                                                                                    <li className='legendlist' >
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >No digital manager found!</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )
                                                                            : (errorMsg
                                                                                ? (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >Error Occured, try again!</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>

                                                                                )
                                                                                : (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>)
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer className="border-0">
                                                <div className="form-group text-center d-flex">
                                                    <button className="green-btn mb-0 rounded-pill" onClick={handleBoardMeeting}>CREAR</button>
                                                    <button className="red-btn mb-0 rounded-pill">CANCELAR</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal> */}

                {/* <Modal show={addFormSmallTab} className="boardMeeting-popup common-popup"
                                            onHide={handleCloseTab} centered>
                                            <Modal.Body >
                                                <div className='addboardingsec'>
                                                    <form>
                                                        <div className='blur-form-sec'>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">nombre de la REUNIÓN DE COMISIÓN DIRECTIVA</label>
                                                                <input className='blur-input'
                                                                    type="text" name='insignia_name'
                                                                    value={selectedInsignia?.insignia_name}
                                                                    onClick={handleInsignia} />
                                                            </div>

                                                            <div className='add-users-list small-table-insignia'>
                                                                <div className="legend-box">
                                                                    <ul className="">
                                                                        {insigniaList
                                                                            ? (insigniaList.length
                                                                                ? (
                                                                                    insigniaList.map((item, i) => {
                                                                                        return (<li id="4"
                                                                                            className="legendlist insignia-item"
                                                                                            key={i}
                                                                                            onClick={(e) => handleSelectedInsignia(e, item)}
                                                                                            style={{ cursor: "pointer" }}>
                                                                                            <div className="legendprofile">
                                                                                                <img src={item.insignia_icon
                                                                                                    ? item.insignia_icon
                                                                                                    : 'https://picsum.photos/100/100'}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className="legendprofilename">
                                                                                                <span className="legPro-name mb-1">
                                                                                                    <strong>
                                                                                                        {item.insignia_name}
                                                                                                    </strong>
                                                                                                </span>

                                                                                            </div>
                                                                                        </li>)
                                                                                    })
                                                                                )
                                                                                : (
                                                                                    <li className='legendlist' >
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >No insignia found!</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )
                                                                            : (errorMsg
                                                                                ? (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >Error Occured, try again!</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                )
                                                                                : (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>)
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">DESCRIPCIÓN</label>
                                                                <input className='blur-input'
                                                                    type="text" name='small_table_name'
                                                                    value={createSmallTab?.small_table_name}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div className='blur-form-control'>
                                                                <label htmlFor="">PROPUESTA</label>
                                                                <input className='blur-input' type="text"
                                                                    name='proposal_title'
                                                                    value={createSmallTab?.proposal_title}
                                                                    onChange={handleMeetingData} />
                                                            </div>
                                                            <div
                                                                className='blur-form-control d-flex blur-form-list text-center position-relative'
                                                                onClick={handleAddUsers}>

                                                                <label htmlFor="">agrega dirigentes digitales</label>
                                                                <img src="./img/cruz.png" alt="" />
                                                                {smallTabUser?.length
                                                                    ? (
                                                                        smallTabUser.map((member) => {
                                                                            return (<div className='user-add-members'>
                                                                                <img src={member.image_url
                                                                                    ? member.image_url
                                                                                    : 'https://picsum.photos/100/100'}
                                                                                    alt="" />
                                                                            </div>)

                                                                        })
                                                                    )
                                                                    : (
                                                                        <>
                                                                            <img src="./img/user.png" alt="" />
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='add-users-list meeting-user'>
                                                                <div className="legend-box">
                                                                    <ul className="">
                                                                        {allMemberList
                                                                            ? (allMemberList.length
                                                                                ? (
                                                                                    allMemberList.map((item, i) => {
                                                                                        return (<li id="4" className="legendlist"
                                                                                            key={i}
                                                                                            onClick={(e) => handleSelectMember(e, item)}
                                                                                            style={{ cursor: "pointer" }}>
                                                                                            <div className="legendprofile">
                                                                                                <img src={item.user.image_url
                                                                                                    ? item.user.image_url
                                                                                                    : 'https://picsum.photos/100/100'}
                                                                                                    alt="" />
                                                                                            </div>
                                                                                            <div className="legendprofilename">
                                                                                                <span className="legPro-name mb-1">
                                                                                                    <strong>
                                                                                                        {item.user?.first_name}
                                                                                                        {item.user?.last_name}
                                                                                                    </strong>
                                                                                                </span>
                                                                                                <span className="legPro-username fw-light">
                                                                                                    {item.user.username
                                                                                                        ? `@${item.user.username}`
                                                                                                        : item.user?.email
                                                                                                    }
                                                                                                </span>
                                                                                            </div>
                                                                                        </li>)
                                                                                    })
                                                                                )
                                                                                : (
                                                                                    <li className='legendlist' >
                                                                                        <div className='legendprofile'>
                                                                                        </div>
                                                                                        <div className='legendprofilename'>
                                                                                            <span className='legPro-name mb-1' >
                                                                                                <strong >No digital manager found!</strong>
                                                                                            </span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )
                                                                            : (errorMsg
                                                                                ? (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >Error Occured, try again!</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>

                                                                                )
                                                                                : (<li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>)
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer className="border-0">
                                                <div className="form-group text-center d-flex">
                                                    <button className="green-btn mb-0 rounded-pill" onClick={handleBoardMeeting}>CREAR</button>
                                                    <button className="red-btn mb-0 rounded-pill">CANCELAR</button>
                                                </div>
                                            </Modal.Footer>
                                        </Modal> */}

            </div>

            <div className='space-between-btn'>
                <button className='green-btn' onClick={handleShow}>{t('AGREGAR ASAMBLEA GENERAL')}</button>
                {/* <button className='red-btn'>ELIMINAR RCD</button> */}
            </div>
        </>
    )
}

export default BoardMeeting