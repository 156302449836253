import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import "./style.css";

const SwiperComponent = ({ banner }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // This useEffect will run whenever the banner prop changes
    console.log("Banner data changed:", banner);
  }, [banner]);

  return (
    <Swiper
      key={banner.length} // Adding a key to force re-render when data changes
      navigation={true}
      className="swiper-model-billboard"
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Navigation]}
    >
      {banner.slice(0, 3).map((item, index) => (
        <SwiperSlide key={index}>
          <div
            className="card custom-add-card"
            onClick={() => navigate("/editbillboard", { state: banner[index] })}
          >
            <div className="card-heading1">
              <h3>{item.title}</h3>
            </div>
            <div className="card-img custom-add-img">
              {item.imageUrl.endsWith(".mp4") ? (
                <video
                  className="video-grid-view custom-video-grid"
                  muted
                  onClick={() => navigate("/editbillboard", { state: item })}
                >
                  <source src={item.imageUrl} type="video/mp4" />
                  {"Su navegador no soporta la etiqueta de vídeo."}
                </video>
              ) : (
                <img
                  src={item.imageUrl}
                  alt=""
                  onClick={() => navigate("/editbillboard", { state: item })}
                />
              )}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
