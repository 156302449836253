import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import Swal from "sweetalert2";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import translations from "../../locals/translation";
import { RiUploadCloud2Fill } from "react-icons/ri";
import GlobalURL from "../BaseUrl";

import { FaImage } from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";

const defaultSrc =
  "https://raw.githubusercontent.com/mikebrg/twidgrid.github.io/main/assets/images/still-life-colombia-national-soccer-team-23-2149728328-522x783.jpeg";

const Demo = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState(defaultSrc);
  const [cropData, setCropData] = useState(null);
  const cropperRef = useRef(null);
  const [size, setSize] = useState("");

  const [uploading, setUploading] = useState(false);

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const onChange = (e) => {
    e.preventDefault();

    setCropData(null);
    const files = e.target.files || e.dataTransfer.files;
    const file = files[0];
    const sizeInBytes = files[0]?.size;

    const KB = sizeInBytes / 1000;
    const sizeInKB = parseFloat(KB.toFixed(2)); // Ensures exactly two decimal places
    console.log("Size in kilobytes:", sizeInKB);
    setSize(sizeInKB);

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle invalid file type
      console.error("Invalid file type. Please select an image file.");
    }
  };

  const getCropData = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      if (cropper) {
        // Get the cropped canvas
        const croppedCanvas = cropper.getCroppedCanvas();

        // Set desired width and height for the cropped image
        const desiredWidth = 626; // Width in pixels
        const desiredHeight = 991; // Height in pixels

        // Create a temporary canvas with the desired dimensions
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = desiredWidth;
        tempCanvas.height = desiredHeight;

        // Calculate scale factors for resizing
        const scaleX = desiredWidth / croppedCanvas.width;
        const scaleY = desiredHeight / croppedCanvas.height;
        const scale = Math.max(scaleX, scaleY);

        // Calculate the new dimensions for the cropped image
        const newWidth = croppedCanvas.width * scale;
        const newHeight = croppedCanvas.height * scale;

        // Calculate the position to center the image
        const offsetX = (desiredWidth - newWidth) / 2;
        const offsetY = (desiredHeight - newHeight) / 2;

        // Draw the cropped canvas onto the temporary canvas with the new dimensions
        const tempCtx = tempCanvas.getContext("2d");
        tempCtx.drawImage(croppedCanvas, offsetX, offsetY, newWidth, newHeight);

        // Convert the temporary canvas to a data URL
        const croppedDataURL = tempCanvas.toDataURL();

        // Set the cropped data URL to state
        setCropData(croppedDataURL);
      }
    }
  };

  const addSplashScreen = async () => {
    if (!cropData) {
      Swal.fire(t("Primero recorta la imagen"));
    }

    if (size > 700) {
      if (size > 1023) {
        const sizeMB = size / 1000;
        const disPlayMB = parseFloat(sizeMB.toFixed(2));
        Swal.fire({
          title: `${t("¡Tamaño demasiado grande!")} ${disPlayMB} MB`,
          text: t("¡Elija un tamaño inferior a 500 KB!"),
        });

        return;
      }
      Swal.fire({
        title: t(`¡Tamaño demasiado grande!`)`${size}KB`,
        text: t("¡Elija un tamaño inferior a 500 KB!"),
      });
    }
    const formData = new FormData();
    const blob = await fetch(cropData).then((res) => res.blob());
    const file = new File([blob], "croppedImage.png", { type: "image/png" });

    console.log("file: from blob ", file);
    formData.append("image", file);

    try {
      setUploading(true);
      const response = await fetch(
        `${GlobalURL}/api/dev/user/banner/addSplashScreen`,
        {
          method: "POST",
          body: formData,
          headers: {
            "ngrok-skip-browser-warning": "69420",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            // 'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        Swal.fire("Server Error");
        setUploading(false);
      }

      const data = await response.json();
      console.log("Response:", data);
      setUploading(false);
      Swal.fire({
        title: t("Hecho"),
        text: t("La imagen se mostrará en la aplicación a partir de ahora."),
        icon: "success",
      });
    } catch (error) {
      setUploading(false);
      console.error("Error:", error.message);
    }
  };

  // Call the function to add the splash screen

  const resetFiles = () => {
    setCropData(null);
    setImage(
      "https://raw.githubusercontent.com/mikebrg/twidgrid.github.io/main/assets/images/still-life-colombia-national-soccer-team-23-2149728328-522x783.jpeg"
    );
  };

  return (
    <>
      <div className="dashboard up_amb">
        <div className="dashboard-container">
          <div className="proposal-page">
            <div className="heading-photo-section">
              <div className="digital-heading">
                <div
                  className="back-sec"
                  onClick={() => {
                    navigate("/homepage");
                  }}
                >
                  <img src="./img/volver.png" alt="" />
                </div>
                <h3 style={{ fontFamily: "Felix-Titling" }}>
                  {t("SUBIR EMBAJADOR")}
                </h3>
                <div className="search-icon" style={{ display: "flex" }}>
                  {
                    <div className="searchBarInput">
                      <input
                        style={{ opacity: "0" }}
                        className="search-tag-bar"
                        placeholder={t("Buscar")}
                        value={""}
                      />
                    </div>
                  }
                  <FiSearch
                    style={{ opacity: "0" }}
                    className="search-icon-click"
                  />
                </div>
              </div>
            </div>

            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="3" md="12"></Col>

                <Col className="second-sec" lg="6" md="12">
                  <Row style={{ alignItems: "center" }}>
                    <Col xs lg="6">
                      <div
                        className="unique-main-image-container"
                        style={{ width: "100%" }}
                      >
                        <Cropper
                          ref={cropperRef}
                          style={{ height: 400, width: "100%" }}
                          zoomTo={0.5}
                          aspectRatio={626 / 991} // Set the aspect ratio to 626x991
                          preview=".unique-img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={800} // Adjusted height to accommodate 626x991 aspect ratio
                          minCropBoxWidth={504} // Adjusted width to accommodate 626x991 aspect ratio
                          cropBoxResizable={false}
                          autoCropArea={1}
                          background={false}
                          responsive={true}
                          checkOrientation={true}
                          guides={true}
                        />

                        <div className="upload-wrapper">
                          <div className="upload-img">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={onChange}
                            />
                            <div>
                              <RiUploadCloud2Fill />
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                marginBottom: "0",
                                marginLeft: "5px",
                              }}
                            >
                              {t("Elija el archivo")}
                            </p>
                          </div>

                          <div className="upload-btn-groups">
                            <button
                              className="unique-reset-button red-btn"
                              onClick={() => {
                                resetFiles();
                              }}
                            >
                              {t("Reiniciar")}
                            </button>

                            <button
                              className="unique-crop-button blue-btn"
                              onClick={getCropData}
                            >
                              {t("Delimitar imagen")}
                            </button>

                            <button
                              disabled={!cropData}
                              style={{ opacity: !cropData ? 0 : 1 }}
                              className="unique-upload-button green-btn"
                              onClick={() => {
                                addSplashScreen();
                              }}
                            >
                              {t("Subir")}
                            </button>
                          </div>

                          {uploading && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="loading-adjust">
                                  <div className="Loader d-flex justify-content-center align-items-center">
                                    <div
                                      className="spinner-border spinner-border-sm my-5"
                                      role="status"
                                    >
                                      <span className="sr-only"></span>
                                    </div>
                                    <div className="my-5">
                                      {" "}
                                      &nbsp; {t("Cargando...")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    {/* <Col xs lg="4">
                      <div>
                        <div className="unique-box" style={{ width: "50%", float: "right" }}>
                          <h1>Preview</h1>
                          <div
                            className="unique-img-preview"
                            style={{ width: "100%", float: "left", height: "300px" }}
                          />
                        </div>

                      </div>
                    </Col> */}
                    <Col xs lg="6">
                      <div className="unique-mobile-view">
                        <div className="unique-box">
                          {cropData ? (
                            <img
                              className="unique-cropped-image"
                              src={cropData}
                              alt="preview"
                            />
                          ) : (
                            <FaImage className="svg-img-for-preview" />
                          )}
                        </div>
                        <img
                          src={"./img/mobile.png"}
                          alt="mobile"
                          style={{ maxWidth: "100%" }}
                        />
                        {/* <img src={"./img/iphone11.png"} alt="mobile" style={{ maxWidth: "100%" }} /> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="third-sec" lg="3" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Demo;
