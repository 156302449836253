import React from 'react';
import "./ShowChats.css";
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import { FiPlus, FiSearch } from 'react-icons/fi';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import Chart from 'react-google-charts';

import AllDigitalLeaders from '../../Dashboard/DigitalSec/AllDigitalLeaders/AllDigitalLeaders';
import AllVerifiedLeaders from '../../Dashboard/DigitalSec/AllVerifiedLeaders/AllVerifiedLeaders';
import GlobalURL from '../../BaseUrl';


export const data = [
    ["Subscription", "Users"],
    [`<img src='https://img.icons8.com/ios/80/000000/diamond.png'/>`, 25],
    ["Silver", 25],
    ["Golds", 25],
    ["Empty", 25],
];

export const options = {
    title: "",
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
        color: '#FEF6F2'
    },

    slices: {
        0: { color: '#005EAA' },
        1: { color: '#25762E' },
        2: { color: '#C37704' },
        3: { color: 'grey' },
    },
};


function ShowChats() {



    //
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(100);
    const [mediaType, setMediaType] = useState('MEDIA');
    const [allMedia, setAllMedia] = useState([]);

    const [activeTabRequired, setActiveTabRequired] = useState(false);

    const { state } = useLocation();
    console.log('States: ----', state);

    const [senderId] = useState(state.userid);

    const [messageDetails, setMessageDetails] = useState([]);

    async function getDigitalManagersDMChats() {
        const apiUrl = `${GlobalURL}/api/dev/debate/chat/messagehistory?page=${page}&limit=${limit}`;
        const token = localStorage.getItem('token'); // Replace with your actual Bearer token

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '69420',
                },
                body: JSON.stringify({ inbox_uid: state.Details.inbox_uid }),
            });

            if (!response.ok) {
                Swal.fire('Error del Servidor');
                setLoader(false);
                return
            }
            if (response.code === 401) {
                Swal.fire('Token caducado, volver a iniciar sesión');
                navigate('/login');
                setLoader(false);
                return
            }

            const data = await response.json();
            console.log('Chat history', data.data);
            const reversedData = data.data.slice().reverse();
            setMessageDetails(reversedData);

            console.log('Chat history reverse', reversedData);
            setLoader(false);

        } catch (error) {
            Swal.fire('Error del Servidor');
            setLoader(false);
            return
        }
    }

    const handlepageChange = (x) => {
        setLoader(true);
        if (x) {
            setPage(page + 1);
            return
        }
        setPage(page - 1);
    }



    const getMediaForGroupChat = async (media_type) => {
        try {
            const response = await fetch(
                `${GlobalURL}/api/dev/debate/chat/getinboxmedia`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "ngrok-skip-browser-warning": "69420",
                    },
                    body: JSON.stringify({
                        inbox_uid: state.Details.inbox_uid,
                        media_type: media_type,
                    }),
                }
            );

            if (response.status === 200) {
                const data = await response.json();
                console.log("Inbox Media: ", data.data);
                setAllMedia(data.data);
                console.log("getMediaForGroupChat: ", data.data);
            } else {
                // Handle non-200 status codes if needed
                console.error("Error:", response.status);
            }
        } catch (err) {
            // Handle fetch or other errors
            console.error("Error occurred:", err);
        }
    };



    useEffect(() => {
        if (true) {
            try {
                if (mediaType)
                    getMediaForGroupChat(mediaType);
                console.log("media type :- ", mediaType);
            }
            catch (error) {
                console.log("error occured: ", error);
            }
        }
    }, [activeTabRequired, mediaType])

    useEffect(() => {
        getDigitalManagersDMChats();
        setTimeout(() => {
            // Select all elements with the class "msg"
            let allMsgDivs = document.getElementsByClassName("msg");

            // Get the last element from the NodeList
            let lastMsgElement = allMsgDivs[allMsgDivs.length - 1];

            console.log("allMsgDivs", allMsgDivs.length);
            console.log("last", lastMsgElement);
            if (lastMsgElement) {
                console.log(`working scroll...`);
                lastMsgElement.scrollIntoView({ behavior: "smooth", block: 'end' });
            }
        }, 1000);
    }, [page, limit]);



    return (
        <>


            <div className="dashboard">
                <div className="dashboard-container">
                    <div className='Digital-sec debate-page'>
                        <Container fluid>
                            <Row className="justify-content-md-center">
                                <Col xs lg="3">
                                </Col>
                                <Col xs lg="6">
                                    <div className='digital-heading'>
                                        <div className='back-sec' onClick={() => { navigate(-1) }}>
                                            <img src="./img/volver.png" alt="" />
                                        </div>
                                        <h3>MENSAJE DIRECTO</h3>
                                        <div className='search-icon'>
                                            <FiSearch />
                                        </div>
                                    </div>


                                    <div className='tabs-section'>

                                        <div className='tabs-cards-sec legend-tabs'>
                                            <Tabs defaultActiveKey="todas" id="fill-tab-example" fill >

                                                <Tab eventKey="todas" title="CONVERSACIÓN">

                                                    <div className="todas-card-main">
                                                        <div
                                                            className={`todas-card-list ${false ? 'active' : ''}`}
                                                        >
                                                            <div className="todas-card todas-three-item mb-0" >

                                                                <div className='left-sec'  >
                                                                    <div className='profile-img'>

                                                                        <img src={state.usersDetails2?.image_url} alt="" />

                                                                    </div>
                                                                    <div className='profile-content'>
                                                                        <h5>{state.usersDetails2?.first_name}{state.usersDetails2?.last_name}  </h5>
                                                                        <p>{state.usersDetails2?.email}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="mid-sec" >
                                                                    <span className='todas-img-mid'>
                                                                        <img src="./img/exclamation.png" style={{ 'opacity': '1', 'cursor': 'pointer' }} alt="" />

                                                                    </span>
                                                                </div>

                                                                <div className='right-sec'>

                                                                    <div className='left-sec' >
                                                                        <div className='profile-content'>
                                                                            <h5>{state.usersDetails1?.first_name}{state.usersDetails1?.last_name}</h5>
                                                                            <p> {state.usersDetails1?.email}</p>
                                                                        </div>
                                                                        <div className='profile-img'>
                                                                            <img src={state.usersDetails1?.image_url} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className='chatPage-sec'>
                                                        <section className="msger">
                                                            <div className="msger-header">
                                                                <p>Conversation</p>
                                                            </div>
                                                            <main className="msger-chat">
                                                                {messageDetails.map((item, index) => (
                                                                    <>
                                                                        {

                                                                            item.sender_id === state.sender_id ? (
                                                                                <div className='msg right-msg'>
                                                                                    <div className="msg-img">
                                                                                        <img src={state.Details.user1.user_id === state.sender_id ? (state.Details.user1.image_url) :
                                                                                            (state.Details.user2.image_url)} alt="" />
                                                                                    </div>
                                                                                    <div className="msg-item">
                                                                                        <div className="msg-bubble">
                                                                                            <div className="msg-text-wrapper-right">
                                                                                                <span className='name-display'>
                                                                                                    {state.Details.user1.user_id === state.sender_id ? (state.Details.user1.first_name)
                                                                                                        : (state.Details.user2.first_name)}
                                                                                                </span>
                                                                                                <div className="msg-text">
                                                                                                    <p>{item.message}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="msg left-msg">
                                                                                    <div className="msg-item">
                                                                                        <div className="msg-bubble">
                                                                                            <div className="msg-text-wrapper">
                                                                                                <span className='name-display'>
                                                                                                    {state.Details.user1.user_id === state.sender_id ? (state.Details.user2.first_name)
                                                                                                        : (state.Details.user1.first_name)}
                                                                                                </span>
                                                                                                <div className="msg-text">
                                                                                                    <p>{item.message}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="msg-img">
                                                                                        <img src={state.Details.user1.user_id !== state.sender_id ? state.Details.user1.image_url :
                                                                                            state.Details.user2.image_url} alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </>
                                                                ))}
                                                            </main>

                                                            {/* item.senderId === senderId */}


                                                        </section>
                                                    </div>


                                                </Tab>

                                                <Tab eventKey="oro" title="DETALLES" >

                                                    <div className="todas-card-main">
                                                        <div
                                                            className={`todas-card-list ${false ? 'active' : ''}`}
                                                        >
                                                            <div className="todas-card todas-three-item mb-0" >

                                                                <div className='left-sec'  >
                                                                    <div className='profile-img'>

                                                                        <img src={state.usersDetails2.image_url} alt="" />

                                                                    </div>
                                                                    <div className='profile-content'>
                                                                        <h5>{state.usersDetails2.first_name}{state.usersDetails2.last_name}  </h5>
                                                                        <p>{state.usersDetails2.email}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="mid-sec" >
                                                                    <span className='todas-img-mid'>
                                                                        <img src="./img/exclamation.png" style={{ 'opacity': '1', 'cursor': 'pointer' }} alt="" />

                                                                    </span>
                                                                </div>

                                                                <div className='right-sec'>

                                                                    <div className='left-sec' >
                                                                        <div className='profile-content'>
                                                                            <h5>{state.usersDetails1.first_name}{state.usersDetails1.last_name}</h5>
                                                                            <p>{state.usersDetails1.email}</p>
                                                                        </div>
                                                                        <div className='profile-img'>
                                                                            <img src={state.usersDetails1.image_url} alt="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='tabGallerySec'>
                                                        <div className='tabGalleryInner'>
                                                            <Tabs
                                                                defaultActiveKey="MEDIA"
                                                                className='tabgalleryList'
                                                                onSelect={(eventKey) => setMediaType(eventKey)}
                                                            >
                                                                <Tab eventKey="MEDIA" title="Media" >
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.map((item, i) => {
                                                                                if (item.message_type === 'IMAGE') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <img src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                                else if (item.message_type === 'VIDEO') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <video src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                            })
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className="" style={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                        <div className="loading-adjust">
                                                                                            <div className="Loader d-flex justify-content-center align-items-center">
                                                                                                <div className="spinner-border spinner-border-sm my-5" role="status">
                                                                                                    <span className="sr-only"></span>
                                                                                                </div>
                                                                                                <div className="my-5"> &nbsp; Eliminando...</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="LINK" title="Enlaces">
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.map((item, i) => {
                                                                                return (

                                                                                    <div className="enlaces-box">

                                                                                        <Link to={item?.multimedia_url}>
                                                                                            <div className="enlaces-first-box">
                                                                                                <div className="campass-img">
                                                                                                    <img src="./image/campass.png" alt="" />
                                                                                                </div>
                                                                                                <div className="enlaces-text">
                                                                                                    <div className="enlaces-title">
                                                                                                        <h6 className="mb-0">TikTok ° Edwin R Suqui</h6>
                                                                                                    </div>
                                                                                                    <div className="enlaces-content">
                                                                                                        <p>5175 me gusta, 90 comentarios. Echa un vistazo al video de Edwin R Suqui</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="enlaces-second-box">
                                                                                                <a href="javascript:void(0)">
                                                                                                    <span>buen día https://vm.tiktok.com/ZM2oFW/</span>
                                                                                                    <img src="./image/angle-right.svg" alt="" />
                                                                                                </a>
                                                                                            </div>
                                                                                        </Link>

                                                                                    </div>


                                                                                )


                                                                            })
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="DOC" title="Docs" >
                                                                    <div className='tabGallery-sec'>
                                                                        {allMedia
                                                                            ? (allMedia.map((item, i) => {
                                                                                if (item.message_type === 'IMAGE') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <img src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                                else if (item.message_type === 'VIDEO') {
                                                                                    return (<div className='img-card' key={i}>
                                                                                        <video src={item?.multimedia_url} alt="" />
                                                                                    </div>)
                                                                                }
                                                                            })
                                                                            )
                                                                            : (
                                                                                <li className='legendlist  justify-content-center'>
                                                                                    <div className='legendprofile'>
                                                                                    </div>
                                                                                    <div className='legendprofilename text-center'>
                                                                                        <span className='legPro-name mb-1' >
                                                                                            <strong >loading...</strong>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                        </div>
                                                    </div>

                                                </Tab>

                                            </Tabs>
                                        </div>


                                    </div>

                                </Col>
                                <Col xs lg="3">
                                    <div className="team-list-char-sec" hidden={true}>
                                        <div className="piechart-sec text-white">
                                            <Chart chartType="PieChart" data={data} options={options} width={"300px"}
                                                height={"300px"} />

                                            <p className='piechart-title'>DIRIGENTES DIGITALES</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </div>
            </div>

        </>
    )
}


export default ShowChats