import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";

import dayjs from "dayjs";
import "dayjs/locale/en"; // Import English locale for day names
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from "../../locals/translation";

import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";

const Calender = () => {
  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const today = new Date();
  const formattedDate = today.toDateString();
  const navigate = useNavigate();
  const tomorrow = new Date();
  const [selectedDates, setSelectedDates] = useState([]);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [searchActive, setSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [originalData, setOriginalData] = useState([]);

  const [loading, setLoading] = useState(true);

  //calender
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [markedDates, setMarkedDates] = useState([]);

  const generateCalendar = () => {
    const startOfMonth = currentMonth.startOf("month");
    const daysInMonth = startOfMonth.daysInMonth();
    const startDay = startOfMonth.day();

    const days = [];

    const dayNames = [t("D"), t("L"), t("M"), t("Mi"), t("J"), t("V"), t("S")];
    // Generate cells for day names
    for (let i = 0; i < 7; i++) {
      days.push(
        <div key={`day-${i}`} className="app-cal-day day-name">
          {dayNames[i]}
        </div>
      );
    }
    // Create empty cells for days before the start of the month
    for (let i = 0; i < startDay; i++) {
      days.push(<div key={`empty-${i}`} className="app-cal-day empty"></div>);
    }
    // Create cells for each day in the month
    for (let i = 1; i <= daysInMonth; i++) {
      const day = startOfMonth.date(i);
      const isToday = day.isSame(dayjs(), "day");
      const formattedDate = day.format("YYYY-MM-DD");
      const markedDate = markedDates.find(
        (date) => date.date === formattedDate
      );

      days.push(
        <div
          key={i}
          className={`app-cal-day ${isToday ? "today" : ""} ${
            markedDate ? "marked-day" : ""
          }`}
          style={{ backgroundColor: markedDate ? markedDate.color : "" }}
        >
          {i}
        </div>
      );
    }
    return days;
  };

  const handleSearch = (e) => {
    const filteredData = dateData2;
    const dataTemp = originalData;
    console.log("originalData: ", originalData);
    setSearchTerm(e.target.value);
    console.log("Search Term: ", e.target.value);
    if (e.target.value === "") {
      setDateData(filteredData);
      return;
    }
    const result = dataTemp.filter((item) =>
      item.title.toLowerCase().includes(e.target.value)
    );
    console.log("result: ", result);

    if (result.length === 0) {
      console.log("Empty Arry executed:  ");
      return;
    }
    setDateData(result);
  };

  const filterMonth = (val) => {
    let month = "";
    if (val === false) {
      month = currentMonth.subtract(1, "month");
    } else {
      month = currentMonth.add(1, "month");
    }
    console.log("current month ", month.format("MMMM"));
    setmonthSel(true);

    const latestData = originalData;
    console.log("latestData: ", latestData);

    const filteredData = latestData.filter((data) => {
      // Extract month and year from the event_date
      const eventDate = moment(data.event_date);
      const eventMonth = eventDate.month(); // Month is zero-based index
      const eventYear = eventDate.year();

      // Compare month and year of event_date with currentMonth
      return eventMonth === month.month() && eventYear === month.year();
    });

    console.log("filteredData: ", filteredData);
    // Use filteredData as needed
    setDateData(filteredData);
    setDateData2(filteredData);
  };

  const filterMonth2 = (val) => {
    console.log("Selected Month: ", val);

    // Extract month and year from the selected month value
    const selectedMonth = parseInt(val, 10); // Convert to integer
    const selectedYear = currentMonth.year(); // Assuming you want to filter by the year of the current month

    console.log("Selected Month Index: ", selectedMonth);
    console.log("Selected Year: ", selectedYear);

    const filteredData = originalData.filter((data) => {
      // Extract month and year from the event_date
      const eventDate = moment(data.event_date);
      const eventMonth = eventDate.month(); // Month is zero-based index
      const eventYear = eventDate.year();

      // Compare month and year of event_date with selectedMonth and selectedYear
      return eventMonth === selectedMonth && eventYear === selectedYear;
    });

    console.log("Filtered Data: ", filteredData);
    // Use filteredData as needed
    setDateData(filteredData);
    setDateData2(filteredData);
  };

  // Function to handle month change
  const handleMonthChange = (event) => {
    const selectedMonth = parseInt(event.target.value, 10);
    setCurrentMonth(currentMonth.month(selectedMonth));
    console.log("selectedMonth: ", selectedMonth);

    filterMonth2(selectedMonth);
  };

  // Function to handle year change
  const handleYearChange = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    setCurrentMonth(currentMonth.year(selectedYear));
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    fetchDates();
  }, []);

  const [dateData, setDateData] = useState([]);
  const [dateData2, setDateData2] = useState([]);
  const [monthSel, setmonthSel] = useState(false);

  const fetchDates = async () => {
    const url = `${GlobalURL}/api/dev/app_moderator/event/getAllEvent`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (response.code === 401) {
        Swal.fire("La sesión expiró, vuelva a iniciar sesión");
        navigate("/login");
      }

      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        setDateData(responseData.data);
        setOriginalData(responseData.data);
        console.log("dates-resp", responseData.data);

        // const eventDates = responseData.data.map((item) => {
        //     const dateOnly = new Date(item.event_date).toISOString().split('T')[0];
        //     return dateOnly;
        // });
        // console.log('dates-resp', responseData.data);

        const eventDates = responseData.data.map((item) => {
          const dateOnly = new Date(item.event_date)
            .toISOString()
            .split("T")[0];
          let color;

          switch (item.event_type) {
            case "event":
              color = "#DF9825";
              break;
            case "exclusive-events":
              color = "#54A3C9";
              break;
            case "exclusive":
              color = "#54A3C9";
              break;
            case "experiences-messice":
              color = "#25762E";
              break;
            default:
              color = "#DF9825"; // Set a default color if none of the conditions match
          }

          // If the date is today, override the color with '#800B0F'
          if (dayjs(dateOnly).isSame(dayjs(), "day")) {
            color = "#800B0F";
          }
          setLoading(false);

          return { date: dateOnly, color: color };
        });

        console.log("eventDates", eventDates);

        console.log("Dates", eventDates);
        //setMarkedDates();
        setMarkedDates(eventDates);

        let month = currentMonth;

        console.log("current month ", month.format("MMMM"));
        setmonthSel(true);

        const filteredData = responseData.data.filter((data) => {
          // Extract month and year from the event_date
          const eventDate = moment(data.event_date);
          const eventMonth = eventDate.month(); // Month is zero-based index
          const eventYear = eventDate.year();

          // Compare month and year of event_date with currentMonth
          return eventMonth === month.month() && eventYear === month.year();
        });

        console.log("filteredData: ", filteredData);
        // Use filteredData as needed
        setDateData(filteredData);
        setDateData2(filteredData);
        setLoading(false);
      } else {
        const errorData = await response.json();
        setLoading(false);
        console.log(errorData);
      }
    } catch (error) {
      console.error("Error:", error.message);
      console.log(error.message);
    }
  };

  return (
    <>
      <div className="dashboard ed-page">
        <div className="dashboard-container">
          <div className="event-date-page">
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col className="first-sec" lg="2" md="12"></Col>
                <Col className="second-sec" lg="8" md="12">
                  <div className="event-page-content">
                    <div className="news-header1">
                      <div className="back-sec">
                        <img
                          src="./img/volver.png"
                          onClick={() => {
                            navigate("/homepage");
                          }}
                          alt=""
                        />
                      </div>
                      <h3 className="main-title">{t("Eventos")}</h3>
                      <div className="search-icon" style={{ display: "flex" }}>
                        {searchActive && (
                          <div className="searchBarInput">
                            <input
                              className="search-tag-bar"
                              placeholder={t("Buscar")}
                              value={searchTerm}
                              onChange={(e) => {
                                handleSearch(e);
                              }}
                            />
                          </div>
                        )}
                        <FiSearch
                          className="search-icon-click"
                          onClick={() => {
                            setSearchActive(!searchActive);
                          }}
                        />
                      </div>
                    </div>
                    <div className="event-calendar-sec">
                      <Row>
                        <Col xl="5">
                          <div className="event-calendar">
                            {/* <Calendar onChange = {changeValue} value = {date} /> */}

                            <div className="eventColor_list">
                              <ul className="d-flex flex-wrap gap-4 ps-0">
                                <li>
                                  <span className="clr-circle red-clr"></span>
                                  <span>{t("Hoy")}</span>
                                </li>
                                <li>
                                  <span className="clr-circle yellow-clr"></span>
                                  <span>{t("Eventos")}</span>
                                </li>
                                <li>
                                  <span className="clr-circle blue-clr"></span>
                                  <span>{t("Eventos Exclusivos")}</span>
                                </li>
                                <li>
                                  <span className="clr-circle green-clr"></span>
                                  <span>{t("Vivencias Events")}</span>
                                </li>
                              </ul>
                            </div>
                            <div className="app-cal-cont">
                              <div className="app-cal-header">
                                <button
                                  className="button-arrow"
                                  onClick={() => {
                                    setCurrentMonth(
                                      currentMonth.subtract(1, "month")
                                    );
                                    filterMonth(false);
                                  }}
                                >
                                  <IoMdArrowDropleft
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                </button>
                                {/* <select className="month-name-app" value={currentMonth.month()} onChange={handleMonthChange} disabled={loading}>
                                                                {monthNames.map((month, index) => (
                                                                    <option key={index} value={index}>{month}</option>
                                                                ))}
                                                            </select> */}
                                {/* Select Month */}
                                <h2 className="month-name-app">
                                  {currentMonth.format("MMMM YYYY")}
                                </h2>
                                <button
                                  className="button-arrow"
                                  onClick={() => {
                                    setCurrentMonth(
                                      currentMonth.add(1, "month")
                                    );
                                    filterMonth(true);
                                  }}
                                >
                                  <IoMdArrowDropright
                                    style={{ width: "40px", height: "40px" }}
                                  />
                                </button>
                              </div>
                              <div className="app-cal">
                                {generateCalendar()}
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xl="7">
                          <div className="cal-month">
                            {searchTerm.length > 0 ? (
                              <h4>{t("Resultado de búsqueda")}</h4>
                            ) : (
                              <h4>{currentMonth.format("MMMM YYYY")}</h4>
                            )}
                          </div>
                          <div className="calendar-list-sec2">
                            <div className="cal-list-card-sec">
                              {loading ? (
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div className="loading-adjust">
                                    <div className="Loader d-flex justify-content-center align-items-center">
                                      <div
                                        className="spinner-border spinner-border-sm my-5"
                                        role="status"
                                      >
                                        <span className="sr-only"></span>
                                      </div>
                                      <div className="my-5">
                                        {" "}
                                        &nbsp; {t("Cargando...")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : dateData.length < 1 ? (
                                <p className="no-data-thing">
                                  {t("No hay evento para")}{" "}
                                  {currentMonth.format("MMMM YYYY")}
                                </p>
                              ) : (
                                dateData.map((item, index) => (
                                  <div
                                    className="cal-list-card"
                                    key={index}
                                    onClick={() => {
                                      navigate("/editevent", { state: item });
                                    }}
                                  >
                                    <div className="left-sec">
                                      <span
                                        className={`dates ${
                                          dayjs(item.event_date).isSame(
                                            dayjs(),
                                            "day"
                                          )
                                            ? "today"
                                            : ""
                                        } ${
                                          dayjs(item.event_date).isSame(
                                            dayjs(),
                                            "day"
                                          )
                                            ? ""
                                            : item.event_type
                                        }`}
                                      >
                                        {new Date(
                                          item.event_date
                                        ).toLocaleDateString("en-US", {
                                          day: "numeric",
                                          timeZone: "UTC", // Adjust timeZone if needed
                                        })}
                                      </span>
                                      <span className="event-data">
                                        <h4>{item.title}</h4>
                                        <p>{item.streaming_link}</p>
                                      </span>
                                    </div>
                                    <div className="right-sec">
                                      <h3></h3>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="event-btns">
                      <button
                        className="green-btn"
                        onClick={() => navigate("/addevent")}
                        style={{ cursor: "pointer" }}
                      >
                        {t("Agregar Evento")}
                      </button>
                      <button
                        className="red-btn"
                        disabled={true}
                        style={{ display: "none" }}
                      >
                        {t("Eliminar evento")}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col className="third-sec" lg="2" md="12"></Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calender;
