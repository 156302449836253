import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import "./style.css"
import GlobalURL from '../BaseUrl';

import VideoThumbnail from 'react-video-thumbnail';


import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const AddMultimedia = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const navigate = useNavigate();

    const [mediatype, setMediatype] = useState("img");
    const [preview, setPreview] = useState();
    const [file, setFile] = useState(null);
    const formData = new FormData();
    const [adding, setAdding] = useState(false);

    function handleChange(e) {
        setFile(e.target.files[0]);
        console.log(e.target.files[0]);

        setPreview(URL?.createObjectURL(e.target.files[0]));
        if (e.target.files[0].name.includes('mp4')) {
            setMediatype("vids");
        }
    }

    const handleMedia = () => {
        if (!file) {
            Swal.fire(t('Por favor seleccione un archivo'))
            return
        }
        // console.log(".ENV: ", process.env.REACT_APP_BASE_URL);
        setAdding(true);
        formData.append("media", file);
        fetch(`${GlobalURL}/api/dev/miteam/multimedia/addMedia`, {
            method: "POST",
            mode: "cors",
            headers: {
                "ngrok-skip-browser-warning": "69420",
            },
            body: formData
        })
            .then(function (res) {
                if (res.ok) {
                    // alert("Perfect! ");
                    Swal.fire(t("multimedia añadido con éxito!"));
                    setFile("");
                    setAdding(false);
                    navigate('/multimedia');
                } else if (res.status === 401) {
                    Swal.fire(t("Error del Servidor, ¡inténtalo de nuevo!"));
                    setAdding(false);
                    // alert("Oops! ");
                }
            })
            .catch(function (e) {
                console.log(e)
                Swal.fire(t("multimedia no agregada, ¡inténtalo de nuevo!"));
                // alert("Error submitting form!");
            })
    }

    const handleThumbnailForMedia = (thumbnail) => {
        console.log("thumbnail: ", thumbnail);
        // setBase64(thumbnail);
        // let fileBaseToImage = converBase64ToImage(base64, pathToStore);
        // console.log("thumbnail: ", fileBaseToImage);
        // const blob = dataURItoBlob(thumbnail);
        // console.log('+ new blob: ', blob);
        // formData.append('thumbnail', blob);

        // Convert Data URI to Blob
        const byteString = atob(thumbnail.split(',')[1]);
        const mimeString = thumbnail.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });
        // Convert Blob to File (assuming you have a filename)
        const file = new File([blob], 'thumbnail.png', { type: mimeString });
        console.log('ffile: ', file);

        // Append File to FormData
        formData.append('thumbnail', file);
    }


    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='addLagend-page'>
                        <Container fluid>
                            <Row>

                                <div className="addLegend-col">
                                    <div className='addLagendsec common-dash-padding'>
                                        <div className='news-header'>
                                            <div className='back-sec' onClick={() => { navigate('/multimedia') }} style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className='header-title' style={{ fontFamily: 'Francois One', fontSize: '23px' }}> {t('AGREGAR MULTIMEDIA')}</h3>
                                            <div className='search-icon'>
                                            </div>
                                        </div>

                                        <div className='common-input-forms newsAddContent'>
                                            <form encType="multipart/form-data">

                                                <div className='common-form-control img-video-form-control'>
                                                    <label>{t('Foto/Video')}</label>
                                                    <div className='file-input-sec'>
                                                        <input id="uploadFile" disabled={true} className="f-input" placeholder={t('Inserta aquí la foto o vídeo del multimedia')} />
                                                        <div className="fileUpload btn btn--browse">
                                                            <input id="uploadBtn" onChange={handleChange} type="file" className="upload" />

                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <img src={preview} alt=''></img>  */}

                                                <div className='preview-imgSec'>
                                                    {
                                                        mediatype === "img"
                                                            ? (
                                                                <div className='preview-imgCon'>
                                                                    <img src={preview} alt=''>
                                                                    </img>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className='preview-imgCon'>

                                                                    <video src={preview} >

                                                                    </video>

                                                                    <div className='preview-imgSec'>
                                                                        <VideoThumbnail
                                                                            videoUrl={preview}
                                                                            thumbnailHandler={handleThumbnailForMedia}
                                                                            width={120}
                                                                            height={80}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </form>
                                        </div>

                                        <div className='news-btn-sec'>
                                            <button className='green-btn' disabled={adding} onClick={handleMedia}>

                                                {
                                                    adding ? <>
                                                        <div className="" style={{ height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <div className="loading-adjust">
                                                                <div className="Loader d-flex justify-content-center align-items-center">
                                                                    <div className="spinner-border spinner-border-sm my-5" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    <div className="my-5"> &nbsp; {t('Cargando...')}</div>
                                                                </div>
                                                            </div>
                                                        </div></> :
                                                        t('Guardar')
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMultimedia
