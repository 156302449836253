import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
// import "react-calendar/dist/Calendar.css";
import "./calender.css";
// import { Calendar } from "react-multi-date-picker"
// import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
// import colors from "react-multi-date-picker/plugins/colors"
import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import dayjs from 'dayjs';
import 'dayjs/locale/en'; // Import English locale for day names
import Swal from "sweetalert2";
import GlobalURL from "../BaseUrl";

import { useSelector } from "react-redux";
import translations from '../../locals/translation';


const Calender = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };

    const today = new Date();
    const formattedDate = today.toDateString();
    const navigate = useNavigate();
    const tomorrow = new Date();
    // const [selectedDates, setSelectedDates] = useState([]);
    tomorrow.setDate(tomorrow.getDate() + 1);



    //calender

    const [currentMonth, setCurrentMonth] = useState(dayjs());

    const [markedDates, setMarkedDates] = useState([]);

    const generateCalendar = () => {
        const startOfMonth = currentMonth.startOf('month');
        const daysInMonth = startOfMonth.daysInMonth();
        const startDay = startOfMonth.day();

        const days = [];
        const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        // Generate cells for day names
        for (let i = 0; i < 7; i++) {
            days.push(
                <div key={`day-${i}`} className="app-cal-day day-name">
                    {dayNames[i]}
                </div>
            );
        }

        // Create empty cells for days before the start of the month
        for (let i = 0; i < startDay; i++) {
            days.push(<div key={`empty-${i}`} className="app-cal-day empty"></div>);
        }

        // Create cells for each day in the month
        for (let i = 1; i <= daysInMonth; i++) {
            const day = startOfMonth.date(i);
            const isToday = day.isSame(dayjs(), 'day');
            const formattedDate = day.format('YYYY-MM-DD');
            const markedDate = markedDates.find((date) => date.date === formattedDate);

            days.push(
                <div
                    key={i}
                    className={`app-cal-day ${isToday ? 'today' : ''} ${markedDate ? 'marked-day' : ''}`}
                    style={{ backgroundColor: markedDate ? markedDate.color : '' }}
                >
                    {i}
                </div>
            );
        }

        return days;
    };

    useEffect(() => {
        fetchDates();
    }, [])

    const [dateData, setDateData] = useState([]);

    const fetchDates = async () => {

        const url = `${GlobalURL}/api/dev/app_moderator/event/getAllEvent`;
        const token = localStorage.getItem('token');

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                },
            });
            if (response.code === 401) {
                Swal.fire('La sesión expiró, vuelva a iniciar sesión');
                navigate('/login');
            }

            if (response.ok) {
                const responseData = await response.json();
                setDateData(responseData.data);
                console.log('dates-resp', responseData.data);
                navigate('/events');

                // const eventDates = responseData.data.map((item) => {
                //     const dateOnly = new Date(item.event_date).toISOString().split('T')[0];
                //     return dateOnly;
                // });
                // console.log('dates-resp', responseData.data);

                const eventDates = responseData.data.map((item) => {
                    const dateOnly = new Date(item.event_date).toISOString().split('T')[0];
                    let color;

                    switch (item.event_type) {
                        case 'event':
                            color = '#DF9825';
                            break;
                        case 'exclusive-events':
                        case 'exclusive':
                            color = '#54A3C9';
                            break;
                        case 'experiences-messice':
                            color = '#25762E';
                            break;
                        default:
                            color = ''; // Set a default color if none of the conditions match
                    }

                    // If the date is today, override the color with '#800B0F'
                    if (dayjs(dateOnly).isSame(dayjs(), 'day')) {
                        color = '#800B0F';
                    }

                    return { date: dateOnly, color: color };
                });

                console.log('eventDates', eventDates);

                console.log('Dates', eventDates);
                //setMarkedDates();
                setMarkedDates(eventDates);

            } else {
                const errorData = await response.json();
                throw new Error(`Error: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    return (
        <>
            <div className="dashboard">
                <div className="event-date-page">
                    <Container fluid>
                        <Row className="justify-content-md-center">
                            <Col className="first-sec" lg="3" md="12"></Col>
                            <Col  className="second-sec" lg="6" md="12">
                                <div className="event-page-content">
                                    <div className="news-header">
                                        <div className="back-sec">
                                            <img src="./img/volver.png" onClick={() => { navigate('/homepage') }} alt="" />
                                        </div>
                                        <h3 className="header-title inter-page-header-title">
                                            Eventos
                                        </h3>
                                        <div className="search-icon">
                                            <FiSearch />
                                        </div>
                                    </div>
                                    <div className="event-calendar-sec">
                                        <Row>
                                            <Col xs lg="5">
                                                <div className="event-calendar">
                                                    {/* <Calendar onChange = {changeValue} value = {date} /> */}

                                                    <div className="eventColor_list">
                                                        <ul className="d-flex flex-wrap gap-4 ps-0">
                                                            <li>
                                                                <span className="clr-circle red-clr"></span>
                                                                <span>
                                                                    {t('Hoy')}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="clr-circle yellow-clr"></span>
                                                                <span>
                                                                    {t('Eventos')}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="clr-circle blue-clr"></span>
                                                                <span>
                                                                    {t('Eventos Exclusivos')}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span className="clr-circle green-clr"></span>
                                                                <span>
                                                                    {t('Vivencias Events')}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="app-cal-cont">
                                                        <div className="app-cal-header">
                                                            <button className="button-arrow" onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month'))}>
                                                                <IoIosArrowBack />
                                                            </button>
                                                            <h2 className="month-name-app">{currentMonth.format('MMMM YYYY')}</h2>
                                                            <button className="button-arrow" onClick={() => setCurrentMonth(currentMonth.add(1, 'month'))}>
                                                                <IoIosArrowForward />
                                                            </button>
                                                        </div>
                                                        <div className="app-cal">{generateCalendar()}</div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col xs lg="7">
                                                <div className="calendar-list-sec">
                                                    <div className="cal-month" style={{
                                                        display: "none", opacity: 0
                                                    }}>
                                                        <h4>{formattedDate}</h4>
                                                    </div>
                                                    <div className="cal-list-card-sec">
                                                        {
                                                            dateData.map((item, index) => (
                                                                <div className="cal-list-card" key={index}>
                                                                    <div className="left-sec">
                                                                        <span className={`dates ${dayjs(item.event_date).isSame(dayjs(), 'day') ? 'today' : ''} ${dayjs(item.event_date).isSame(dayjs(), 'day') ? '' : item.event_type}`}>
                                                                            {new Date(item.event_date).toLocaleDateString('en-US', {
                                                                                day: 'numeric',
                                                                                timeZone: 'UTC', // Adjust timeZone if needed
                                                                            })}
                                                                        </span>
                                                                        <span className="event-data">
                                                                            <h4>{item.title}</h4>
                                                                            <p>{item.streaming_link}</p>
                                                                        </span>
                                                                    </div>
                                                                    <div className="right-sec">
                                                                        <h3></h3>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }


                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="event-btns">
                                        <button
                                            className="green-btn"
                                            onClick={() => navigate("/addevent")}
                                            style={{ cursor: "pointer" }}
                                        >
                                            Agregar Evento
                                        </button>
                                        <button className="red-btn">Eliminar evento</button>
                                    </div>
                                </div>
                            </Col>
                            <Col className="third-sec" lg="3" md="12"></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Calender;
