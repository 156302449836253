import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FiSearch } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import './style2.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import GlobalURL from '../BaseUrl';

import { useSelector } from 'react-redux';
import translations from '../../locals/translation';

const DigitalLeader = () => {

    const language = useSelector(state => state.language.value);

    const t = (key) => {
        // Check if translations exist for the current language
        if (translations[language]) {
            // Access translation for the provided key
            const translation = translations[language][key];
            // Return translation if found, otherwise return the key itself
            return translation !== undefined ? translation : key;
        } else {
            // Return the key itself if translations for the current language are not available
            return key;
        }
    };


    const [data, setData] = useState([]);

    const { state } = useLocation();
    console.log("userID", state);
    const uID = state.userId;


    const navigate = useNavigate();

    const [imgUrl, setImgUrl] = useState('');
    const [name, setName] = useState('');
    const [isVerified, setIsVerified] = useState(true);
    const [gems, setGems] = useState(state.gems);

    const isCurrentWeek = (date) => {
        const entryDate = new Date(date);
        const today = new Date();

        // Check if the entry date is in the current week
        return (
            entryDate.getFullYear() === today.getFullYear() &&
            entryDate.getMonth() === today.getMonth() &&
            entryDate.getDate() >= today.getDate() - today.getDay() &&
            entryDate.getDate() < today.getDate() + (6 - today.getDay())
        );
    };


    async function fetchData() {
        const url = `${GlobalURL}/api/dev/app_moderator/getGemsHistory`;
        const token = localStorage.getItem('token');


        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                    "user_id": uID
                }),
            });
            if (response.code === 401) {
                Swal.fire(t('La sesión expiró, vuelva a iniciar sesión'));
                navigate('/login');
            }

            if (response.ok) {

                const responseData = await response.json();
                console.log('responseData: ', responseData)
                console.log(responseData.data.history);
                setData(responseData.data.history || []);

                setImgUrl(responseData.data.userData[0].image_url);
                setName(responseData.data.userData[0].first_name + ' ' + responseData.data.userData[0].last_name);
                setIsVerified(responseData.data.userData[0].is_verified);

            } else {
                const errorData = await response.json();
            }
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    useEffect(() => {
        fetchData();
    }, [])


    return (
        <>
            <div className='dashboard'>
                <div className='dashboard-container'>
                    <div className='digital-leader-page'>
                        <Container fluid>
                            <Row>
                                <Col className="first-sec" lg="3" md="12"></Col>
                                <Col className="second-sec" lg="3" md="12">
                                    <div className='digi-leader-sec'>
                                        <div className="news-header">
                                            <div className="back-sec" alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                                                <img src="./img/volver.png" alt="" />
                                            </div>
                                            <h3 className="main-title" style={{ fontFamily: 'Francois One', fontSize: '23px' }}>{t('DIRIGENTE DIGITAL')}</h3>
                                            <div className="search-icon" style={{ opacity: '0' }}>
                                                <FiSearch />
                                            </div>
                                        </div>
                                        <div className='digileader-container'>
                                            <div className='digi-profile-sec'>
                                                <div class="center-head">
                                                    <img src={imgUrl} alt="" />
                                                    {isVerified && (<img className='verification-badge' src='./img/Diamante.png' alt="" />)}
                                                    <h3>
                                                        {name}
                                                    </h3>
                                                </div>
                                                <div className='games-sec'>
                                                    <div class="gems">
                                                        <img src="./img/Gemas.png" alt="gem" />
                                                        {gems}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='gems-movements-sec'>
                                                <h3 className='gems-movements'>
                                                    {t('Movimientos de Gemas')}
                                                </h3>
                                                <div className='gems-card-list'>
                                                    {
                                                        data.map((item, index) => (
                                                            // isIncreasing = (item.intital_gems_count - item.updated_gems_count) > 0
                                                            // isCurrentWeek = /* your logic for checking if it's the current week */;
                                                            <>
                                                                <div className='gems-card' key={index}>
                                                                    <div className='left-side'>
                                                                        <div className='img-sec'>
                                                                            <img src="./img/propuestas.png" alt="" />
                                                                        </div>
                                                                        <div className='details-sec'>
                                                                            <h5>{item.debit_source}</h5>
                                                                            <p>{t('enlace a la propuesta')}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='right-side'>
                                                                        {item.intital_gems_count - item.updated_gems_count === 0 ? (<p className='decreasing'>0</p>) : (<p className={item.intital_gems_count - item.updated_gems_count > 0 ? 'increasing' : 'decreasing'}><span hidden={item.intital_gems_count - item.updated_gems_count < 0} >+</span>{item.intital_gems_count - item.updated_gems_count}</p>)}
                                                                        {/* <p className='date-day-display'>
                                                                    {new Date(item.createdAt).toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' })}
                                                                    </p> */}

                                                                        <p className='date-day-display'>
                                                                            {isCurrentWeek(item.createdAt)
                                                                                ? new Date(item.createdAt).toLocaleDateString('en-US', { weekday: 'long' })
                                                                                : new Date(item.createdAt).toLocaleDateString('en-US')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </>))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="third-sec" lg="3" md="12"></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DigitalLeader
